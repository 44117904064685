import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import { Divider, FormControl, MenuItem, TextField, Typography, Select } from '@mui/material';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import CustomButton from '../commonComponents/CustomButton';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import Require from '../commonComponents/Required';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import UserMobile from '../../Modals/UserMobile';
import { useGetMobileUser } from '../../hooks/workerManagementHooks';
import { useGetFullDepartments } from '../../hooks/departmentHooks';
import Loading from 'react-fullscreen-loading';

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required(`User's name is Required`)
    .matches(/^[ a-zA-Z0-9]+$/, 'Only alphabets and numbers are allowed'),
  uniqId: Yup.string().trim().required(`User's ID is Required`),
  department: Yup.string().required('Department is Required'),
  email: Yup.string().email('Email must be a valid email').required('Email is required'),
  mobile: Yup.string()
    .matches(/^\d{10}$/, 'Please enter a valid 10-digit phone number.')
    .required('Phone number is required.'),
});

const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '1px 14px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));

// New Style using ClassName

const HeadBox = styled(Box)(() => ({
  paddingBottom: '20px',
  '.head-text': {
    lineHeight: '32px',
    height: '32px',
    fontSize: '21px',
    padding: '0px 0px 18px 0px',
  },
  '.title-texts': {
    fontSize: '14px',
    color: '#265AA8',
  },
  '.title-last': {
    fontSize: '34px',
    fontWeight: '700',
    color: '#265AA8',
  },
  '.title-box': {
    display: 'flex',
  },
  '.title-sub-box': {
    display: 'flex',
    alignItems: 'center',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
    position: 'absolute',
    bottom: '1px',
    left: '0px',
    width: '100%',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 8px 0px',
    color: '#000',
  },
  '.back-arrow': {
    cursor: 'pointer',
  },
  '.mx-14': {
    margin: '0px 14px',
  },
  '.disable-btn': {
    background: '#C9C9C9',
  },
  '.email-text': {
    marginTop: '13px',
    fontSize: '12px',
    color: '#122E54',
  },
  '.min-height': {
    minHeight: '200px !important',
  },
}));

const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '9.5px 14px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8A',
  },
}));
const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
      // width: 250,
    },
  },
};

const AddMobileUser = () => {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [modalValues, setModalValues] = useState({});
  const { data: mobileUser, isSuccess } = useGetMobileUser(id, !!id);
  const user: {
    name: string;
    uniqId: string;
    department: {
      name: string;
      id: string;
    };
    email: string;
    mobile: string;
  } = mobileUser?.user;
  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  // const { data: getDepartments } = useGetDepartments(true, 1);
  const { data: getDepartments } = useGetFullDepartments();

  const breadCrumbData = [
    {
      title: 'User Management',
      link: '/user-management',
      activeTab: '2',
    },
    {
      title: `${isEdit ? `Edit ${user?.name}` : 'Add new user'}`,
      link: '',
    },
  ];
  const icon = <AiOutlineInfoCircle color='red' />;

  useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
  }, [id]);

  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={`${isEdit ? 'Edit' : 'Add new'}  Mobile user`}
        backToLink='/user-management'
        activeTab='2'
      />
      <HeadBox className='main-component-padding'>
        <Formik
          initialValues={{
            name: isEdit ? user?.name : '',
            uniqId: isEdit ? user?.uniqId : '',
            department: isEdit ? user?.department?.id : '',
            email: isEdit ? user?.email : '',
            mobile: isEdit ? user?.mobile : '',
            departmentName: isEdit ? user?.department?.name : '',
          }}
          enableReinitialize
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            const departmentName = getDepartments?.find(
              (data) => data?.id === values?.department
            )?.name;
            setModalValues({ ...values, departmentName });
            handleOpen();
          }}
        >
          {({ errors, touched, handleSubmit, handleChange, values, isValid, dirty }) => (
            <Form onSubmit={handleSubmit}>
              <Typography className='title-typography'> Enter Basic Details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography className='field-title'>
                    User&#39;s Name
                    <Require />
                  </Typography>
                  <Input
                    error={!errors.name || !touched.name ? false : true}
                    placeholder='Enter name'
                    fullWidth
                    onChange={handleChange}
                    name='name'
                    value={values.name || ''}
                    InputProps={{
                      endAdornment: touched.name && errors.name && icon,
                    }}
                  />
                  {touched.name && errors.name && (
                    <Typography className='validation-text'>{errors.name}</Typography>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Typography className='field-title'>
                    User&#39;s ID
                    <Require />
                  </Typography>

                  <Input
                    error={!errors.uniqId || !touched.uniqId ? false : true}
                    placeholder='Enter Id'
                    fullWidth
                    onChange={handleChange}
                    name='uniqId'
                    value={values.uniqId || ''}
                    InputProps={{
                      endAdornment: touched.uniqId && errors.uniqId && icon,
                    }}
                  />
                  {touched.uniqId && errors.uniqId && (
                    <Typography className='validation-text'>{errors.uniqId}</Typography>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Typography className='field-title'>
                    Department
                    <Require />
                  </Typography>
                  <FormControl fullWidth>
                    <Selects
                      error={!errors.department || !touched.department ? false : true}
                      onChange={handleChange}
                      name='department'
                      value={values.department || ''}
                      variant='outlined'
                      displayEmpty
                      MenuProps={MenuProps}
                    >
                      <MenuItem value='' key={100000} disabled>
                        <span className='menu-item-span'>Select Department</span>
                      </MenuItem>
                      {getDepartments?.map((department, ind) => {
                        return (
                          <MenuItem key={ind} value={department.id}>
                            {department.name}
                          </MenuItem>
                        );
                      })}
                    </Selects>

                    {touched.department && errors.department && (
                      <Typography className='validation-text'>
                        {isEdit ? user?.department.id : errors.department}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <Typography className='field-title'>
                    Mobile Number
                    <Require />
                  </Typography>

                  <Input
                    error={!errors.mobile || !touched.mobile ? false : true}
                    placeholder='Enter Phone Number'
                    fullWidth
                    onChange={handleChange}
                    name='mobile'
                    value={values.mobile || ''}
                    InputProps={{
                      endAdornment: touched.mobile && errors.mobile && icon,
                    }}
                  />
                  {touched.mobile && errors.mobile && (
                    <Typography className='validation-text'>{errors.mobile}</Typography>
                  )}
                </Grid>
              </Grid>
              <Divider />
              <Typography className='title-typography'> User Email Id</Typography>
              <Grid container spacing={2} mb={'18.5%'}>
                <Grid item xs={4} className='min-height'>
                  <Typography className='field-title'>
                    Email ID for login
                    <Require />
                  </Typography>

                  <Input
                    error={!errors.email || !touched.email ? false : true}
                    placeholder='Enter Email Id'
                    fullWidth
                    value={values.email || ''}
                    onChange={handleChange}
                    name='email'
                    InputProps={{
                      endAdornment: touched.email && errors.email && icon,
                    }}
                  />
                  {touched.email && errors.email && (
                    <Typography className='validation-text'>{errors.email}</Typography>
                  )}
                  {values.email && (
                    <Typography className='email-text'>
                      Email will be sent to the user with the login credentials.
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Box className='button-box'>
                <Box className='footer-padding'>
                  <CustomButton
                    className={!id && !(isValid && dirty) ? 'disable-btn' : 'blue-bg'}
                    variant='contained'
                    type='submit'
                  >
                    {isEdit ? 'Save Changes' : 'Save Worker'}
                  </CustomButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        {isModalOpen && (
          <UserMobile isOpen={isModalOpen} userValues={modalValues} handleClose={handleClose} />
        )}
      </HeadBox>
      <Loading loading={id && !isSuccess ? true : false} background='white' loaderColor='#265AA8' />
    </React.Fragment>
  );
};
export default AddMobileUser;
