import React, { useState } from 'react';
import { Grid } from '@mui/material';
import CardHead from '../commonComponents/CardHead';
import RecentActivityCard from '../commonComponents/RecentActivityCard';
// import PieChartHead from '../commonComponents/PieChartHead';
import TicketCard from '../commonComponents/TicketCard';
import TopDownTimesCard from '../commonComponents/TopDownTimesCard';
import Highlights from '../commonComponents/Highlights';
import { useGetDashboard, useGetDashboardCharts } from '../../hooks/dashboardHooks';
import ProductionDelayHighlights from '../commonComponents/ProductionDelayHighlights';
import moment from 'moment';
import GanttChartNew from './GanttChartNew';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import GanttChartSF from './GanttChartSF';

const Dashboard_New = () => {
  const { data: dashboardData } = useGetDashboard(true);
  const currentDate: any = moment();
  const weekStart = currentDate.clone().startOf('week');
  const weekEnd = currentDate.clone().endOf('week');
  const [dates, setDatesState] = useState({
    startDate: moment(weekStart).format('YYYY-MM-DD'),
    endDate: moment(weekEnd).format('YYYY-MM-DD'),
  });
  const [worker, setWorker] = useState('All');
  const [machine, setMachine] = useState('All');
  const { data: dashboardCharts } = useGetDashboardCharts(true, {
    startDate: dates.startDate,
    endDate: dates.endDate,
    worker: worker === 'All' ? '' : worker,
    machine: machine === 'All' ? '' : machine,
  });
  const workerEfficiency = dashboardCharts?.chartsData?.workerEfficiencyChart;
  const machinesEfficiency = dashboardCharts?.chartsData?.machinesEfficiencyChart;
  const dailyProduction = dashboardCharts?.chartsData?.dailyProductionChart;
  const productionCount = dashboardCharts?.chartsData?.productionCountChart;
  const workOrderCompletionDelay = dashboardCharts?.chartsData?.workOrderCompletionDelayChart;
  const setDates = (e, { startDate, endDate }) => {
    setDatesState({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    });
  };

  return (
    <React.Fragment>
      <BreadcrumbNavigation breadCrumbData={null} pageTitle={`Dashboard`} backToLink={null} />
      <Grid container spacing={2} mb={2} className='main-padding'>
        <Grid item xs={9} md={12} lg={9}>
          <Highlights
            height={'493px'}
            title={'Highlight'}
            setDatesState={setDatesState}
            dates={dates}
            setDates={setDates}
            workerEfficiency={workerEfficiency}
            machinesEfficiency={machinesEfficiency}
            dailyProduction={dailyProduction}
            setMachine={setMachine}
            machine={machine}
            worker={worker}
            setWorker={setWorker}
          />
        </Grid>
        <Grid item xs={6} md={12} lg={3}>
          <CardHead
            Component={RecentActivityCard}
            height={'443px'}
            title={'Recent Activity'}
            isViewAll={true}
            viewAllLink={'/recent-activity'}
            data={dashboardData?.recentActivities || []}
          />
        </Grid>

        <Grid item xs={9} md={12} lg={9}>
          <ProductionDelayHighlights
            height={'493px'}
            productionCount={productionCount}
            workOrderCompletionDelay={workOrderCompletionDelay}
          />
        </Grid>
        <Grid item xs={3} md={12} lg={3}>
          <CardHead
            height={'443px'}
            isEditIcon={true}
            isViewAll={true}
            Component={TopDownTimesCard}
            title={'Active Downtimes'}
            // data={[1, 2, 3, 4, 5, 6]}
            data={dashboardData?.downtimeHistory || []}
          />
        </Grid>

        <Grid item xs={6} md={12} lg={9}>
          <CardHead
            height={'443px'}
            title={'Schedule'}
            isEditIcon={true}
            //isChart={true}
            isGrant={true}
            // Component={GanttChart}
            Component={GanttChartNew}
          />
        </Grid>
        <Grid item xs={6} md={12} lg={3}>
          <CardHead
            height={'443px'}
            isEditIcon={true}
            isViewAll={true}
            Component={TicketCard}
            title={'Tickets'}
            data={dashboardData?.tickets || []}
          />
        </Grid>
        {/* <Grid item xs={6} md={12} lg={9}>
          <CardHead
            height={'443px'}
            title={'Schedule'}
            isEditIcon={true}
            isGrantSF={true}
            // Component={GanttChart}
            Component={GanttChartSF}
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
};

export default Dashboard_New;
