import React from 'react';
import { Outlet } from 'react-router-dom';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';

const DuplicatePartWrapper = () => {
  const breadCrumbData = [
    {
      title: 'Catalogue',
      link: '/catalogue',
    },
    {
      title: `Parts`,
      link: `/catalogue`,
    },
    {
      title: `Add Variant`,
      link: `/part/add-variant/select-part`,
    },
  ];
  return (
    <>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={'Add Variant'}
        backToLink='/catalogue'
      />
      <Outlet />
    </>
  );
};

export default DuplicatePartWrapper;
