import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import {
  Checkbox,
  Divider,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import CustomButton from '../commonComponents/CustomButton';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import Require from '../commonComponents/Required';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import UserDesktop from '../../Modals/UserDesktop';
import { useGetDesktopUser } from '../../hooks/userManagementHooks';
import RadioButton from '../../assets/svg/RadioButton';
import RadioButtonFilled from '../../assets/svg/RadioButtonFilled';
import { useGetFullDepartments } from '../../hooks/departmentHooks';
import Loading from 'react-fullscreen-loading';
import { useGetFullRole } from '../../hooks/userRoleHook';

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required(`User's name is Required`)
    //.matches(/^[A-Za-z]+$/, 'Only alphabets and numbers are allowed'),
    .matches(/^[ a-zA-Z0-9]+$/, 'Only alphabets and numbers are allowed'),
  uniqId: Yup.string().trim().required(`User's ID is Required`),
  userRole: Yup.string().required('Role is required'),
  department: Yup.string().required('Department is Required'),
  email: Yup.string().email('Email must be a valid email').required('Email is Required'),
  mobile: Yup.string()
    .matches(/^\d{10}$/, 'Please enter a valid 10-digit phone number.')
    .required('Phone number is required.'),
});

const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '1px 14px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));

const CheckBoxRole = styled(Checkbox)(() => ({
  padding: '0px 10px 0px 0px !important',
}));

// New Style using ClassName

const HeadBox = styled(Box)(() => ({
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
    position: 'absolute',
    bottom: '1px',
    left: '0px',
    width: '100%',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 8px 0px',
    color: '#000',
  },

  '.disable-btn': {
    background: '#C9C9C9',
  },
  '.email-text': {
    marginTop: '13px',
    fontSize: '12px',
    color: '#122E54',
  },
  '.min-height': {
    minHeight: '200px !important',
  },
}));

const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '9.5px 14px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8A',
  },
}));

const AddDesktopUser = () => {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [modalValues, setModalValues] = useState({});
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // const moduleOptions: string[] = [
  //   'Workorders',
  //   'Resources',
  //   'Inventory',
  //   'Catalogue',
  //   'Processes',
  //   'User Management',
  // ];
  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  // const { data: getDepartments } = useGetDepartments(true, 1);
  const { data: getDepartments } = useGetFullDepartments();
  const icon = <AiOutlineInfoCircle color='red' />;

  useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
  }, [id]);
  const { data: desktopUser, isSuccess } = useGetDesktopUser(id, !!id);
  const { data: userRoles } = useGetFullRole(true);
  // interface userInterface {
  //   name: string;
  //   uniqId: string;
  //   department: {
  //     id: string;
  //     name: string;
  //   };
  //   email: string;
  //   userRole: {
  //     id: string;
  //     name: string;
  //   };
  //   mobile: string;
  // }
  // const finalEditUser: userInterface = desktopUser?.user;
  const finalEditUser = desktopUser?.user;
  const breadCrumbData = [
    {
      title: 'User Management',
      link: '/user-management',
    },
    {
      title: `${isEdit ? finalEditUser?.name : 'Add new user'}`,
      link: '',
    },
  ];
  // const modules: string[] = [];

  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={`${isEdit ? `Edit ${finalEditUser?.name}` : 'Add new desktop user'}`}
        backToLink='/user-management'
      />
      <HeadBox className='main-component-padding'>
        <Formik
          initialValues={{
            name: isEdit ? finalEditUser?.name : '',
            uniqId: isEdit ? finalEditUser?.uniqId : '',
            department: isEdit ? finalEditUser?.department?.id : '',
            // module: isEdit ? initialModule || [] : modules,
            userRole: isEdit ? finalEditUser?.userRole?.name : '',
            email: isEdit ? finalEditUser?.email : '',
            mobile: isEdit ? finalEditUser?.mobile : '',
          }}
          enableReinitialize={true}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            const updatedValues = {
              ...values,
              roleId: !id
                ? userRoles?.find((user) => user.name === values.userRole)?.id
                : values.userRole
                ? userRoles?.find((user) => user.name === values.userRole)?.id
                : finalEditUser?.userRole?.id,
              departmentName: getDepartments?.find((data) => data?.id === values.department)?.name,
            };
            setModalValues(updatedValues);
            handleOpen();
          }}
        >
          {({ errors, touched, handleSubmit, handleChange, values, isValid, dirty }) => (
            <Form onSubmit={handleSubmit}>
              <Typography className='title-typography'> Enter Basic Details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography className='field-title'>
                    User&#39;s Name
                    <Require />
                  </Typography>
                  <Input
                    error={!errors.name || !touched.name ? false : true}
                    placeholder='Enter name'
                    fullWidth
                    onChange={handleChange}
                    name='name'
                    value={values?.name || ''}
                    InputProps={{
                      endAdornment: touched.name && errors.name && icon,
                    }}
                  />
                  {touched.name && errors.name && (
                    <Typography className='validation-text'>
                      {isEdit ? errors?.name : errors.name}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Typography className='field-title'>
                    User&#39;s ID
                    <Require />
                  </Typography>

                  <Input
                    error={!errors.uniqId || !touched.uniqId ? false : true}
                    placeholder='Enter worker Id'
                    fullWidth
                    onChange={handleChange}
                    name='uniqId'
                    value={values?.uniqId || ''}
                    InputProps={{
                      endAdornment: touched.uniqId && errors.uniqId && icon,
                    }}
                  />
                  {touched.uniqId && errors.uniqId && (
                    <Typography className='validation-text'>{errors.uniqId}</Typography>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Typography className='field-title'>
                    Department
                    <Require />
                  </Typography>
                  <FormControl fullWidth>
                    <Selects
                      error={!errors.department || !touched.department ? false : true}
                      onChange={handleChange}
                      name='department'
                      value={values?.department || ''}
                      variant='outlined'
                      defaultValue=''
                      displayEmpty
                      MenuProps={MenuProps}
                    >
                      <MenuItem value='' disabled>
                        <span className='menu-item-span'>Select Department</span>
                      </MenuItem>
                      {getDepartments?.map((department, ind) => {
                        return (
                          <MenuItem key={ind} value={department.id}>
                            {department.name}
                          </MenuItem>
                        );
                      })}
                    </Selects>

                    {touched.department && errors.department && (
                      <Typography className='validation-text'>
                        {isEdit ? finalEditUser?.department?.name : errors.department}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <Typography className='field-title'>
                    Mobile Number
                    <Require />
                  </Typography>

                  <Input
                    error={!errors.mobile || !touched.mobile ? false : true}
                    placeholder='Enter Phone Number'
                    fullWidth
                    onChange={handleChange}
                    name='mobile'
                    value={values?.mobile || ''}
                    InputProps={{
                      endAdornment: touched.mobile && errors.mobile && icon,
                    }}
                  />
                  {touched.mobile && errors.mobile && (
                    <Typography className='validation-text'>{errors.mobile}</Typography>
                  )}
                </Grid>
              </Grid>
              <Divider />
              <Typography className='title-typography'> Module Access</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography className='field-title'>
                    Role
                    <Require />
                  </Typography>
                  <FormControl fullWidth>
                    {/* <InputLabel id='demo-multiple-checkbox-label'>Select Role</InputLabel> */}
                    <Selects
                      name='userRole'
                      labelId='demo-multiple-checkbox-label'
                      id='demo-multiple-checkbox'
                      // multiple
                      value={values?.userRole || ''}
                      sx={{ borderRadius: '10px' }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      // input={<OutlinedInput label='Select Modules' />}
                      renderValue={(selected: any) => {
                        if (!selected) {
                          return <span className='menu-item-span'>Select Role</span>;
                        }
                        return selected;
                      }}
                      MenuProps={MenuProps}
                      displayEmpty
                    >
                      <MenuItem value='' disabled>
                        <span className='menu-item-span'>Select Role</span>
                      </MenuItem>
                      {userRoles &&
                        userRoles?.length > 0 &&
                        userRoles?.map((roleDetails, ind) => {
                          return (
                            <MenuItem key={ind} value={roleDetails?.name}>
                              <CheckBoxRole
                                // checked={values.module?.indexOf(name) > -1}
                                checked={values?.userRole === roleDetails?.name}
                                icon={<RadioButton />}
                                checkedIcon={<RadioButtonFilled />}
                              />
                              <ListItemText primary={roleDetails?.name} />
                            </MenuItem>
                          );
                        })}
                    </Selects>
                    {touched.userRole && errors.userRole && (
                      <Typography className='validation-text'>{errors?.userRole}</Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Divider />
              <Typography className='title-typography'> User Email Id</Typography>
              <Grid container spacing={2} mb={'6.9%'}>
                <Grid item xs={4} className='min-height'>
                  <Typography className='field-title'>
                    Email ID for login
                    <Require />
                  </Typography>

                  <Input
                    error={!errors.email || !touched.email ? false : true}
                    placeholder='Enter Email Id '
                    fullWidth
                    onChange={handleChange}
                    name='email'
                    value={values.email || ''}
                    InputProps={{
                      endAdornment: touched.email && errors.email && icon,
                    }}
                  />
                  {touched.email && errors.email && (
                    <Typography className='validation-text'>{errors?.email}</Typography>
                  )}
                  {values.email && (
                    <Typography className='email-text'>
                      Email will be sent to the user with the login credentials.
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Box className='button-box'>
                <Box className='footer-padding'>
                  <CustomButton
                    className={!id && !(isValid && dirty) ? 'disable-btn' : 'blue-bg'}
                    variant='contained'
                    type='submit'
                  >
                    Continue
                  </CustomButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        {isModalOpen && (
          <UserDesktop isOpen={isModalOpen} userData={modalValues} handleClose={handleClose} />
        )}
      </HeadBox>
      <Loading loading={id && !isSuccess ? true : false} background='white' loaderColor='#265AA8' />
    </React.Fragment>
  );
};
export default AddDesktopUser;
