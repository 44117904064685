import { get, post, patch, deleteRequest } from '../Utils/index';

export const addChecklist = async (data) => {
  return post(`/customer/checklist`, data);
};
export const getSingleChecklist = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/checklist/${id}`);
};
export const getChecklists = async (data) => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10 };
  return get(`/customer/checklist`, payload);
};
export const getFullChecklists = async () => {
  return get(`/customer/checklist/full-list`);
};
export const updateChecklist = async (data) => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/checklist/${id}`, data);
};

export const deleteChecklist = async (id) => {
  return deleteRequest(`/customer/checklist/${id}`);
};
export const checklistApi = {
  addChecklist,
  getSingleChecklist,
  getChecklists,
  getFullChecklists,
  deleteChecklist,
  updateChecklist,
};
