import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';
import * as _ from 'lodash';
import { useForgotPassword } from '../hooks/authHooks';
import './../assets/css/modal.css';
import { Divider } from '@mui/material';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
};

// New Style using ClassName
const HorizontalDivider = styled(Divider)(() => ({
  margin: '16px 0px !important',
}));

const HeadBoxWrapper = styled(Box)(() => ({
  '.note-title': {
    fontSize: '12px',
    // padding: '0px 0px 0px 24px',
    color: '#014686',
    marginTop: '8px',
  },
  '.note-font-weight': {
    fontWeight: 700,
  },
  '.box-sub-info': {
    padding: '16px 24px 0px 24px !important',
  },
}));
const ResetPassword = ({ isOpen, handleOpen, handleClose, data }: any) => {
  const { mutate: forgotPassword } = useForgotPassword();
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby=''
      aria-describedby='modal-modal-description'
    >
      <HeadBoxWrapper sx={style}>
        <Box className='title-head-box'>
          <Typography className='top-up-name'>Reset Password for the user</Typography>
          <CloseIcon onClick={handleClose} className='close-icon' />
        </Box>
        <HorizontalDivider />
        <Box className='modal-body'>
          <Box className='section-one'>
            <Typography className='field-title pl-sub-text'>
              The password for {_.get(data, 'name', '')} will be reset to the following:
            </Typography>
          </Box>
          <Box className='box-sub-info'>
            <Box className='note-title'>
              <span className='note-font-weight'>Note:</span>{' '}
              <span>
                {' '}
                An email will be sent to the user regarding the same, the user can setup a new
                password using the link in the email.
              </span>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className='sub-box'>
            <CustomModalButton
              variant='contained'
              type='submit'
              className='blue-bg ml-20'
              onClick={() => {
                forgotPassword({ email: data?.email });
                handleClose();
              }}
            >
              Confirm
            </CustomModalButton>
          </Box>
        </Box>
      </HeadBoxWrapper>
    </Modal>
  );
};
export default ResetPassword;
