import React from 'react';

const DeleteIcon_new = () => {
  return (
    <React.Fragment>
      <svg
        width='15'
        height='16.25'
        viewBox='0 0 16 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M14.875 2.75H1.125C0.779822 2.75 0.5 3.02982 0.5 3.375C0.5 3.72018 0.779822 4 1.125 4H14.875C15.2202 4 15.5 3.72018 15.5 3.375C15.5 3.02982 15.2202 2.75 14.875 2.75Z'
          fill='#0160B9'
        />
        <path
          d='M5.5 7.125V12.125C5.5 12.4702 5.77982 12.75 6.125 12.75C6.47018 12.75 6.75 12.4702 6.75 12.125V7.125C6.75 6.77982 6.47018 6.5 6.125 6.5C5.77982 6.5 5.5 6.77982 5.5 7.125Z'
          fill='#0160B9'
        />
        <path
          d='M9.25 7.125V12.125C9.25 12.4702 9.52982 12.75 9.875 12.75C10.2202 12.75 10.5 12.4702 10.5 12.125V7.125C10.5 6.77982 10.2202 6.5 9.875 6.5C9.52982 6.5 9.25 6.77982 9.25 7.125Z'
          fill='#0160B9'
        />
        <path
          d='M3 15.25V3.375C3 3.02982 2.72018 2.75 2.375 2.75C2.02982 2.75 1.75 3.02982 1.75 3.375V15.25C1.75 15.7678 2.11612 16.1339 2.11612 16.1339C2.48223 16.5 3 16.5 3 16.5H13C13.5178 16.5 13.8839 16.1339 13.8839 16.1339C14.25 15.7678 14.25 15.25 14.25 15.25V3.375C14.25 3.02982 13.9702 2.75 13.625 2.75C13.2798 2.75 13 3.02982 13 3.375V15.25H3Z'
          fill='#0160B9'
        />
        <path
          d='M4.79918 0.799175C4.25 1.34835 4.25 2.125 4.25 2.125V3.375C4.25 3.72018 4.52982 4 4.875 4C5.22018 4 5.5 3.72018 5.5 3.375V2.125C5.5 1.86612 5.68306 1.68306 5.68306 1.68306C5.86612 1.5 6.125 1.5 6.125 1.5H9.875C10.1339 1.5 10.3169 1.68306 10.3169 1.68306C10.5 1.86612 10.5 2.125 10.5 2.125V3.375C10.5 3.72018 10.7798 4 11.125 4C11.4702 4 11.75 3.72018 11.75 3.375V2.125C11.75 1.34835 11.2008 0.799175 11.2008 0.799175C10.6517 0.25 9.875 0.25 9.875 0.25H6.125C5.34835 0.25 4.79918 0.799175 4.79918 0.799175Z'
          fill='#0160B9'
        />
      </svg>
    </React.Fragment>
  );
};

export default DeleteIcon_new;
