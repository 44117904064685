import { PartErrI, PartFullListI, PartI, PartListErrI, PartListI } from '../Types/part';
import { get, post, patch, deleteRequest } from '../Utils/index';

export const addPart = async (data): Promise<PartI | PartErrI> => {
  delete data['supplierId'];
  return post(`/customer/part`, data).then((res) => res.data);
};

export const getSinglePart = async (data): Promise<PartI | PartErrI> => {
  const id = data?.queryKey[1];
  return get(`/customer/part/${id}`).then((res) => res.data);
};

export const getSingleDeletedPart = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/part/deleted/${id}`);
};

export const updatePart = async (data): Promise<PartI | PartErrI> => {
  const id = data?.id;
  delete data['id'];
  delete data['supplierId'];
  return patch(`/customer/part/${id}`, data).then((res) => res.data);
};

export const restorePart = async (id) => {
  return patch(`/customer/part/restore/${id}`);
};

export const hardDeletePart = async (id) => {
  return deleteRequest(`/customer/part/hard-delete/${id}`);
};

export const deletePart = async (id) => {
  return deleteRequest(`/customer/part/${id}`);
};

export const getParts = async (
  data
): Promise<PartListI | undefined | { parts: { results: [] } } | PartListErrI> => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10, type: 'part' };
  return get(`/customer/part`, payload).then((res) => res.data);
};

export const getFullParts = async (): Promise<PartFullListI | { parts: [] }> => {
  return get(`/customer/part/parts/full-list`).then((res) => res.data);
};
export const partApi = {
  addPart,
  getSinglePart,
  updatePart,
  deletePart,
  getParts,
  getFullParts,
  hardDeletePart,
  restorePart,
  getSingleDeletedPart,
};
