import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorPopup from './ErrorPopup';
import { useParams } from 'react-router-dom';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';
import './../assets/css/modal.css';
import Require from '../pages/commonComponents/Required';
import { useAddProduct, useUpdateProduct } from '../hooks/productHooks';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  //border: '2px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
};

const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  fontSize: '14px !important',
  padding: '24px 0px 0px 0px',
}));
const InputField = styled(TextField)(() => ({
  borderRadius: 'rpx',
  width: '400px',
  // margin: '0px 0px 0px 24px',
  // height: "10px",
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '0px 14px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
    '&:hover fieldset': {
      // borderColor: "yellow"
    },
    '&.Mui-focused fieldset': {
      // borderColor: "green"
    },
  },
}));
const InventoryBox = styled(Box)(() => ({
  '.note-title': {
    fontSize: '12px',
    // padding: '0px 0px 0px 24px',
    color: '#014686',
    marginTop: '8px',
  },
  '.note-font-weight': {
    fontWeight: 700,
  },
  '.box-sub-info': {
    padding: '0px 24px !important',
  },
}));
interface Prop {
  handleClose: () => void;
  isOpen: boolean;
  data?: any;
}
const AddProductStock = (props: Prop) => {
  const { id } = useParams();
  const { handleClose, isOpen: open } = props;
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const [showStockErr, setShowStockErr] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [avlStock, setAvlStock] = React.useState(0);
  const {
    mutate: addProduct,
    isError: isAddProductError,
    error: addProductError,
    isLoading,
  } = useAddProduct();
  const {
    mutate: updateProduct,
    isError: isUpdateProductError,
    error: updateProductError,
  } = useUpdateProduct();

  React.useEffect(() => {
    if (isAddProductError || isUpdateProductError) {
      isAddProductError
        ? setErrorMessage(addProductError?.data?.message)
        : setErrorMessage(updateProductError?.data?.message);
      setIsErrorModalOpen(true);
    }
  }, [isAddProductError, isUpdateProductError]);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <InventoryBox sx={style}>
          <Box>
            <Box className='title-head-box'>
              <Typography id='modal-modal-title' className='top-up-name'>
                Add Product Stock for {props?.data?.name}
              </Typography>
              <CloseIcon onClick={handleClose} className='close-icon' />
            </Box>
            <Box className='box-sub-info'>
              <Box className='note-title'>
                <span className='note-font-weight'>Note:</span>{' '}
                <span>Once you confirm, the changes will be save in the inventory.</span>
              </Box>
              <TextFiled className=''>
                {/* Existing Quantity: <span className='span-color'>{props?.data?.avlStock || 0}</span> */}
                Existing Quantity{' '}
              </TextFiled>
              <Typography className='span-color'>{props?.data?.avlStock || 0}</Typography>
              <TextFiled mb={1}>
                Updated Quantity
                <Require />
              </TextFiled>
              <InputField
                type={'number'}
                placeholder='Enter Updated Quantity'
                name='name'
                onChange={(e) => {
                  setAvlStock(parseInt(e.target.value));
                  if (parseInt(e.target.value) > 0) {
                    setShowStockErr(false);
                  } else {
                    setShowStockErr(true);
                  }
                }}
              />
              {showStockErr && (
                <Typography className='validation-text'>
                  {'Please Enter Valid Stock Quantity'}
                </Typography>
              )}
            </Box>

            <Box className='sub-box'>
              <CustomModalButton
                variant='outlined'
                className='blue-color edit-btn'
                onClick={handleClose}
              >
                Cancel
              </CustomModalButton>
              <CustomModalButton
                disabled={isLoading}
                variant='contained'
                type='submit'
                className='blue-bg ml-20'
                onClick={() => {
                  if (avlStock && !showStockErr) {
                    id || props.data?.id
                      ? updateProduct({ ...props.data, avlStock, id: id || props.data?.id })
                      : addProduct({ ...props.data, avlStock });
                  } else {
                    setShowStockErr(true);
                  }
                }}
              >
                Confirm
              </CustomModalButton>
            </Box>
          </Box>
        </InventoryBox>
      </Modal>
      {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
      )}
    </div>
  );
};
export default AddProductStock;
