import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import Paper from "@mui/material/Paper";
import { Form, Formik } from 'formik';
import { Typography, Select, MenuItem, FormControl } from '@mui/material';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFullGetParts } from '../../hooks/partHooks';
import Require from '../commonComponents/Required';
import CustomButton from '../commonComponents/CustomButton';

const SignupSchema = Yup.object().shape({
  selectPart: Yup.string().required('Part name is Required'),
});

const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '9.5px 14px',
  },
}));

const BoxWrapper = styled(Box)(() => ({
  height: '82vh',
  '.main-box': {
    marginBottom: '55vh',
  },
  '.select-box': {
    width: '400px',
    borderRadius: '10px',
  },
  '.mb-6': {
    marginBottom: '6px',
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
    // padding: '20px',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
    position: 'absolute',
    bottom: '1px',
    left: '0px',
    width: '100%',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.footer-padding': {
    padding: '16px 56px 16px 20px',
  },
}));
const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
      // width: 250,
    },
  },
};

const SelectPart = () => {
  const navigate = useNavigate();

  const { data: getParts } = useFullGetParts(true);

  const breadCrumbData = [
    {
      title: 'Catalogue',
      link: '/catalogue',
    },
    {
      title: `Part`,
      link: ``,
    },
    {
      title: `Add Variant`,
      link: ``,
    },
  ];
  return (
    <React.Fragment>
      <BoxWrapper className='main-component-padding'>
        {/* <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={`Add Variant`}
          backToLink='/catalogue'
        /> */}
        <Formik
          initialValues={{
            selectPart: '',
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            navigate(`/part/add-variant/add/${values.selectPart}`);
          }}
        >
          {({ errors, touched, handleSubmit, handleChange, values, isValid, dirty }) => (
            <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Box className='main-box'>
                <Typography className='title-typography'>Select the Part to Add Variant</Typography>
                <Typography className='mb-6'>
                  Part
                  <Require />
                </Typography>
                <FormControl fullWidth>
                  <Selects
                    error={!errors.selectPart || !touched.selectPart ? false : true}
                    className='select-box'
                    onChange={handleChange}
                    name='selectPart'
                    variant='outlined'
                    value={values.selectPart}
                    displayEmpty
                    MenuProps={MenuProps}
                  >
                    <MenuItem value='' disabled>
                      <span className='menu-item-span'>Select part</span>
                    </MenuItem>
                    {getParts?.map((part, ind) => {
                      return (
                        <MenuItem value={part?.id} key={ind}>
                          {part?.name}
                        </MenuItem>
                      );
                    })}
                  </Selects>
                  {touched.selectPart && errors.selectPart && (
                    <Typography className='validation-text'>{errors.selectPart}</Typography>
                  )}
                </FormControl>
              </Box>
              <Box>
                <Box className='button-box'>
                  <Box className='footer-padding'>
                    <CustomButton
                      className={!(isValid && dirty) ? 'disable-btn' : 'blue-bg'}
                      variant='contained'
                      type='submit'
                    >
                      Continue
                    </CustomButton>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </BoxWrapper>
    </React.Fragment>
  );
};
export default SelectPart;
