import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';
import { useNavigate, useParams } from 'react-router-dom';
import * as _ from 'lodash';
import { useAddDesktopUser, useUpdateDesktopUser } from '../hooks/userManagementHooks';
import ErrorPopup from './ErrorPopup';
import './../assets/css/modal.css';
import { Grid } from '@mui/material';
import CustomSubTitle from '../pages/commonComponents/CustomSubTitle';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  overflow: 'auto',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: '66%',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
};

const CustomTypography = styled(Typography)(() => ({
  color: 'gray',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

// New Style using ClassName

const HeadBoxWrapper = styled(Box)(() => ({
  '.email-text': {
    color: '#265AA8',
    fontSize: '14px',
  },
  '.note-title': {
    fontSize: '12px',
    padding: '20px 0px 0px 24px',
    color: '#014686',
    marginTop: '8px',
  },
  '.note-font-weight': {
    fontWeight: 700,
  },
}));
type ISomeType = { [key: string]: string };
interface propI {
  isOpen: boolean;
  handleClose: () => void;
  userData: ISomeType;
}
const UserDesktop = (props: propI) => {
  const { isOpen, handleClose, userData } = props;
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleErrorModalOpen = () => setIsErrorModalOpen(true);
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const allSingleData = { ...userData };
  const { id } = useParams();

  const {
    mutate: addUser,
    isLoading,
    isError: isAddDesktopError,
    error: addDesktopError,
  } = useAddDesktopUser();
  const {
    mutate: updateUser,
    isError: isUpdateDesktopError,
    error: updateDesktopError,
  } = useUpdateDesktopUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAddDesktopError || isUpdateDesktopError) {
      isAddDesktopError
        ? setErrorMessage(addDesktopError?.data?.message)
        : setErrorMessage(updateDesktopError?.data?.message);
      setIsErrorModalOpen(true);
      // handleClose();
    }
  }, [isAddDesktopError, isUpdateDesktopError]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby=''
      aria-describedby='modal-modal-description'
    >
      <HeadBoxWrapper sx={style}>
        <Box>
          <Box className='title-head-box'>
            <Typography className='top-up-name'>Please confirm the user details</Typography>
            <CloseIcon onClick={handleClose} className='close-icon' />
          </Box>
          <Box className='modal-body'>
            <Box>
              <CustomSubTitle>User&#39;s Details</CustomSubTitle>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <CustomTypography>User&#39;s Name: </CustomTypography>
                  <Typography className='span-color pl-sub-text '>
                    {_.get(userData, 'name', '-')}
                  </Typography>
                  <CustomTypography>Department Name:</CustomTypography>
                  <Typography className='span-color pl-sub-text '>
                    {_.get(userData, 'departmentName', '-')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <CustomTypography>User&#39;s ID: </CustomTypography>
                  <Typography className='span-color pl-sub-text '>
                    {_.get(userData, 'uniqId', '-')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <CustomSubTitle>Module Access</CustomSubTitle>
              <CustomTypography>Modules: </CustomTypography>
              <Typography className='span-color pl-sub-text '>
                {_.get(userData, 'userRole', '-')}
              </Typography>
            </Box>
            <Box>
              <CustomSubTitle>Email ID</CustomSubTitle>
              <CustomTypography>Email ID for login: </CustomTypography>
              <Typography className='span-color pl-sub-text '>
                {_.get(userData, 'email', '-')}
              </Typography>
            </Box>
            <Box className='note-title'>
              <span className='note-font-weight'>Note:</span>{' '}
              <span> Email will be sent to the user with the login credentials.</span>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className='sub-box'>
            <CustomModalButton
              variant='outlined'
              className='blue-color'
              onClick={() => {
                handleClose();
                //navigate('/edit-desktop-user/userId');
              }}
            >
              Edit Details
            </CustomModalButton>
            <CustomModalButton
              disabled={isLoading}
              variant='contained'
              type='submit'
              className='blue-bg ml-20'
              onClick={() => {
                const userRole = allSingleData?.roleId;
                const updatedUser = {
                  department: allSingleData?.department,
                  email: allSingleData?.email,
                  mobile: allSingleData?.mobile,
                  name: allSingleData?.name,
                  uniqId: allSingleData?.uniqId,
                  userRole,
                  password: allSingleData?.password,
                };
                if (id) {
                  updateUser({ ...updatedUser, id });
                } else {
                  addUser({ ...updatedUser });
                }
              }}
            >
              Confirm & Save
            </CustomModalButton>
          </Box>
        </Box>
        <></>
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
      </HeadBoxWrapper>
    </Modal>
  );
};
export default UserDesktop;
