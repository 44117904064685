import { useMutation, useQueryClient, useQuery, QueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { number } from 'yup';
import { machineApi } from '../api/machine';
import { machineMaintenanceDetailsApi } from '../api/machineMaintenanceDetails';
import { machine } from '../queryKeys/resources';

export const useGetMachine = (id: any, isEnable = true) => {
  const queryClient = useQueryClient();
  return useQuery([machine.MACHINE, id], machineApi.getSingleMachine, {
    select: (data) => data,
    enabled: isEnable,
    onSuccess: (res: any) => {
      const data = res;
      //toast.success(data?.message);
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};
export const useGetDeletedMachine = (id: any, isEnable = true) => {
  const queryClient = useQueryClient();
  return useQuery([machine.MACHINE + 'DELETED', id], machineApi.getSingleDeletedMachine, {
    select: (data) => data.data,
    enabled: isEnable,
    onSuccess: (res: any) => {
      const data = res?.data;
      //toast.success(data?.message);
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};

export const useGetMachines = (isEnabled = true, page: number) => {
  return useQuery([machine.MACHINES, page], machineApi.getMachines, {
    select: (data) => data?.data?.machines,
    onSuccess: (res: any) => {
      const data = res?.data;
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};

export const useGetFullMachines = () => {
  return useQuery([machine.MACHINES + 'FULL'], machineApi.getFullMachines, {
    select: (data) => data?.data?.machines,
    onSuccess: (res: any) => {
      const data = res?.data;
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};

export const useAddMachine = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(machineApi.addMachine, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([machine.MACHINES]);
      const data = res.data;
      //toast.success(data?.message);
      // navigate(`/machine-details/${data?.machine.id}`);
      navigate(`/resources`, { state: { activeTab: '1' } });
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useUpdateMachine = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(machineApi.updateMachine, {
    onSuccess: (res, id) => {
      queryClient.invalidateQueries([machine.MACHINES]);
      queryClient.invalidateQueries([machine.MACHINE, id]);
      queryClient.refetchQueries([machine.MACHINE, id]);
      // queryClient.removeQueries([machine.MACHINE, id]);
      const data = res.data;
      //toast.success(data?.message);
      // navigate(`/machine-details/${data?.machine?.id}`);
      navigate(`/resources`, { state: { activeTab: '1' } });
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useDeleteMachine = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(machineApi.deleteMachine, {
    onSuccess: (res, id) => {
      const data = res.data;
      queryClient.invalidateQueries([machine.MACHINES]);
      queryClient.removeQueries([machine.MACHINE, id]);
      //toast.success(data.message);
      navigate(`/resources`, { state: { activeTab: '1' } });
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useDeleteMachineMaintenanceDetails = () => {
  const queryClient = useQueryClient();
  return useMutation(machineMaintenanceDetailsApi.deleteMaintenanceDetails, {
    onSuccess: (res, payload) => {
      const data = res.data;
      const { machineId } = payload;
      //toast.success(data.message);
      queryClient.invalidateQueries([machine.MACHINES]);
      queryClient.refetchQueries([machine.MACHINE, machineId]);
      queryClient.setQueryData([machine.MACHINE, machineId], () => data);
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useUpdateMachineMaintenanceDetails = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(machineMaintenanceDetailsApi.updateMaintenanceDetails, {
    onSuccess: (res, payload) => {
      const data = res.data;
      const { id } = payload;
      //toast.success(data.message);
      queryClient.invalidateQueries([machine.MACHINES]);
      queryClient.fetchQuery([machine.MACHINE, id]);
      queryClient.setQueryData([machine.MACHINE, id], () => data?.machine?.maintenanceDetails);
      //queryClient.getQueryData([machine.MACHINE, id]);
      //window.location.reload();
      navigate(`/edit-machine/${data?.machine.id}`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useRestoreMachine = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(machineApi.restoreMachine, {
    onSuccess: (res, id) => {
      const data = res.data;
      queryClient.invalidateQueries([machine.MACHINES]);
      // queryClient.invalidateQueries([part.PART, id]);
      //toast.success(data.message);
      // const materialType = data?.part?.partMaterialType;
      navigate('/deleted-items');
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useHardDeleteMachine = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(machineApi.hardDeleteMachine, {
    onSuccess: (res, id) => {
      const data = res.data;
      queryClient.invalidateQueries([machine.MACHINES]);
      // queryClient.invalidateQueries([part.PART, id]);
      navigate('/deleted-items');
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};
