import * as React from 'react';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';
import { Divider, Grid } from '@mui/material';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import {
  useDeleteMobileUser,
  useGetDeletedMobileUser,
  useGetMobileUser,
  useHardDeleteMobileUser,
  useRestoreMobileUser,
} from '../../hooks/workerManagementHooks';
import * as _ from 'lodash';
import CustomModalButton from '../commonComponents/CustomModalButton';
import ResetPassword from '../../Modals/ResetPassword';
import Loading from 'react-fullscreen-loading';
import './../../assets/css/detailsInformationBox.css';
import ErrorPopup from '../../Modals/ErrorPopup';

// New Style using ClassName

const HeadBoxWaper = styled(Box)(() => ({
  paddingBottom: '40px',
  '.mt-45': {
    marginTop: '45px',
  },
  '.btn-margin': {
    margin: '24px 0px 0px 24px',
  },
}));

const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '10px',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

const UserMobileDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const isDeleted = location.pathname.startsWith('/deleted-items');
  const isRestore = location.pathname.startsWith('/deleted-items');
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);
  // mobile user
  const {
    data: singleMobileUser,
    isSuccess: isMobileSuccess,
    isError,
  } = useGetMobileUser(id, !!id && !isDeleted);
  const { data: getMobileUserDeleted, isSuccess: isSuccessDeleted } = useGetDeletedMobileUser(
    id,
    !!id && isDeleted
  );
  const currentUser = isDeleted ? getMobileUserDeleted?.MobileUser : singleMobileUser?.user;
  const {
    mutate: deleteSingleMobileUser,
    isError: isDeleteMobileWorkerError,
    error: deleteMobileWorkerError,
  }: any = useDeleteMobileUser();
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  React.useEffect(() => {
    if (isDeleteMobileWorkerError) {
      setErrorMessage(deleteMobileWorkerError?.data?.message);
      setIsErrorModalOpen(true);
      // handleClose();
    }
  }, [isDeleteMobileWorkerError]);
  const { mutate: hardDeleteMobileUser } = useHardDeleteMobileUser();
  const { mutate: restoreMobileUser } = useRestoreMobileUser();

  const handleMobileUserDelete = () => {
    if (isDeleted) {
      hardDeleteMobileUser(id);
    } else {
      deleteSingleMobileUser(id);
    }
  };

  const handleMobileUserRestore = () => {
    if (isRestore) {
      restoreMobileUser(id);
    } else {
      // nothing to restore
    }
  };
  const breadCrumbData = [
    {
      title: 'User Management',
      link: '/user-management',
      activeTab: '2',
    },
    {
      title: _.get(currentUser, 'name', `-`),
      link: `/mobile-user-details/${id}`,
    },
  ];

  const detailPageData = [
    {
      title: 'edit',
      link: `/edit-mobile-user/${id}`,
    },
    {
      title: 'delete',
      link: '',
    },
  ];

  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={_.get(currentUser, 'name', `-`)}
        backToLink='/user-management'
        activeTab='2'
        isDetailPage={true}
        detailPageData={detailPageData}
        isStatusShow={true}
        isOnline={currentUser?.isOnline ? true : false}
        handleDelete={handleMobileUserDelete}
        isDeleted={isDeleted}
        isRestore={isRestore}
        handleRestore={handleMobileUserRestore}
        // handleDelete={handleDelete}
      />
      <HeadBoxWaper className='main-padding'>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box className='details-box-border padding-bottom'>
                <Typography id='modal-modal-title' className='details-sub-title'>
                  Basic details
                </Typography>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <TextFiled>
                      User&#39;s Name:{' '}
                      <Typography className='span-color'>
                        {_.get(currentUser, 'name', '')}
                      </Typography>
                    </TextFiled>
                    <TextFiled>
                      Department:{' '}
                      <Typography className='span-color'>
                        {_.get(currentUser?.department, 'name', '')}
                      </Typography>
                    </TextFiled>
                  </Grid>
                  <Grid item xs={6}>
                    <TextFiled>
                      User&#39;s ID:{' '}
                      <Typography className='span-color'>
                        {_.get(currentUser, 'uniqId', '')}
                      </Typography>
                    </TextFiled>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className='details-box-border padding-bottom'>
                <Typography id='modal-modal-title' className='details-sub-title'>
                  Email ID
                </Typography>
                <TextFiled>
                  Email ID for login:{' '}
                  <Typography className='span-color'>{_.get(currentUser, 'email', '')}</Typography>
                </TextFiled>
                <CustomModalButton className='blue-bg btn-margin' onClick={handleModalOpen}>
                  Reset Password
                </CustomModalButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {isModalOpen && (
          <ResetPassword
            isOpen={isModalOpen}
            handleOpen={handleModalOpen}
            handleClose={handleModalClose}
            data={currentUser}
          />
        )}
        <Loading
          loading={
            id && (isDeleted ? !isSuccessDeleted : !isMobileSuccess)
              ? !isError
                ? true
                : false
              : false
          }
          background='white'
          loaderColor='#265AA8'
        />
      </HeadBoxWaper>
      {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
      )}
    </React.Fragment>
  );
};
export default UserMobileDetails;
