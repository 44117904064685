import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';
import { useAddRole, useUpdateRole } from '../hooks/userRoleHook';
import ErrorPopup from './ErrorPopup';
import './../assets/css/modal.css';
import { Grid } from '@mui/material';
import CustomSubTitle from '../pages/commonComponents/CustomSubTitle';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
};

const CustomTypography = styled(Typography)(() => ({
  color: 'gray',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

// New Style using ClassName

const HeadBoxWrapper = styled(Box)(() => ({}));
type ISomeType = { [key: string]: any };
interface roleI {
  isOpen: boolean;
  handleClose: () => void;
  values: {
    name: string;
    notifications: ISomeType;
    rights: ISomeType;
  };
}

const AddRoles = (props: roleI) => {
  const { isOpen, handleClose, values } = props;
  const { id } = useParams();
  const { mutate: addRole, isError: isAddRoleError, error: addRoleError, isLoading } = useAddRole();
  const {
    mutate: updateRole,
    isError: isUpdateRoleError,
    error: updateRoleError,
  } = useUpdateRole();
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);

  React.useEffect(() => {
    if (isAddRoleError || isUpdateRoleError) {
      isAddRoleError
        ? setErrorMessage(addRoleError?.data?.message)
        : setErrorMessage(updateRoleError?.data?.message);
      setIsErrorModalOpen(true);
      // handleClose();
    }
  }, [isAddRoleError, isUpdateRoleError]);
  const moduleMapping = {
    workOrder: 'Work Order',
    worker: 'Worker',
    machine: 'Machine',
    process: 'Process',
    part: 'Part',
    product: 'Product',
  };
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby=''
      aria-describedby='modal-modal-description'
    >
      <HeadBoxWrapper sx={style}>
        <Box>
          <Box className='title-head-box'>
            <Typography className='top-up-name'>Please confirm the user details</Typography>
            <CloseIcon onClick={handleClose} className='close-icon' />
          </Box>
          <Box className='modal-body'>
            <Box className='section-one'>
              <CustomSubTitle>Basic Details</CustomSubTitle>
              <CustomTypography>Role</CustomTypography>
              <span className='span-color pl-sub-text'>{_.get(values, 'name', '-')}</span>
            </Box>
            <Box className='section-one'>
              <CustomSubTitle>Module Access</CustomSubTitle>
              <Grid container spacing={0}>
                {values?.rights?.map((right, ind) => {
                  const accessedModules = {
                    Create: right?.add,
                    Read: right?.view,
                    Update: right?.edit,
                    Delete: right?.delete,
                  };
                  const displayModules = Object.entries(accessedModules)
                    .filter((data) => data[1])
                    .map((data) => data[0]);
                  return (
                    <Grid item xs={6} key={ind}>
                      <CustomTypography>{moduleMapping[right?.userModule]}: </CustomTypography>
                      <Typography className='span-color pl-sub-text'>
                        {displayModules?.length === 0 ? '-' : displayModules?.join(', ')}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className='sub-box'>
            <CustomModalButton
              className='blue-color'
              onClick={() => {
                handleClose();
              }}
            >
              Edit Details
            </CustomModalButton>
            <CustomModalButton
              disabled={isLoading}
              variant='contained'
              type='submit'
              className='blue-bg ml-20'
              onClick={() => {
                id ? updateRole({ ...values, id }) : addRole(values);
              }}
            >
              Confirm & Save
            </CustomModalButton>
          </Box>
        </Box>
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
      </HeadBoxWrapper>
    </Modal>
  );
};
export default AddRoles;
