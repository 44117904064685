import { Box, Button, Divider, Pagination, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ButtonComponent from './ButtonComponent';
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import CustomizeColumn from '../../assets/svg/CustomizeColumn';
import TableDataListIcon from '../../assets/svg/TableDataListIcon';
import CustomButton from './CustomButton';
import Plus from '../../assets/svg/Plus';

const PaginationWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  width: '92%',
  bottom: '40px',
  // margin: '10px 0px',
});
const CustomTableButton = styled(Button)({
  borderRadius: '4px !important',
  fontSize: '14px !important',
  fontWeight: '600 !important',
  height: '40px',
  textTransform: 'uppercase',
  padding: '10px 16px',
});

interface btnData {
  title: string;
  isBtnShow?: boolean;
  isBtnDisable?: boolean;
  linkData: {
    menuItem: string;
    to: string;
    requestCount?: number;
  }[];
}

interface tableInterface {
  rows: any[];
  columns: any[];
  detailsRedirection?: string;
}

const columns = [
  { id: 'no', label: 'Sr.No', borderBottom: 'none' },
  { id: 'name', label: 'Name', fontcolor: '#fff' },
  {
    id: 'ide',
    label: 'ID',
  },
  {
    id: 'department',
    label: 'Department',
  },
  {
    id: 'line',
    label: 'Line',
  },
  {
    id: 'currentStatus',
    label: 'Current Status',
    display: 'flex',
    alignItems: 'center',
  },
  {
    id: 'nextAvailableOn',
    label: 'Next available on',
  },
  {
    id: 'action',
    label: 'Action',
  },
];

const TableComponent = (props: {
  data?: btnData | undefined;
  tableData?: tableInterface;
  totalPages?: any;
  isOptionShow: boolean;
  isEditStock?: boolean;
  isHideDropDown?: boolean;
  width?: number;
  isLoading?: boolean;
  isStateChanged?: boolean;
  isShowFiveRecords?: boolean;
  paginationData?: any;
  page?: number;
  setPage?: any;
  listName?: string;
  requestCount?: number;
  isNoEntityShow?: boolean;
  isSubscription?: boolean;
  moduleName?: string;
  redirectPage?: string;
}) => {
  const width = props?.width ? props.width : 100;
  let isOptionShow = true;
  if (isOptionShow === true || isOptionShow === false) {
    isOptionShow = props.isOptionShow;
  }
  const navigate = useNavigate();
  const columnsData = props?.tableData ? props.tableData.columns : columns;
  const isNoEntityShow = props?.isNoEntityShow || false;
  const isSubscription = props?.isSubscription || false;
  const { isHideDropDown = false } = props;
  const moduleName = props?.moduleName;
  const redirectPage = props?.redirectPage;
  // const [rowsData, setRowsData] = useState<any>(props?.tableData ? props.tableData.rows : []);
  const [rowsData, setRowsData] = useState<any>([]);
  const CustomDataGrid = styled(DataGrid)(() => ({
    fontSize: '14px',
    '.MuiDataGrid-cell': {
      borderBottom: 'none',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: '#F4F9FF',
    },
    [`& .${gridClasses.cell}.blue-font`]: {
      color: '#0160B9 !important',
      cursor: 'pointer',
      fontWeight: '600',
      fontSize: '14px',
    },
  }));
  let isBtnShow = true;

  if (props?.data?.isBtnShow === true || props.data?.isBtnShow === false) {
    isBtnShow = props?.data?.isBtnShow;
  }

  useEffect(() => {
    if (!props?.isLoading) {
      setRowsData(props?.tableData?.rows);
    }
  }, [props?.isLoading, props.tableData?.rows?.length, props?.isStateChanged, rowsData?.length]);
  const { data, paginationData, page, setPage, listName } = props;

  const CustomDivider = styled(Divider)(() => ({
    margin: '0px 16px 0px 18px !important',
  }));
  const customBtnLink = data?.linkData[0]?.to;
  const isHideOptions =
    location.pathname.startsWith('/edit-product') ||
    location.pathname.startsWith('/add-product') ||
    location.pathname.startsWith('/worker-detail') ||
    location.pathname.startsWith('/machine-details') ||
    location.pathname.startsWith('/deleted-items1111');
  function CustomToolbar() {
    return (
      <Box>
        <Box className='table-toolbar'>
          <Box>
            <Typography className='list-title'>{listName}</Typography>
          </Box>
          <Box>
            {props.isOptionShow && !isHideOptions && (
              <GridToolbarContainer>
                <GridToolbarColumnsButton name='Customize Columns' />
                <CustomDivider orientation='vertical' className='table-verticle-divider' />
                <GridToolbarFilterButton />
                {isBtnShow && (
                  <>
                    <CustomDivider orientation='vertical' className='table-verticle-divider' />
                    {!isHideDropDown ? (
                      <ButtonComponent
                        title={data?.title}
                        linkData={data?.linkData}
                        isBtnDisable={data?.isBtnDisable}
                      />
                    ) : (
                      <>
                        <CustomTableButton
                          className='blue-bg'
                          onClick={() => navigate(`${customBtnLink}`)}
                        >
                          <Plus fill='white' />{' '}
                          <span className='tb-margin-left'>{data?.title}</span>
                        </CustomTableButton>
                      </>
                    )}
                  </>
                )}
              </GridToolbarContainer>
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  // const isTableHeight = location.pathname.startsWith('/my-tickets');
  const isTableHeight = location.pathname.startsWith('/deleted-items');
  const isHistoryTable =
    location.pathname.startsWith('/machine-details') ||
    location.pathname.startsWith('/worker-detail');
  return (
    <React.Fragment>
      {rowsData?.length <= 0 && !props.isLoading && isNoEntityShow && isSubscription ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} minHeight={'500px'}>
          <Box textAlign={'center'}>
            <TableDataListIcon />
            <Typography>{`No '${moduleName}' added yet`}</Typography>
            <Typography mb={3}>{`Add '${moduleName}' to ${moduleName} List`}</Typography>
            {isBtnShow && (
              <CustomButton
                className='blue-color'
                onClick={() =>
                  navigate(`${redirectPage}`, { state: { requestCount: props?.requestCount } })
                }
              >
                <Plus fill={'#0160B9'} /> <span className='ml'>{`Add ${moduleName}`}</span>
              </CustomButton>
            )}
          </Box>
        </Box>
      ) : (
        <>
          <Box
            className={isTableHeight ? 'table-height-ticket' : 'table-height'}
            height={
              isTableHeight
                ? '480px !important'
                : `${
                    width === 50 || props?.isShowFiveRecords
                      ? '181px !important'
                      : '520px !important'
                  }`
            }
            // TODO
            // className={
            //   isTableHeight ? 'table-height' : isHistoryTable ? 'table-height-history' : ''
            // }
            width={`${width}%`}
          >
            <CustomDataGrid
              rows={rowsData || []}
              getRowHeight={() => 'auto'}
              hideFooter
              // TODO
              // getRowHeight={() => 40}
              // pageSize={10}
              // pagination
              // autoHeight={isTableHeight ? false : isHistoryTable ? false : true}
              // rowHeight={36}
              loading={props.isLoading}
              headerHeight={33}
              columns={columnsData}
              onCellClick={(e: any) => {
                if (e.field !== 'actions') {
                  const isRedeirction = props.tableData?.detailsRedirection;
                  if (isRedeirction && props.tableData?.detailsRedirection !== 'noRedirection') {
                    navigate(`${props.tableData?.detailsRedirection}/${e?.id}`);
                  }
                }
              }}
              // getRowClassName={(params) =>
              //   params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              // }
              getCellClassName={(params) => {
                return [
                  'Name',
                  'Line Name',
                  'Department Name',
                  'Supplier Name',
                  'Part Name',
                  'Assigned to',
                  'Created by',
                ].includes(params.colDef.headerName || '')
                  ? 'blue-font'
                  : 'regular';
              }}
              components={{
                Toolbar: CustomToolbar,
                // OpenFilterButtonIcon: OpenFilterButtonIcon,
                ColumnSelectorIcon: CustomizeColumn,
              }}
              componentsProps={{
                panel: {
                  sx: {
                    top: '-30px !important',
                    left: '810px !important',
                  },
                },
              }}
            />
            {!isHideOptions && (
              <PaginationWrapper className='pagination-wrapper'>
                <Pagination
                  page={page}
                  color='primary'
                  count={props?.totalPages || paginationData?.totalPages}
                  variant='outlined'
                  onChange={(e, page) => {
                    setPage(page);
                  }}
                />
              </PaginationWrapper>
            )}
          </Box>
        </>
      )}
    </React.Fragment>
  );
};
export default TableComponent;
