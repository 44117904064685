import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';
import { useAddMobileUser, useUpdateMobileUser } from '../hooks/workerManagementHooks';
import ErrorPopup from './ErrorPopup';
import './../assets/css/modal.css';
import { Grid } from '@mui/material';
import CustomSubTitle from '../pages/commonComponents/CustomSubTitle';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
};

const CustomTypography = styled(Typography)(() => ({
  color: 'gray',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

// New Style using ClassName

const HeadBoxWrapper = styled(Box)(() => ({
  '.email-text': {
    color: '#265AA8',
    fontSize: '14px',
    padding: '24px 0px 0px 24px',
  },
  '.note-title': {
    fontSize: '12px',
    padding: '24px 0px 0px 24px',
    color: '#014686',
    marginTop: '8px',
  },
  '.note-font-weight': {
    fontWeight: 700,
  },
}));
interface propI {
  isOpen: boolean;
  handleClose: () => void;
  userValues: any;
}
const UserMobile = (props: propI) => {
  const { isOpen, handleClose, userValues } = props;

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const { id } = useParams();
  const {
    mutate: addUser,
    isError: isAddMobileError,
    error: addMobileError,
    isLoading,
  } = useAddMobileUser();
  const {
    mutate: updateUser,
    isError: isUpdateMobileError,
    error: updateMobileError,
  } = useUpdateMobileUser();

  useEffect(() => {
    if (isAddMobileError || isUpdateMobileError) {
      isAddMobileError
        ? setErrorMessage(addMobileError?.data?.message)
        : setErrorMessage(updateMobileError?.data?.message);
      setIsErrorModalOpen(true);
      // handleClose();
    }
  }, [isAddMobileError, isUpdateMobileError]);
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby=''
      aria-describedby='modal-modal-description'
    >
      <HeadBoxWrapper sx={style}>
        <Box>
          <Box className='title-head-box'>
            <Typography className='top-up-name'>Please confirm the user details</Typography>
            <CloseIcon onClick={handleClose} className='close-icon' />
          </Box>
          <Box className='modal-body'>
            <Box className='section-one'>
              <CustomSubTitle>User details</CustomSubTitle>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <CustomTypography>User&#39;s Name </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(userValues, 'name', '-')}
                  </Typography>
                  <CustomTypography>Department </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(userValues, 'departmentName', '-')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {' '}
                  <CustomTypography>User&#39;s ID </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(userValues, 'uniqId', '-')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box className='section-one'>
              <CustomSubTitle>Email ID</CustomSubTitle>
              <CustomTypography>Email ID for login </CustomTypography>
              <Typography className='span-color pl-sub-text'>
                {_.get(userValues, 'email', '-')}
              </Typography>
            </Box>
          </Box>
          <Box className='note-title'>
            <span className='note-font-weight'>Note:</span>{' '}
            <span> Email will be sent to the user with the login credentials.</span>
          </Box>
          {/* <Typography className='email-text'>
            Email will be sent to the user with the login credentials.
          </Typography> */}
        </Box>
        <Box>
          <Box className='sub-box'>
            <CustomModalButton
              variant='outlined'
              className='edit-btn'
              onClick={() => {
                handleClose();
                //navigate('/edit-mobile-user/userId');
              }}
            >
              Edit Details
            </CustomModalButton>
            <CustomModalButton
              disabled={isLoading}
              variant='contained'
              type='submit'
              className='blue-bg ml-20'
              onClick={async () => {
                const updatedUser = {
                  department: userValues?.department,
                  email: userValues?.email,
                  mobile: userValues?.mobile,
                  name: userValues?.name,
                  uniqId: userValues?.uniqId,
                };
                id ? updateUser({ ...updatedUser, id }) : addUser({ ...updatedUser });
              }}
            >
              Confirm & Save
            </CustomModalButton>
          </Box>
        </Box>
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
      </HeadBoxWrapper>
    </Modal>
  );
};
export default UserMobile;
