import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deletedItemsApi } from '../api/DeleteItems';
import { deletedItems } from '../queryKeys/deletedItems';
import { useNavigate } from 'react-router-dom';

export const useGetDeletedItems = (isEnable = true) => {
  return useQuery([deletedItems.DELETEDITEMS], deletedItemsApi.getDeletedItems, {
    select: (data) => data.data?.deletedItems,
    retry: 0,
    enabled: isEnable,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    //   //toast.success(data?.message);
    // },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};

export const useDeleteAllDeletedItems = () => {
  const navigate = useNavigate();
  return useMutation(deletedItemsApi.deleteAllItems, {
    onSuccess: () => {
      // const data = res.data;
      //toast.success(data.message);
      navigate('/deleted-items');
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useRestoreAllDeletedItems = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(deletedItemsApi.restoreAllItems, {
    onSuccess: () => {
      // const data = res.data;
      //toast.success(data.message);
      queryClient.invalidateQueries([deletedItems.DELETEDITEMS]);
      queryClient.removeQueries([deletedItems.DELETEDITEMS]);
      queryClient.refetchQueries([deletedItems.DELETEDITEMS]);
      navigate(`/deleted-items`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};
export const useRestoreSelectedDeletedItems = () => {
  const navigate = useNavigate();

  return useMutation(deletedItemsApi.restoreSelectedItems, {
    onSuccess: () => {
      // const data = res.data;
      //toast.success(data.message);
      navigate(`/deleted-items`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};
