import { useQuery } from '@tanstack/react-query';
import { dashboard } from '../queryKeys/dashboard';
import { dashboardApi } from '../api/dashboard';

export const useGetDashboard = (isEnable = true) => {
  return useQuery([dashboard.DASHBOARD], dashboardApi.getDashboardData, {
    select: (data) => data.data?.dashboardAllList,
    retry: 0,
    enabled: isEnable,
    // onSuccess: (res: any) => {
    //   // const data = res?.data;
    //   //toast.success(data?.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};
export const useGetDashboardCharts = (isEnable = true, dates = {}) => {
  return useQuery([dashboard.DASHBOARD + 'CHARTS', dates], dashboardApi.getDashboardCharts, {
    select: (data) => data.data,
    retry: 0,
    enabled: isEnable,
    // onSuccess: (res: any) => {
    //   // const data = res?.data;
    //   //toast.success(data?.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useGetDashboardGanttCharts = (isEnable = true, dates = {}) => {
  return useQuery(
    [dashboard.DASHBOARD + 'GANTTCHARTS', dates],
    dashboardApi.getDashboardGanttCharts,
    {
      select: (data) => data.data,
      retry: 0,
      enabled: isEnable,
      // onSuccess: (res: any) => {
      //   // const data = res?.data;
      //   //toast.success(data?.message);
      // },
      // onError: (err: any) => {
      //   const errData = err?.data;
      //   //toast.error(errData.message);
      // },
      refetchOnWindowFocus: false,
    }
  );
};
export const useGetDashboardGanttChartsHC = (isEnable = true, dates = {}) => {
  return useQuery(
    [dashboard.DASHBOARD + 'GANTTCHARTSHC', dates],
    dashboardApi.getDashboardGanttChartsHC,
    {
      select: (data) => data.data,
      retry: 0,
      enabled: isEnable,
      // onSuccess: (res: any) => {
      //   // const data = res?.data;
      //   //toast.success(data?.message);
      // },
      // onError: (err: any) => {
      //   const errData = err?.data;
      //   //toast.error(errData.message);
      // },
      refetchOnWindowFocus: false,
    }
  );
};
