const part = {
  PART: 'PART',
  PARTS: 'PARTS',
  PARTSFULL: 'PARTSFULL',
};

const rawMaterial = {
  RAWMATERIAL: 'RAWMATERIAL',
  RAWMATERIALS: 'RAWMATERIALS',
};

const product = {
  PRODUCT: 'PRODUCT',
  PRODUCTS: 'PRODUCTS',
  PRODUCTSFULL: 'PRODUCTSFULL',
};

const supplier = {
  SUPPLIER: 'SUPPLIER',
  SUPPLIERS: 'SUPPLIERS',
};

const searchOptions = {
  SEARCH: 'SEARCH',
};

export { part, rawMaterial, product, supplier, searchOptions };
