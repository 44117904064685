import React, { lazy, Suspense } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ComponentWrapper from './generalComponents/ComponentWrapper';
const AddWorker = lazy(() => import('./pages/Resources/Woker/AddWorker'));
const WorkerDetails = lazy(() => import('./pages/Resources/Woker/WorkerDetails'));
const Processes = lazy(() => import('./pages/Processes/Processes'));
const AddProcedure = lazy(() => import('./pages/Workorder/AddProcedure'));
const MachineDetails = lazy(() => import('./pages/Resources/Machine/MachineDetails'));
// const AddMachineVariant = lazy(() => import('./pages/Resources/Machine/AddVariant'));
const SelectMachine = lazy(() => import('./pages/Resources/Machine/SelectMachine'));
// import { ToastContainer } from 'react-toastify';
const AddRawMaterial = lazy(() => import('./pages/Catalogue/AddRawMaterial'));
const AddVariant = lazy(() => import('./pages/Catalogue/AddVariant'));
const PartDetails = lazy(() => import('./pages/Catalogue/PartDetails'));
const AddParts = lazy(() => import('./pages/Catalogue/AddParts'));
const Catalogue = lazy(() => import('./pages/Catalogue/Catalogue'));
const ProcessDetails = lazy(() => import('./pages/Processes/Add-Process-Variant/ProcessDetails'));

const AddProcess = lazy(() => import('./pages/Processes/AddProcess'));
const SelectProcess = lazy(() => import('./pages/Processes/Add-Process-Variant/SelectProcess'));

const VariantProcessDetails = lazy(
  () => import('./pages/Processes/Add-Process-Variant/AddVariantProcessDetails')
);

const DuplicateMachineWrapper = lazy(
  () => import('./pages/Resources/Machine/DuplicateMachineWrapper')
);
import ChecklistDetailsView from './pages/Checklists/ChecklistDetailsView';
// import AddProduct from './pages/Catalogue';
import AddMobileUser from './pages/UserManagement/AddMobileUser';
import AddDesktopUser from './pages/UserManagement/AddDesktopUser';
import UserDetails from './pages/UserManagement/UserDetails';
// import Login from './pages/Login/Login';
import RawMaterialsDetails from './pages/Catalogue/RawMaterialsDetails';
import AddProduct from './pages/Catalogue/AddProduct';
import UserLogin from './pages/Login/UserLogin';
import AddRole from './pages/UserManagement/AddRole';
import RoleDetails from './pages/UserManagement/RoleDetails';
import SetupPassword from './pages/Login/SetupPassword';
// import FactorySchedule from './pages/factoryManagement/FactorySchedule';
import AddLine from './pages/FactoryLayout/AddLine';
import FactoryScheduleDetails from './pages/factoryManagement/FactoryScheduleDetails';
// import AddSetupIssue from './pages/FactoryLayout/AddSetupIssue';
import AddDepartment from './pages/FactoryLayout/AddDepartment';
import LineDetails from './pages/FactoryLayout/LineDetails';
import DepartmentDetails from './pages/FactoryLayout/DepartmentDetails';
import AddPartModal from './Modals/AddPartModal';
import AddTicket from './pages/tickets/AddTickets';
import MyTickets from './pages/tickets/MyTickets';
// import PieChartData from './pages/Dashboard/PieChart';
// import CircularBar from './pages/Dashboard/CircularBar';
import UserMobileDetails from './pages/UserManagement/UserMobileDeatils';
import AddWorkRequest from './pages/Workorder/AddWorkRequest';
import DeletedItems from './pages/FactoryLayout/DeletedItems';
import AddProductModal from './Modals/AddProductModal';
import RecentActivity from './pages/Dashboard/RecentActivity';
import WorkerCompletion from './pages/Resources/Woker/WorkerCompletion';
import DuplicateProcessWrapper from './pages/Processes/Add-Process-Variant/DuplicateProcessWrapper';
import Dashboard_New from './pages/Dashboard/Dashboard_New';
import BarChartData from './pages/Dashboard/BarChart';
import Checklists from './pages/Checklists/Checklists';
import ForgotPassword from './pages/Login/ForgotPassword';
import DuplicatePartWrapper from './pages/Catalogue/DuplicatePartWrapper';
import SelectPart from './pages/Catalogue/SelectPart';
//import SelectProcess from './pages/Processes/Add-Process-Variant/SelectProcess';
const AddChecklist = lazy(() => import('./pages/Checklists/AddChecklist'));
// const Inventory = lazy(() => import('./pages/Inventory/Inventory'));
const WorkOrder = lazy(() => import('./pages/Workorder/WorkOrder'));
const ManufacturingOrder = lazy(() => import('./pages/Workorder/ManufacturingOrder'));
const CompletionReport = lazy(() => import('./pages/Workorder/CompletionReport'));
const AddSupplier = lazy(() => import('./pages/Catalogue/AddSupplier'));
const UserManagement = lazy(() => import('./pages/UserManagement/UserManagement'));
const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const MaintenanceManagerDashboard = lazy(
  () => import('./pages/Dashboard/MaintenanceManagerDashboard')
);
const FactoryOwnerDashboard = lazy(() => import('./pages/Dashboard/FactoryOwnerDashboard'));
const Resources = lazy(() => import('./pages/Resources/Resources'));
const AddMachine = lazy(() => import('./pages/Resources/Machine/AddMachine'));
const FactoryLayout = lazy(() => import('./pages/FactoryLayout/FactoryLayout'));
const IssueMapping = lazy(() => import('./pages/FactoryLayout/IssueMapping'));
const FactorySchedule = lazy(() => import('./pages/factoryManagement/FactorySchedule'));

function App() {
  return (
    <Router>
      <Suspense fallback={<h1>Loading...</h1>}>
        <Routes>
          {/* Authentication Modules */}
          {/* <Route path='/login' element={<Login />} /> */}
          <Route path='/login' element={<UserLogin />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/set-password' element={<SetupPassword />} />
          <Route path='/register' element={<h1>This is registration page</h1>} />
          {/* All Protected Routes Starts From Here */}
          <Route path='/' element={<ComponentWrapper />}>
            {/* Dashboard Module */}
            <Route index path='dashboard-new' element={<Dashboard />} />
            <Route index path='dashboard' element={<Dashboard_New />} />
            <Route index path='recent-activity' element={<RecentActivity />} />
            <Route
              index
              path='maintenance-manager/dashboard'
              element={<MaintenanceManagerDashboard />}
            />
            {/* Todo - implement circular bar for dashboard */}
            {/* <Route index path='test-pie-chart' element={<PieChartData />} />
            <Route index path='test-circular-chart' element={<CircularBar />} /> */}
            <Route index path='factory-owner/dashboard' element={<FactoryOwnerDashboard />} />
            {/* TODO:- This module is remaining */}
            {/* Inventory Module */}
            {/* <Route index path='inventory' element={<Inventory />} /> */}
            {/* Resources Module */}
            <Route path='resources' element={<Resources />} />
            {/* Machine Module */}
            <Route path='add-machine' element={<AddMachine />} />
            <Route path='edit-machine/:id' element={<AddMachine />} />
            <Route path='machine-details/:id' element={<MachineDetails />} />
            <Route path='machine/add-variant' element={<DuplicateMachineWrapper />}>
              {/* <Route index element={<SelectMachine />} /> */}
              {/* <Route path='add/:id' element={<AddMachineVariant isBreadCrumbShow={false} />} /> */}
              <Route path='add/:id' element={<AddMachine />} />
              <Route path='select-machine' element={<SelectMachine />} />
            </Route>
            {/* <Route path='machine/add-variant' element={<AddMachineVariant />} /> */}

            {/* Worker Module */}
            <Route path='worker-completion' element={<WorkerCompletion />} />
            <Route path='edit-worker-completion/:id' element={<WorkerCompletion />} />
            {/* <Route path='add-worker' element={<AddWorker />} />
            <Route path='edit-worker/:id' element={<AddWorker />} /> */}
            <Route path='worker-detail/:id' element={<WorkerDetails />} />
            <Route path='add-steps-test' element={<AddProcedure />} />

            {/* Work Order Module */}
            <Route path='work-order' element={<WorkOrder />} />
            <Route path='work-order/add-procedure/:id' element={<AddProcedure />} />
            <Route path='work-order/edit-procedure/:id' element={<AddProcedure />} />
            <Route path='work-order/view-procedure/:id' element={<AddProcedure />} />
            {/* <Route path='work-order' element={<Box> Coming Soon... </Box>} /> */}
            <Route path='add-manufacturing-order' element={<ManufacturingOrder />} />
            <Route path='edit-manufacturing-order/:id' element={<ManufacturingOrder />} />
            {/* Work Request Module */}
            <Route path='add-work-request' element={<AddWorkRequest />} />
            <Route path='edit-work-request/:id' element={<AddWorkRequest />} />
            <Route path='completion-report/:id' element={<CompletionReport />} />

            {/* Process Module */}
            <Route path='processes' element={<Processes />} />
            <Route path='add-process' element={<AddProcess />} />
            <Route path='edit-process/:id' element={<AddProcess />} />
            <Route path='process/add-variant' element={<DuplicateProcessWrapper />}>
              <Route path='select-process' element={<SelectProcess />} />
              <Route path='add/:id' element={<AddProcess />} />
            </Route>
            {/* <Route path='add-process-variant' element={<SelectProcess />} />
              <Route path='variant-details/:id' element={<VariantProcessDetails />} /> */}
            <Route path='process-details/:id' element={<ProcessDetails />} />
            {/* Checklists Module */}
            <Route path='checklists' element={<Checklists />} />
            <Route path='add-checklist' element={<AddChecklist />} />
            <Route path='edit-checklist/:id' element={<AddChecklist />} />
            <Route path='checklist-details-view/:id' element={<ChecklistDetailsView />} />
            {/* Catalogue Module */}
            <Route path='catalogue' element={<Catalogue />} />
            {/* Part Module */}
            <Route path='add-part' element={<AddParts />} />
            <Route path='edit-part/:id' element={<AddParts />} />
            <Route path='part-details/:id' element={<PartDetails />} />
            <Route path='part/add-variant' element={<DuplicatePartWrapper />}>
              <Route path='select-part' element={<SelectPart />} />
              <Route path='add/:id' element={<AddParts />} />
            </Route>
            <Route path='add-variant/:id' element={<AddVariant />} />
            {/* Add New Part Modal */}
            {/* <Route path='add-part-modal' element={<AddPartModal />} /> */}
            {/* <Route path='add-product-modal' element={<AddProductModal />} /> */}

            {/* Raw material Module */}
            <Route path='add-raw-material' element={<AddRawMaterial />} />
            <Route path='edit-raw-material/:id' element={<AddRawMaterial />} />
            <Route path='raw-material-details/:id' element={<RawMaterialsDetails />} />
            {/* Product Module */}
            <Route path='add-product' element={<AddProduct />} />
            <Route path='edit-product/:id' element={<AddProduct />} />
            {/* Supplier Module */}
            <Route path='add-supplier' element={<AddSupplier />} />
            <Route path='edit-supplier/:id' element={<AddSupplier />} />
            {/* User Management */}
            <Route path='user-management' element={<UserManagement />} />
            {/* Mobile User Module */}
            <Route path='add-mobile-user' element={<AddMobileUser />} />
            <Route path='edit-mobile-user/:id' element={<AddMobileUser />} />
            {/* Desktop User Module */}
            <Route path='add-desktop-user' element={<AddDesktopUser />} />
            <Route path='edit-desktop-user/:id' element={<AddDesktopUser />} />
            <Route path='desktop-user-details/:id' element={<UserDetails />} />
            <Route path='mobile-user-details/:id' element={<UserMobileDetails />} />
            <Route path='add-role' element={<AddRole />} />
            <Route path='edit-role/:id' element={<AddRole />} />
            <Route path='role-details/:id' element={<RoleDetails />} />
            {/* Ticket */}
            <Route path='my-tickets' element={<MyTickets />} />
            <Route path='add-ticket' element={<AddTicket />} />
            <Route path='edit-ticket/:id' element={<AddTicket />} />
            {/* Factory working */}
            <Route path='factory-schedule' element={<FactorySchedule />} />
            <Route path='factory-schedule-details/:id' element={<FactoryScheduleDetails />} />
            {/* Factory Layout Module */}
            <Route path='factory-layout' element={<FactoryLayout />} />
            <Route path='issue-mapping' element={<IssueMapping />} />
            <Route path='issue-mapping/:id' element={<IssueMapping />} />
            {/* Deleted Items */}
            <Route path='deleted-items' element={<DeletedItems />} />
            <Route path='deleted-items/machine-details/:id' element={<MachineDetails />} />
            <Route path='deleted-items/worker-detail/:id' element={<WorkerDetails />} />
            <Route path='deleted-items/process-details/:id' element={<ProcessDetails />} />
            <Route path='deleted-items/part-details/:id' element={<PartDetails />} />
            <Route path='deleted-items/desktop-user-details/:id' element={<UserDetails />} />
            <Route path='deleted-items/edit-product/:id' element={<AddProduct />} />
            <Route path='deleted-items/mobile-user-details/:id' element={<UserMobileDetails />} />
            <Route path='deleted-items/completion-report/:id' element={<CompletionReport />} />
            <Route path='deleted-items/role-details/:id' element={<RoleDetails />} />
            <Route path='deleted-items/line-details/:id' element={<LineDetails />} />
            <Route path='deleted-items/department-details/:id' element={<DepartmentDetails />} />
            <Route path='deleted-items/role-details/:id' element={<RoleDetails />} />
            <Route path='deleted-items/desktop-user-details/:id' element={<UserDetails />} />
            <Route path='deleted-items/mobile-user-details/:id' element={<UserMobileDetails />} />
            <Route
              path='deleted-items/raw-material-details/:id'
              element={<RawMaterialsDetails />}
            />
            {/* Line Module */}
            <Route path='/add-line' element={<AddLine />} />
            <Route path='/edit-line/:id' element={<AddLine />} />
            <Route path='edit-factory-schedule/:id' element={<FactorySchedule />} />
            <Route path='/line-details/:id' element={<LineDetails />} />
            {/* Department Module */}
            <Route path='/add-department' element={<AddDepartment />} />
            <Route path='/edit-department/:id' element={<AddDepartment />} />
            <Route path='/department-details/:id' element={<DepartmentDetails />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
