import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box } from '@mui/material';

const BarChartSingleData = ({
  isEditBtn = false,
  color,
  name,
  count,
  isPercent = false,
  chartDataName,
}: any) => {
  const maxValue = Math?.max(...(count || []));
  // const theme = useTheme();
  // const isMatches = useMediaQuery(theme.breakpoints.only('lg'));
  // const height = isEditBtn ? (isMatches ? '80%' : '68%') : isMatches ? '85%' : '65%';
  const options = {
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    chart: {
      type: 'column',
      height: '370px',
    },
    title: {
      // text: '<span style="font-size:21px; color: #265AA8; font-weight: 600" className="page-heading">Column chart</span>',
      text: null,
      align: 'left',
      fontSize: '34px',
    },
    // subtitle: {
    //   text: 'Source: WorldClimate.com',
    // },
    xAxis: {
      // categories: ['Product 1', 'Product 2', 'Product 3', 'Product 4', 'Product 5'],
      categories: name,
      labels: {
        style: {
          color: '#000',
        },
      },
    },
    yAxis: {
      gridLineColor: '#E2E2E2',
      lineWidth: 1,
      min: 0,
      // max: isPercent ? 100 : 500,
      max: isPercent ? 100 : Math.min(maxValue),
      title: {
        text: chartDataName,
        rotation: 0,
        offset: 0,
        margin: 0,
        y: 185,
        style: {
          color: '#7D7D7D',
          fontSize: 10,
          fontWeight: 700,
          width: 20,
        },
      },
      labels: {
        formatter: function (this: any) {
          // return isPercent ? this.value + '%' : '';
          return isPercent ? this.value + '%' : this.value;
        },
        style: {
          color: '#000',
        },
      },
    },
    plotOptions: {
      column: {
        // pointPadding: 0.2,
        // borderWidth: 0,
        stacking: 'normal',
      },
      series: {
        borderRadius: 8,
        // borderRadiusTopLeft: '5%',
        // borderRadiusTopRight: '5%',
      },
    },
    series: [
      {
        showInLegend: false,
        color: color,
        //name: 'Tokyo',
        // data: [49.9, 71.5, 106.4, 129.2, 144.0],
        data: count,
      },
    ],
  };

  return (
    <React.Fragment>
      <Box>
        <Box>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </Box>
        <Box display={'flex'} justifyContent={'center'}>
          {/* {isEditBtn && (
            <Button startIcon={<EditIconWithBox />} variant='outlined'>
              Edit Products
            </Button>
          )} */}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default BarChartSingleData;
