import React from 'react';

const EditIconWithBox = () => {
  return (
    <svg
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ cursor: 'pointer' }}
    >
      <path
        d='M7.16671 3.16765L3.00004 3.16765C2.07957 3.16765 1.33337 3.91384 1.33337 4.83431L1.33337 14.001C1.33337 14.9215 2.07957 15.6676 3.00004 15.6676L12.1667 15.6676C13.0872 15.6676 13.8334 14.9215 13.8334 14.001V9.83431M12.6549 1.98913C13.3057 1.33826 14.361 1.33826 15.0119 1.98913C15.6628 2.64001 15.6628 3.69528 15.0119 4.34615L7.85706 11.501L5.50004 11.501L5.50004 9.14395L12.6549 1.98913Z'
        stroke='#122E54'
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default EditIconWithBox;
