import { useMutation, useQueryClient, useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { ticketApi } from '../api/ticket';
import { ticket } from '../queryKeys/ticket';
import { ticketErrI } from '../Types/ticket';

export const useAddTicket = (activeTab = '1') => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(ticketApi.addTicket, {
    onSuccess: () => {
      queryClient.invalidateQueries([ticket.TICKET]);
      //toast.success(data?.message);
      navigate(`/my-tickets`, { state: { activeTab } });
    },
    onError: (err: ticketErrI) => {
      return err;
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useGetTickets = (isEnable = true, PagePath) => {
  return useQuery([ticket.TICKETS, PagePath], ticketApi.getTickets, {
    initialData: { tickets: { results: [] } },
    select: (data) => data?.tickets,
    enabled: isEnable,
    // onSuccess: (res: any) => {
    //   const data = res;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: true,
    // refetchInterval: true,
  });
};

export const useGetTicket = (id, isEnable = true) => {
  return useQuery([ticket.TICKET, id], ticketApi.getTicket, {
    select: (data) => data,
    enabled: isEnable,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    //   //toast.success(data?.message);
    // },
    // onError: (err: ticketErrI) => {
    //   return err;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
};

export const useUpdateTicket = (activeTab = '1', isImageUploaded = false) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(ticketApi.updateTicket, {
    onSuccess: (res) => {
      const data = res;
      queryClient.invalidateQueries([ticket.TICKETS]);
      queryClient.invalidateQueries([ticket.TICKET, data?.ticket?.id]);
      if (isImageUploaded) {
        queryClient.invalidateQueries([ticket.TICKETS, '']);
      } else {
        navigate(`/my-tickets`, { state: { activeTab } });
      }
    },
    onError: (err: any) => {
      return err;
      // const errData = err?.data;
    },
  });
};
export const useTicketResolved = () => {
  const queryClient = useQueryClient();
  return useMutation(ticketApi.ticketResolved, {
    onSuccess: (res, id) => {
      queryClient.invalidateQueries([ticket.TICKETS]);
      queryClient.invalidateQueries([ticket.TICKET, id]);
      //toast.success(data?.message);
    },
    onError: (err) => {
      return err;
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useDeleteTicket = (activeTab = '1') => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(ticketApi.deleteTicket, {
    onSuccess: (res) => {
      const data = res.data;
      queryClient.invalidateQueries([ticket.TICKETS]);
      queryClient.removeQueries([ticket.TICKET, data?.ticket?.id]);
      //toast.success(data.message);
      navigate(`/my-tickets`, { state: { activeTab } });
    },
    // onError: (err: any) => {
    //   return err;
    //   //toast.error(errData.message);
    // },
  });
};

export const useGetComments = (id) => {
  return useQuery([ticket.TICKETCOMMENTS, id], ticketApi.getComments, {
    select: (data) => data?.data?.comments?.results?.reverse(),
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: true,
    // refetchInterval: true,
  });
};

export const useGetCommentsInfinite = (id) => {
  return useInfiniteQuery([ticket.TICKETCOMMENTS, id, 'TEST'], ticketApi.getComments, {
    // enabled: isEnabled,
    select: (data: any) => data?.pages,
    getNextPageParam: (lastPage, pages) => {
      const maxPage = lastPage?.data?.comments?.totalPages;
      if (maxPage >= pages?.length + 1) {
        return pages?.length + 1;
      } else {
        return undefined;
      }
    },
    onSuccess: (res: any) => {
      const data = res?.data;
    },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useAddComment = () => {
  const queryClient = useQueryClient();
  return useMutation(ticketApi.addComments, {
    onSuccess: () => {
      queryClient.invalidateQueries([ticket.TICKETCOMMENTS]);
      //toast.success(data?.message);
      // navigate(`/my-tickets`);
    },
    onError: (err: ticketErrI) => {
      return err;
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};
