import { deleteRequest, get, patch, post } from '../Utils/index';
export const addWorkorder = async (data) => {
  return post(`/customer/work-order`, data);
};
export const getWorkordersCurrent = async (data) => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10, type: 'current' };
  return get(`/customer/work-order`, payload);
};

export const getWorkordersCompleted = async (data) => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10, type: 'completed' };
  return get(`/customer/work-order`, payload);
};

export const getWorkordersDraft = async (data) => {
  const page = data?.queryKey[1] || 1;
  const payload: any = { page, limit: 10, type: 'draft' };
  return get(`/customer/work-order`, payload);
};
export const getFullWorkorders = async () => {
  return get(`/customer/work-order/full-list`);
};
export const getWorkorder = async (data) => {
  const id = data.queryKey[1];
  return get(`/customer/work-order/${id}`);
};
export const getDeletedWorkorder = async (data) => {
  const id = data.queryKey[1];
  return get(`/customer/work-order/deleted/${id}`);
};
export const updateWorkorder = async (data) => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/work-order/${id}`, data);
};
export const updateScheduleWorkorder = async (data) => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/work-order/schedule-chart/${id}`, data);
};
export const cancelWorkorder = async (id) => {
  return patch(`/customer/work-order/cancel/${id}`);
};
export const deleteWorkorder = async (id) => {
  return deleteRequest(`/customer/work-order/${id}`);
};
export const restoreWorkorder = async (id) => {
  // const id = data?.queryKey[1];
  // const id = data?.id;
  // delete data['id'];
  return patch(`/customer/work-order/restore/${id}`);
};
export const hardDeleteWorkorder = async (id) => {
  // const id = data?.queryKey[1];
  // const id = data?.id;
  // delete data['id'];
  return deleteRequest(`/customer/work-order/hard-delete/${id}`);
};

export const workorderApi = {
  addWorkorder,
  getWorkordersCurrent,
  getWorkordersCompleted,
  getWorkordersDraft,
  getFullWorkorders,
  getWorkorder,
  updateWorkorder,
  cancelWorkorder,
  deleteWorkorder,
  restoreWorkorder,
  hardDeleteWorkorder,
  getDeletedWorkorder,
  updateScheduleWorkorder,
};
