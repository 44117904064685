import { Box, styled } from '@mui/material';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DeleteIcon from '../../assets/svg/DeleteIcon';
import EditIcon from '../../assets/svg/EditIcon';
import DeleteWarningModal from '../../Modals/DeleteWarningModal';
import CustomTable from '../commonComponents/Table';
import moment from 'moment';
import { useUpdateNotification } from '../../hooks/notificationHooks';
import { useDeleteChecklist, useGetChecklists } from '../../hooks/checklistHooks';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';

const Checklists = () => {
  //Delete Modal
  const [deleteRecord, setDeleteRecord] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const { state } = useLocation();
  const { mutate: readNotification } = useUpdateNotification();
  const handleDeleteOpen = () => setDeleteRecord(true);
  const handleDeleteClose = () => setDeleteRecord(false);

  const [page, setPage] = useState(1);
  const {
    data: getAllChecklistInfo,
    isLoading: isChecklistLoading,
    isFetching: isChecklistFetching,
    isSuccess: isChecklistSuccess,
  } = useGetChecklists(true, page);

  const getAllChecklist = getAllChecklistInfo?.results;

  const getChecklist = getAllChecklist;
  const navigate = useNavigate();
  const { mutate: deleteSingleChecklist } = useDeleteChecklist();
  const BtnChecklistLink = {
    title: 'Create New Checklist',
    linkData: [
      {
        menuItem: 'Add New Checklist',
        to: '/add-checklist',
      },
    ],
  };

  const processCols = [
    {
      field: 'srNo',
      headerName: 'Sr. No',
      flex: 0.4,
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 0.7,
      renderCell: (params) => {
        return (
          <Box className='table-icons'>
            <Link to={`/edit-checklist/${params.row.id}`}>{params.row.title}</Link>
          </Box>
        );
      },
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Date Created',
      flex: 1,
    },
    {
      field: 'updatedOn',
      headerName: 'Update on',
      flex: 0.9,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      // width: 200,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Box className='table-icons'>
            <Box pr={'25px'} onClick={() => navigate(`/edit-checklist/${params.row.id}`)}>
              <EditIcon />
            </Box>

            <Box
              onClick={() => {
                setCurrentId(params.row.id);
                handleDeleteOpen();
              }}
            >
              <DeleteIcon />
            </Box>
          </Box>
        );
      },
    },
  ];

  const processRows = getChecklist?.map((data, ind) => {
    const startIndex = (page - 1) * 10;
    return {
      id: data.id,
      srNo: startIndex + ind + 1,
      title: data?.title,
      createdBy: data?.createBy?.name,
      createdAt: data?.createdAt ? moment(data?.createdAt)?.format('hh:MM a, DD MMM, YYYY') : '-',
      updatedOn: data?.updatedAt ? moment(data?.updatedAt).format('hh:MM a, DD MMM, YYYY') : '-',
      action: '',
    };
  });

  const tableData = {
    columns: processCols || [],
    rows: processRows || [],
    detailsRedirection: '/checklist-details-view',
  };
  const BoxWrapper = styled(Box)(() => ({
    color: '#000',
    '.page-head': {
      fontSize: '34px !important',
      color: '#265AA8',
      lineHeight: 'none',
      fontWeight: '700 !important',
      '.css-1wczmbf-MuiTypography-root': {
        lineHeight: '0',
      },
    },
  }));

  React.useEffect(() => {
    if (state?.isRead) {
      readNotification(state?.id);
      state.isRead = false;
    }
  }, []);

  return (
    <React.Fragment>
      <BreadcrumbNavigation breadCrumbData={null} pageTitle={`Checklists`} backToLink={null} />
      <BoxWrapper className='main-padding'>
        <CustomTable
          data={BtnChecklistLink}
          isHideDropDown={true}
          isOptionShow={true}
          tableData={tableData}
          isLoading={isChecklistLoading || isChecklistFetching}
          page={page}
          setPage={setPage}
          paginationData={getAllChecklistInfo}
          // listName={'Checklists'}
          isNoEntityShow={tableData?.rows?.length <= 0 && isChecklistSuccess}
          isSubscription={true}
          moduleName={'Checklist'}
          redirectPage={'/add-checklist'}
        />

        {deleteRecord && currentId && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
            handleDelete={deleteSingleChecklist}
            id={currentId}
            // params={params.row.id}
          />
        )}
      </BoxWrapper>
    </React.Fragment>
  );
};
export default Checklists;
