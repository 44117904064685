import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import GreaterThanIcon from '../../assets/svg/GreaterThanIcon';
import CheckboxIcon from '../../assets/svg/CheckboxIcon';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

const CardWrapper = styled(Card)(() => ({
  boxShadow: '0px 2px 10px rgba(40, 40, 40, 0.1)',
  borderRadius: '15px',
  // padding: '14px 16px 12px 14px',
  padding: '14px 10px 11px 8px',
  cursor: 'pointer',
  // marginBottom: '10px',
  margin: '0px 5px 10px 4px',
  '.MuiCardContent-root': {
    padding: '0px',
  },
  '.MuiCardContent-root:last-child': {
    paddingBottom: '0px',
  },
  '.title': {
    color: '#1D1B1B',
    fontWeight: 600,
    fontSize: '16px',
    marginLeft: '10px',
  },
  '.value': {
    color: '#777676',
    marginLeft: '10px',
  },
  '.due-by': {
    color: '#1D1B1B',
  },
  '.mr-12': {
    marginRight: '12px',
  },
  '.inner-content': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const TicketCard = ({ cardData }: any) => {
  const navigate = useNavigate();
  return (
    <CardWrapper onClick={() => navigate(`/edit-ticket/${cardData?.id}`)}>
      <CardContent>
        <Box className='inner-content'>
          <Box display={'flex'}>
            <Typography className='title'>
              {cardData?.description || cardData?.action || '-'}
            </Typography>
          </Box>
          <GreaterThanIcon />
        </Box>
        <Typography className='value'>{_.get(cardData?.createBy, 'name', '-')}</Typography>
      </CardContent>
    </CardWrapper>
  );
};

export default TicketCard;
