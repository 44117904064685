import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import EditIconWithBox from '../../assets/svg/EditIconWithBox';
import { Button, Grid } from '@mui/material';
import BarChartSingleData from '../Dashboard/BarChartSingleData';
import EditProductDashboard from '../../Modals/EditProductDashboard';
interface Prop {
  height: string;
  workOrderCompletionDelay: any;
  productionCount: any;
}
const ProductionDelayHighlights = (props: Prop) => {
  const { height, workOrderCompletionDelay, productionCount } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const CardWrapper = styled(Card)(() => ({
    boxShadow: '0px 2px 10px rgba(40, 40, 40, 0.1)',
    borderRadius: '15px',
    height: height,
    //marginBottom: '18px',
    //width: '427px',
    padding: '18px 16px',
    overflow: 'auto',
    '.MuiCardContent-root': {
      padding: '0px',
    },
    '.MuiCardContent-root:last-child': {
      paddingBottom: '0px',
    },
    '.heading-wrapper': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '10px',
    },
    '.head-title': {
      color: '#265AA8',
      fontWeight: 700,
      fontSize: '21px',
    },
    '.sub-head-title': {
      color: '#242424',
      fontWeight: 700,
      fontSize: '18px',
    },
    '.view-link': {
      color: '#265AA8',
      fontWeight: 600,
      fontSize: '14px',
      cursor: 'pointer',
    },
    '.circular-bar': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 30,
    },
    '.border-right': {
      borderRight: '2px dashed #E4E4E4',
    },
  }));
  const [products, setProducts] = React.useState<any>([]);
  const [filteredProducts, setFilteredProducts] = React.useState<any>([]);
  React.useEffect(() => {
    if (productionCount) {
      const filterData = productionCount?.slice(0, 5);
      setFilteredProducts(filterData);
      setProducts(productionCount);
    }
  }, [productionCount]);

  const ProductionName = filteredProducts?.map((data) => {
    return data?.name;
  });
  const productionCountNumber = filteredProducts?.map((data) => {
    return data?.productionCount;
  });
  const workOrderName = workOrderCompletionDelay?.map((data) => {
    return data?.title;
  });
  const completionPercentage = workOrderCompletionDelay?.map((data) => {
    // Old Functionality
    // return data?.completionPercentage > 100 ? 100 : data?.completionPercentage;
    return parseFloat(data?.completionPercentage.toFixed(2));
  });
  return (
    <CardWrapper>
      <CardContent>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} lg={6} md={12} className='border-right'>
            <Typography className='sub-head-title' mb={1}>
              Production Count
            </Typography>
            <BarChartSingleData
              color={'#D9D9D9'}
              name={ProductionName}
              count={productionCountNumber}
              chartDataName={'Production Count'}
            />
            <Box display={'flex'} justifyContent={'center'}>
              <Button variant='outlined' onClick={() => handleOpen()}>
                <EditIconWithBox /> Edit Products
              </Button>
            </Box>
            {open && (
              <EditProductDashboard
                open={open}
                handleClose={handleClose}
                products={products}
                setProducts={setProducts}
                filteredProducts={filteredProducts}
                setFilteredProducts={setFilteredProducts}
                ProductionName={ProductionName}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={6} md={12}>
            <Typography className='sub-head-title' mb={1}>
              Work-order completion Delay
            </Typography>
            <BarChartSingleData
              color={'#FF6A6A'}
              name={workOrderName}
              count={completionPercentage}
              isPercent={true}
              chartDataName={'Workorder Name'}
            />
          </Grid>
        </Grid>
      </CardContent>
    </CardWrapper>
  );
};

export default ProductionDelayHighlights;
