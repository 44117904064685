import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import PieChartData from '../Dashboard/PieChart';
import BarChartData from '../Dashboard/BarChart';
import DateRange from '../Dashboard/DateRange';
interface Prop {
  title: string;
  height: string;
  setDatesState?: any;
  dates?: any;
  setDates?: any;
  workerEfficiency?: any;
  machinesEfficiency?: any;
  dailyProduction?: any;
  setMachine?: any;
  machine?: any;
  worker?: any;
  setWorker?: any;
}
const Highlights = (props: Prop) => {
  const {
    title,
    height,
    setDatesState,
    dates,
    setDates,
    workerEfficiency,
    machinesEfficiency,
    dailyProduction,
    setMachine,
    machine,
    worker,
    setWorker,
  } = props;
  const CardWrapper = styled(Card)(() => ({
    boxShadow: '0px 2px 10px rgba(40, 40, 40, 0.1)',
    borderRadius: '15px',
    height: height,
    //marginBottom: '18px',
    //width: '427px',
    padding: '18px 16px',
    overflow: 'auto',
    '.MuiCardContent-root': {
      padding: '0px',
    },
    '.MuiCardContent-root:last-child': {
      paddingBottom: '0px',
    },
    '.heading-wrapper': {
      display: 'flex',
      // justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '10px',
    },
    '.head-title': {
      color: '#265AA8',
      fontWeight: 700,
      fontSize: '21px',
    },
    '.sub-head-title': {
      color: '#242424',
      fontWeight: 700,
      fontSize: '18px',
    },
    '.view-link': {
      color: '#265AA8',
      fontWeight: 600,
      fontSize: '14px',
      cursor: 'pointer',
    },
    '.circular-bar': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 30,
    },
    '.border-right': {
      borderRight: '2px dashed #E4E4E4',
    },
  }));
  const ProductiveWorker = workerEfficiency?.productive;
  const nonProductiveWorker = workerEfficiency?.nonProductive;
  const nonProductiveMachine = machinesEfficiency?.nonProductive;
  const productiveMachine = machinesEfficiency?.productive;
  return (
    <CardWrapper>
      <CardContent>
        <Box className='heading-wrapper'>
          <Box>
            <Typography className='head-title'>{title}</Typography>
          </Box>
          <Box mx={2}>
            <DateRange setDatesState={setDatesState} dates={dates} setDates={setDates} />
          </Box>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={3} md={6} className='border-right'>
            <Typography className='sub-head-title'>Worker efficiency</Typography>
            <PieChartData
              allValue={'All Workers'}
              productive={ProductiveWorker}
              nonProductive={nonProductiveWorker}
              worker={worker}
              setWorker={setWorker}
            />
          </Grid>
          <Grid item xs={12} lg={3} md={6} className='border-right'>
            <Typography className='sub-head-title'>Machine efficiency</Typography>
            <PieChartData
              allValue={'All Machine'}
              productive={productiveMachine}
              nonProductive={nonProductiveMachine}
              setMachine={setMachine}
              machine={machine}
            />
          </Grid>
          {/* Old Dashboard */}
          {/* <Grid item xs={12} lg={3} md={6} className='border-right'>
            <Typography className='sub-head-title'>OEE</Typography>
            <Grid container>
              <Grid item xs={6}>
                <Box className='circular-bar'>
                  <CircularBarData
                    circularText={'OEE'}
                    stroke={'#265AA8'}
                    data={81}
                    background={'#ABCDFF'}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className='circular-bar'>
                  <CircularBarData
                    circularText={'Availability'}
                    stroke={'#45C8FF'}
                    data={61}
                    background={'#C9EFFF'}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className='circular-bar'>
                  <CircularBarData
                    circularText={'Performance'}
                    stroke={'#93CE16'}
                    data={74}
                    background={'#DBFFDA'}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className='circular-bar'>
                  <CircularBarData
                    circularText={'Quality'}
                    stroke={'#F29E38'}
                    data={95}
                    background={'#FFD4A0'}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item xs={12} lg={6} md={12}>
            <Typography className='sub-head-title' mb={1}>
              Daily Production
            </Typography>
            <BarChartData dailyProduction={dailyProduction} />
            {/* <Box display={'flex'} justifyContent={'center'}>
              <Button variant='outlined'>
                <EditIconWithBox /> Edit Products
              </Button>
            </Box> */}
          </Grid>
        </Grid>
      </CardContent>
    </CardWrapper>
  );
};

export default Highlights;
