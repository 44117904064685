import React from 'react';

const GreaterThanIcon = () => {
  return (
    <svg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.5 1.16683L7.33333 7.00016L1.5 12.8335'
        stroke='#122E54'
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default GreaterThanIcon;
