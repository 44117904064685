import { get } from '../Utils';

export const getDashboardData = async () => {
  return get(`/customer/dashboard`);
};

export const getDashboardCharts = async (data) => {
  const dates = data.queryKey?.[1];
  return get(`/customer/dashboard/charts`, dates);
};
export const getDashboardGanttCharts = async (data) => {
  const dates = data.queryKey?.[1];
  return get(`/customer/dashboard/schedule-workorder`, dates);
};
export const getDashboardGanttChartsHC = async (data) => {
  const dates = data.queryKey?.[1];
  return get(`/customer/dashboard/schedule-workorder-high-charts`, dates);
};

export const dashboardApi = {
  getDashboardData,
  getDashboardCharts,
  getDashboardGanttCharts,
  getDashboardGanttChartsHC,
};
