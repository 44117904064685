import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import EditIcon from '../../assets/svg/EditIcon';
import { TextField } from '@material-ui/core';

const CardWrapper = styled(Card)(() => ({
  boxShadow: '0px 2px 10px rgba(40, 40, 40, 0.1)',
  borderRadius: '15px',
  padding: '14px 16px 12px 14px',
  marginBottom: '10px',
  '.MuiCardContent-root': {
    padding: '0px',
  },
  '.MuiCardContent-root:last-child': {
    paddingBottom: '0px',
  },
  '.title': {
    color: '#1D1B1B',
    fontWeight: 600,
    fontSize: '16px',
    // marginLeft: '10px',
  },
  '.value': {
    color: '#777676',
  },
  '.ml-35': {
    marginLeft: '35px',
  },
  '.due-by': {
    color: '#1D1B1B',
  },
  '.mr-12': {
    marginRight: '12px',
  },
  // '.inner-content': {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   alignItems: 'center',
  // },
}));

const ProfileCard = ({ field, value, setData }: any) => {
  const [edit, setEdit] = React.useState();

  return (
    <CardWrapper>
      <CardContent>
        <Box className='inner-content'>
          <Box width={'67px'}></Box>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box display={'flex'} flexDirection={'column'}>
              <Typography className='title'>{field} : </Typography>
              {edit !== field && <Typography className='value'> {value}</Typography>}
              {edit === field && (
                <TextField
                  type={field === 'D.O.B' ? 'date' : 'text'}
                  value={value}
                  onChange={(e) => setData(e.target.value)}
                >
                  {value}
                </TextField>
              )}
            </Box>
            <Box onClick={() => setEdit(field)}>
              <EditIcon />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </CardWrapper>
  );
};

export default ProfileCard;
