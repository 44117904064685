import { get, post, patch, deleteRequest, put } from '../Utils/index';

export const addFactorySchedule = async (data) => {
  return put(`/customer/factory-schedule`, data);
};
export const getFactorySchedule = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/factory-schedule`);
};
export const updateFactorySchedule = async (data) => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/factory-schedule/${id}`, data);
};
export const deleteFactorySchedule = async (id) => {
  return deleteRequest(`/customer/factory-schedule/${id}`);
};
// export const getMachines = async () => {
//   return get(`/customer/machine`);
// };
export const factoryScheduleApi = {
  addFactorySchedule,
  getFactorySchedule,
  updateFactorySchedule,
  deleteFactorySchedule,
};
