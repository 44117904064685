import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { departmentApi } from '../api/department';
import { department } from '../queryKeys/department';
import { DepartmentErrI, DepartmentListErrI } from '../Types/department';

export const useAddDepartment = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(departmentApi.addDepartment, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([department.DEPARTMENT]);
      // navigate(`/department-details/${res?.department?.id}`);
      navigate(`/factory-layout`, { state: { activeTab: '2' } });
    },
    onError: (err: DepartmentErrI) => {
      return err;
      // toast.error(errData.message);
    },
  });
};

export const useGetDepartment = (id, isEnable = true) => {
  return useQuery([department.DEPARTMENT, id], departmentApi.getSingleDepartment, {
    select: (data) => data?.department,
    enabled: isEnable,
    // onSuccess: () => {
    //   // const data = res?.data;
    // },
    // onError: () => {
    //   // const errData = err.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useGetDepartments = (isEnable, page) => {
  return useQuery([department.DEPARTMENT, page], departmentApi.getDepartments, {
    enabled: isEnable,
    select: (data) => data?.departments,
    // onSuccess: () => {
    //   // const data = res?.data;
    // },
    onError: (err: DepartmentListErrI) => {
      return err;
      // const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};
export const useGetFullDepartments = () => {
  return useQuery([department.DEPARTMENT + 'FULL'], departmentApi.getFullDepartment, {
    select: (data) => data?.data?.departments,
    // onSuccess: () => {
    //   // const data = res?.data;
    // },
    onError: (err) => {
      return err;
      // const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useDeleteDepartment = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(departmentApi.deleteDepartment, {
    onSuccess: (res, id) => {
      queryClient.invalidateQueries([department.DEPARTMENT]);
      queryClient.invalidateQueries([department.DEPARTMENT, id]);
      // toast.success(data?.message);
      navigate(`/factory-layout`, { state: { activeTab: '2' } });
    },
    onError: () => {
      // toast.error(errData?.message);
    },
  });
};

export const useRestoreDepartment = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(departmentApi.restoreDepartment, {
    onSuccess: () => {
      queryClient.invalidateQueries([department.DEPARTMENTS]);
      // queryClient.invalidateQueries([part.PART, id]);
      //toast.success(data.message);
      navigate('/deleted-items');
    },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  });
};

export const useHardDeleteDepartment = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(departmentApi.hardDeleteDepartment, {
    onSuccess: () => {
      queryClient.invalidateQueries([department.DEPARTMENTS]);
      // queryClient.invalidateQueries([part.PART, id]);
      navigate('/deleted-items');
    },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  });
};

export const useGetDeletedDepartmernt = (id, isEnable = true) => {
  return useQuery(
    [department.DEPARTMENT + 'DELETED', id],
    departmentApi.getSingleDeletedDepartment,
    {
      enabled: isEnable,
      select: (data) => data.data,
      // onSuccess: (res: any) => {
      //   const data = res?.data;
      // },
      // onError: (err: any) => {
      //   const errData = err?.data;
      //   //toast.error(errData.message);
      // },
      refetchOnWindowFocus: false,
    }
  );
};

export const useUpdateDepartment = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(departmentApi.updateDepartment, {
    onSuccess: (res, id) => {
      queryClient.invalidateQueries([department.DEPARTMENT]);
      queryClient.invalidateQueries([department.DEPARTMENT, id]);
      // toast.success(data?.message);
      // navigate(`/department-details/${res?.department?.id}`);
      navigate(`/factory-layout`, { state: { activeTab: '2' } });
    },
    onError: (err: any) => {
      return err;
      // const errData = err.data;
      // toast.error(errData?.message);
    },
  });
};
