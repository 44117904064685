import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EditIconWithBox from '../../assets/svg/EditIconWithBox';

const BarChartData = (props: any) => {
  const { dailyProduction } = props;
  const badItems = dailyProduction?.map((data) => {
    return data?.badItems;
  });
  const goodItems = dailyProduction?.map((data) => {
    return data?.goodItems;
  });
  const date = dailyProduction?.map((data) => {
    return data?._id;
  });
  const theme = useTheme();
  const isMatches = useMediaQuery(theme.breakpoints.only('lg'));
  // const height = isEditBtn ? (isMatches ? '80%' : '68%') : isMatches ? '85%' : '65%';
  const options = {
    credits: {
      enabled: false,
    },
    legend: {
      enabled: true,
    },
    accessibility: {
      enabled: false,
    },
    chart: {
      type: 'column',
      height: '370px',
    },
    title: {
      // text: '<span style="font-size:21px; color: #265AA8; font-weight: 600" className="page-heading">Column chart</span>',
      text: null,
      align: 'left',
      fontSize: '34px',
    },
    // subtitle: {
    //   text: 'Source: WorldClimate.com',
    // },
    xAxis: {
      // categories: ['Product 1', 'Product 2', 'Product 3', 'Product 4', 'Product 5'],
      categories: date,
      labels: {
        style: {
          color: '#000',
        },
      },
    },
    yAxis: {
      gridLineColor: '#E2E2E2',
      lineWidth: 1,
      min: 0,
      labels: {
        style: {
          color: '#000',
        },
      },
      title: {
        text: ' Daily Production',
        rotation: 0,
        offset: 0,
        margin: 0,
        y: 165,
        style: {
          color: '#7D7D7D',
          fontSize: 10,
          fontWeight: 700,
          width: 20,
        },
      },
    },
    plotOptions: {
      column: {
        // pointPadding: 0.2,
        // borderWidth: 0,
        stacking: 'normal',
      },
      series: {
        // borderRadius: 8,
        borderRadiusTopLeft: '50%',
        borderRadiusTopRight: '50%',
      },
    },
    series: [
      {
        // showInLegend: false,
        color: 'rgba(171, 230, 255, 1)',
        name: 'Good Parts',
        // data: [100, 100, 150, 150, 180],
        data: goodItems,
      },
      {
        // showInLegend: false,
        color: 'rgba(255, 106, 106, 1)',
        name: 'Bad Parts',
        // data: [49.9, 71.5, 106.4, 129.2, 144.0],
        data: badItems,
      },
    ],
  };

  return (
    <React.Fragment>
      <Box>
        <Box>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </Box>
        <Box display={'flex'} justifyContent={'center'}></Box>
      </Box>
    </React.Fragment>
  );
};

export default BarChartData;
