import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import GreaterThanIcon from '../../assets/svg/GreaterThanIcon';
import * as _ from 'lodash';
import moment from 'moment';

const CardWrapper = styled(Card)(() => ({
  boxShadow: '0px 2px 10px rgba(40, 40, 40, 0.1)',
  borderRadius: '15px',
  padding: '14px 16px 12px 14px',
  margin: '0px 5px 10px 4px',
  // marginBottom: '10px',
  '.MuiCardContent-root': {
    padding: '0px',
  },
  '.MuiCardContent-root:last-child': {
    paddingBottom: '0px',
  },
  '.title': {
    color: '#1D1B1B',
    fontWeight: 400,
    fontSize: '14px',
  },
  '.value': {
    color: '#777676',
    fontSize: '12px',
  },
  '.inner-content': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '.time-title': {
    color: '#CC0000',
    fontSize: '11px',
  },
}));

const TopDownTimesCard = ({ cardData }: any) => {
  return (
    <CardWrapper>
      <CardContent>
        <Box className='inner-content'>
          <Box>
            <Typography className='title'>Downtime Name</Typography>
            <Typography className='value'>{_.get(cardData, 'type', '-')}</Typography>
          </Box>
          <Box>
            <Typography className='time-title'>
              {' '}
              {moment(_.get(cardData, 'createdAt', '-')).format(`hh:mm A, D MMM YYYY`)}
            </Typography>
            <Typography className='value'>{_.get(cardData?.createBy, 'name', '-')}</Typography>
          </Box>
          <GreaterThanIcon />
        </Box>
        {/* <Box className='inner-content'>
          <Typography className='value'>{_.get(cardData?.createBy, 'name', '-')}</Typography>
          <Typography className='title'>2 hrs 30 mins</Typography>
          <Typography className='title'>
            {' '}
            {moment(_.get(cardData, 'createdAt', '-')).format(`HH:MM`)}
          </Typography>
        </Box> */}
      </CardContent>
    </CardWrapper>
  );
};

export default TopDownTimesCard;
