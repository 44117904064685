import styled from '@emotion/styled';
import { Button } from '@mui/material';

const CustomModalButton = styled(Button)(() => ({
  padding: '10px 16px 10px !important',
  borderRadius: '4px !important',
  fontSize: '14px !important',
  fontWeight: '600 !important',
  height: '42px',
  textTransform: 'capitalize',
}));

export default CustomModalButton;
