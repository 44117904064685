import { get, patch, deleteRequest } from '../Utils';

export const getDeletedItems = async () => {
  return get(`/customer/deleted-items`);
};

export const deleteAllItems = async () => {
  return deleteRequest(`/customer/deleted-items`);
};

export const restoreAllItems = async () => {
  return patch(`/customer/deleted-items`);
};
export const restoreSelectedItems = async (data) => {
  return patch(`/customer/deleted-items/selected-restore`, data);
};
export const deletedItemsApi = {
  getDeletedItems,
  deleteAllItems,
  restoreAllItems,
  restoreSelectedItems,
};
