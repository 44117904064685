// import { useState } from 'react';
import { Menu, ProSidebar, SidebarHeader, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import * as _ from 'lodash';
import { useState } from 'react';
import SidebarIcon from '../assets/svg/SidebarIcon';
import WorkOrderActive from '../assets/svg/WorkOrderActive';
import WorkOrderInActive from '../assets/svg/WorkOrderInActive';
import ResourceActive from '../assets/svg/ResourceActive';
import ResourceInActive from '../assets/svg/ResourceInActive';
import CatalogueActive from '../assets/svg/CatalogueActive';
import CatalogueInActive from '../assets/svg/CatalogueInActive';
import ProcessActiveIcon from '../assets/svg/ProcessActiveIcon';
import ProcessInActiveIcon from '../assets/svg/ProcessInActiveIcon';
import ChecklistActiveIcon from '../assets/svg/ChecklistActiveIcon';
import ChecklistInActiveIcon from '../assets/svg/ChecklistInActiveIcon';
import TicketActiveIcon from '../assets/svg/TicketActiveIcon';
import TicketInActiveIcon from '../assets/svg/TicketInActiveIcon';
import DashboardActiveIcon from '../assets/svg/DashboardActiveIcon';
import DashboardInActiveIcon from '../assets/svg/DashboardInActiveIcon';
import FactoryManagementActiveIcon from '../assets/svg/FactoryManagementActiveIcon';
import FactoryManagementInActiveIcon from '../assets/svg/FactoryManagementInActiveIcon';
import SubMenuItemIcon from '../assets/svg/SubMenuItemIcon';
import MenuActiveIconArrow from '../assets/svg/MenuActiveIconArrow';
const Sidebar = ({ isAuthenticated, user }: any) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const userRole = user;
  const SidebarWrapper = styled(Box)(() => ({
    position: 'fixed',
    width: '264px',
    top: '0',
    height: '100% !important',
    backgroundColor: '#265AA8',
    '.sidebar-header': {
      display: 'flex',
      height: 'auto',
      justifyContent: 'start',
      padding: '14.5px 0px 14.5px 20px',
    },
    '.menu-item': {
      color: '#fff ',
      fontSize: '16px !important',
      fontWeight: '400',
      lineHeight: '21px',
    },
    '.nav-link-box': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow':
      {
        borderColor: 'black !important',
        borderWidth: '0 2px 2px 0',
        padding: '4.5px !important',
        verticalAlign: 'middle',
        transition: 'transform 0.3s',
        transform: 'rotate(225deg) !important',
      },
    '.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow':
      {
        transform: 'rotate(45deg) !important',
        borderColor: isFactoryDataActive ? 'white !important' : 'black !important',
      },
    '.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul': {
      paddingTop: isFactoryDataActive ? '15px !important' : '0px !important',
    },
  }));

  function startsWithString(string, values) {
    return values.some((element) => {
      return string.startsWith(element);
    });
  }
  const location = useLocation().pathname;

  // const openInNewTab = (url: string | URL | undefined) => {
  //   window.open(url, '_blank', 'noopener,noreferrer');
  // };

  const isDashboardActive = ['/dashboard', '/recent-activity'].includes(location);

  const isWorkOrderActive =
    [
      '/work-order',
      '/add-manufacturing-order',
      '/work-order/add-procedure/',
      '/add-work-request',
    ].includes(location) ||
    startsWithString(location, [
      '/edit-manufacturing-order/',
      '/work-order/add-procedure/',
      '/work-order/edit-procedure/',
      '/work-order/view-procedure/',
      '/completion-report',
      '/edit-work-request',
    ]);

  const isResourcesActive =
    [
      '/resources',
      '/add-machine',
      '/add-worker',
      '/add-variant-machine',
      '/duplicate-machine',
      '/worker-completion',
      // '/add-checklist',
    ].includes(location) ||
    startsWithString(location, [
      '/edit-machine',
      '/edit-worker',
      '/edit-variant-machine',
      '/machine-details',
      '/worker-detail',
      '/duplicate-machine/',
      '/add-variant-machine',
      '/machine/add-variant',
      // '/edit-checklist/',
      // '/checklist-details-view/',
    ]);

  const isCatalogueActive =
    [
      '/add-part',
      '/add-raw-material',
      '/catalogue',
      '/add-product',
      '/add-supplier',
      '/catalogue',
      '/add-variant',
    ].includes(location) ||
    startsWithString(location, [
      '/edit-part',
      '/part-details',
      '/edit-raw-material',
      '/edit-supplier',
      '/edit-product',
      '/raw-material-details',
      '/add-variant/',
    ]);

  const isProcessesActive =
    [
      '/processes',
      '/add-process',
      '/add-process-variant',
      '/variant-details',
      '/process-details',
    ].includes(location) ||
    startsWithString(location, [
      '/edit-process',
      '/edit-process-variant',
      '/variant-details',
      '/process-details',
    ]);
  const isChecklistActive =
    ['/checklists', '/add-checklist', '/checklist-details-view'].includes(location) ||
    startsWithString(location, ['/edit-checklist', '/checklist-details-view']);

  const isFactoryDataActive =
    [
      '/factory-layout',
      '/add-line',
      '/add-department',
      '/user-management',
      '/add-desktop-user',
      '/add-mobile-user',
      '/issue-mapping',
      '/factory-schedule',
    ].includes(location) ||
    startsWithString(location, [
      '/edit-line/',
      '/line-details/',
      '/edit-department/',
      '/desktop-user-details',
      '/mobile-user-details',
      '/edit-mobile-user/',
      '/edit-desktop-user',
      '/issue-mapping',
      '/edit-factory-schedule',
      '/edit-role/',
      '/role-details',
      '/factory-schedule-details',
      '/add-role',
      '/department-details/',
      '/deleted-items',
    ]);

  const isTicketActive =
    ['/my-tickets', '/add-ticket'].includes(location) ||
    startsWithString(location, ['/ticket-test', '/edit-ticket']);

  const isFactoryLayoutActive =
    ['/factory-layout', '/add-line', '/add-department'].includes(location) ||
    startsWithString(location, [
      '/edit-line/',
      '/line-details/',
      '/edit-department/',
      '/department-details/',
    ]);

  const isUserManagementActvie =
    ['/user-management', '/add-desktop-user', '/add-mobile-user'].includes(location) ||
    startsWithString(location, [
      '/desktop-user-details',
      '/mobile-user-details',
      '/edit-mobile-user/',
      '/edit-desktop-user',
      '/edit-role/',
      '/role-details',
      '/add-role',
    ]);

  const isIssueMappingActive =
    ['/issue-mapping'].includes(location) || startsWithString(location, ['/issue-mapping']);

  const isFactoryScheduleActive =
    ['/factory-schedule'].includes(location) ||
    startsWithString(location, ['/edit-factory-schedule', '/factory-schedule-details']);
  const isDeletedItemsActive =
    ['/deleted-items'].includes(location) || startsWithString(location, ['/deleted-items']);

  const isAllPermission = !_.isEmpty(
    userRole?.data?.permission?.find((role) => role?.userModule === 'all')
  );

  const isDashboardAllowed = true;
  const isWorkOrderAllowed = !_.isEmpty(
    userRole?.data?.permission?.find((role) => role?.userModule === 'workOrder')
  );
  const isResourcesAllowed = !_.isEmpty(
    userRole?.data?.permission?.find((role) => ['machine', 'worker'].includes(role?.userModule))
  );
  // const isCatalougueAllowed = !_.isEmpty(
  //   userRole?.data?.permission?.find(
  //     (role) => role?.userModule === 'part' || role?.userModule === 'product'
  //   )
  // );
  const isCatalougueAllowed = true;
  const isProcessesAllowed = !_.isEmpty(
    userRole?.data?.permission?.find((role) => role?.userModule === 'process')
  );
  // const isMyTicketsAllowed = !_.isEmpty(
  //   userRole?.data?.permission?.find((role) => role?.userModule === 'MyTickets')
  // );
  const isMyTicketsAllowed = true;
  const isChecklistAllowed = true;
  // const isFactoryDataAllowed = !_.isEmpty(
  //   userRole?.data?.permission?.find((role) => role?.userModule === 'all')
  // );
  const isFactoryDataAllowed = userRole?.data?.user?.userType === 'administrator' ? true : false;

  return (
    <SidebarWrapper>
      <ProSidebar width={'15%'}>
        <SidebarHeader className='sidebar-header'>
          <SidebarIcon />
        </SidebarHeader>
        <Menu>
          {(isAllPermission || isDashboardAllowed) && (
            <MenuItem
              className={`menu-item ${isDashboardActive && 'white-color'}`}
              active={isDashboardActive}
              icon={isDashboardActive ? <DashboardActiveIcon /> : <DashboardInActiveIcon />}
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'white-color' : isDashboardActive ? 'white-color' : 'black-color'
                }
                to='/dashboard'
              >
                <Box className='nav-link-box'>
                  Dashboard
                  {isDashboardActive && <MenuActiveIconArrow />}
                </Box>
              </NavLink>
            </MenuItem>
          )}
          {(isAllPermission || isResourcesAllowed) && (
            <MenuItem
              className={`menu-item ${isResourcesActive && 'white-color'}`}
              active={isResourcesActive}
              icon={isResourcesActive ? <ResourceActive /> : <ResourceInActive />}
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'white-color' : isResourcesActive ? 'white-color' : 'black-color'
                }
                to='/resources'
              >
                <Box className='nav-link-box'>
                  Resources
                  {isResourcesActive && <MenuActiveIconArrow />}
                </Box>
              </NavLink>
            </MenuItem>
          )}
          {(isAllPermission || isProcessesAllowed) && (
            <MenuItem
              className={`menu-item ${isProcessesActive && 'white-color'}`}
              active={isProcessesActive}
              icon={isProcessesActive ? <ProcessActiveIcon /> : <ProcessInActiveIcon />}
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'white-color' : isProcessesActive ? 'white-color' : 'black-color'
                }
                to='/processes'
              >
                <Box className='nav-link-box'>
                  Processes
                  {isProcessesActive && <MenuActiveIconArrow />}
                </Box>
              </NavLink>
            </MenuItem>
          )}
          {(isAllPermission || isCatalougueAllowed) && (
            <MenuItem
              className={`menu-item ${isCatalogueActive && 'white-color'}`}
              active={isCatalogueActive}
              icon={isCatalogueActive ? <CatalogueActive /> : <CatalogueInActive />}
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'white-color' : isCatalogueActive ? 'white-color' : 'black-color'
                }
                to='/catalogue'
              >
                <Box className='nav-link-box'>
                  Catalogue
                  {isCatalogueActive && <MenuActiveIconArrow />}
                </Box>
              </NavLink>
            </MenuItem>
          )}
          {(isAllPermission || isMyTicketsAllowed) && (
            <MenuItem
              className={`menu-item ${isTicketActive && 'fw-bold'}`}
              active={isTicketActive}
              icon={isTicketActive ? <TicketActiveIcon /> : <TicketInActiveIcon />}
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'white-color' : isTicketActive ? 'white-color' : 'black-color'
                }
                to='/my-tickets'
              >
                <Box className='nav-link-box'>
                  My Tickets
                  {isTicketActive && <MenuActiveIconArrow />}
                </Box>
              </NavLink>
            </MenuItem>
          )}
          {(isAllPermission || isChecklistAllowed) && (
            <MenuItem
              className={`menu-item ${isChecklistActive && 'white-color'}`}
              active={isChecklistActive}
              icon={isChecklistActive ? <ChecklistActiveIcon /> : <ChecklistInActiveIcon />}
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'white-color' : isChecklistActive ? 'white-color' : 'black-color'
                }
                to='/checklists'
              >
                <Box className='nav-link-box'>
                  Checklists
                  {isChecklistActive && <MenuActiveIconArrow />}
                </Box>
              </NavLink>
            </MenuItem>
          )}
          {(isAllPermission || isWorkOrderAllowed) && (
            <MenuItem
              className={`menu-item ${isWorkOrderActive && 'white-color'}`}
              active={isWorkOrderActive}
              icon={isWorkOrderActive ? <WorkOrderActive /> : <WorkOrderInActive />}
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'white-color' : isWorkOrderActive ? 'white-color' : 'black-color'
                }
                to='/work-order'
              >
                <Box className='nav-link-box'>
                  Work Orders
                  {isWorkOrderActive && <MenuActiveIconArrow />}
                </Box>
              </NavLink>
            </MenuItem>
          )}

          {/* {isAllPermission && (
            <MenuItem
              className='menu-item'
              active={location === '/inventory'}
              icon={<FiEdit2 size={'15px'} />}
            >
              <Link to='/inventory'>Inventory</Link>
            </MenuItem>
          )} */}

          {(isAllPermission || isFactoryDataAllowed) && (
            <SubMenu
              open={isFactoryDataActive || isDropdownOpen}
              className={isFactoryDataActive ? 'submenu-head-active white-color' : 'submenu-head'}
              title={`Factory Management`}
              icon={
                isFactoryDataActive ? (
                  <FactoryManagementActiveIcon />
                ) : (
                  <FactoryManagementInActiveIcon />
                )
              }
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <MenuItem
                className={isFactoryLayoutActive ? 'submenu-item-active' : 'submenu-item'}
                icon={<SubMenuItemIcon />}
              >
                Factory Layout
                <Link to='/factory-layout' />
                {/* <NavLink
                  className={({ isActive }) =>
                    isActive ? 'white-color' : isFactoryLayoutActive ? 'blue-color' : 'black-color'
                  }
                  to='/factory-layout'
                /> */}
              </MenuItem>
              <MenuItem
                className={isUserManagementActvie ? 'submenu-item-active' : 'submenu-item'}
                icon={<SubMenuItemIcon />}
              >
                User Management
                <Link to='/user-management' />
              </MenuItem>
              <MenuItem
                className={isIssueMappingActive ? 'submenu-item-active' : 'submenu-item'}
                icon={<SubMenuItemIcon />}
              >
                Issue Mapping
                <Link to='/issue-mapping' />
              </MenuItem>
              <MenuItem
                className={isFactoryScheduleActive ? 'submenu-item-active' : 'submenu-item'}
                icon={<SubMenuItemIcon />}
              >
                Factory Working
                <Link to='/factory-schedule' />
              </MenuItem>
              <MenuItem
                className={isDeletedItemsActive ? 'submenu-item-active' : 'submenu-item'}
                icon={<SubMenuItemIcon />}
              >
                Deleted Items
                <Link to='/deleted-items' />
              </MenuItem>
            </SubMenu>
          )}
        </Menu>
      </ProSidebar>
    </SidebarWrapper>
  );
};

export default Sidebar;
