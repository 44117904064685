import * as React from 'react';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { Formik, Form, FieldArray, getIn } from 'formik';
import {
  Box,
  Checkbox,
  Divider,
  Grid,
  ListItemText,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Radio,
  Autocomplete,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import CustomButton from '../commonComponents/CustomButton';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import { useLocation, useParams } from 'react-router-dom';
import Require from '../commonComponents/Required';
import CustomModalButton from '../commonComponents/CustomModalButton';
import CheckboxFilledIcon from '../../assets/svg/CheckboxFilledIcon';
import CheckboxIcon from '../../assets/svg/CheckboxIcon';
import RadioButton from '../../assets/svg/RadioButton';
import RadioButtonFilled from '../../assets/svg/RadioButtonFilled';
import { useFullGetParts } from '../../hooks/partHooks';
import { useFullGetProcesses } from '../../hooks/processHooks';
import {
  useGetProduct,
  useRestoreProduct,
  useHardDeleteProduct,
  useGetDeletedProduct,
} from '../../hooks/productHooks';
import Loading from 'react-fullscreen-loading';
import _ from 'lodash';
import { useUpdateNotification } from '../../hooks/notificationHooks';
import { useGetFullMachines } from '../../hooks/machineHooks';
import DotsixVector from '../../assets/svg/DotsixVector';
import Plus from '../../assets/svg/Plus';
import AddProductStock from '../../Modals/AddProductStock';
import { useQueryClient } from '@tanstack/react-query';
import { user } from '../../queryKeys/userManagement';

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Product name is required'),
  number: Yup.string().trim().required('Product number is required'),
  processes: Yup.array().of(
    Yup.object().shape({
      process: Yup.string().required('Process is required'),
      machine: Yup.string().required('Machine is required'),
    })
  ),
});

const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '1px 14px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));
const CheckBoxPart = styled(Checkbox)(() => ({
  padding: '0px 10px 0px 0px !important',
}));

const BoxWrapper = styled(Box)(() => ({
  '.fieldSubBox': {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    mt: 3,
    mb: 1,
  },
  '.SubHeadText': {
    fontSize: '22px',
    fontWeight: '700',
    color: '#265AA8',
  },
  '.remove': {
    display: 'flex',
    cursor: 'pointer',
    color: '#265AA8',
  },
  '.tab-panel': {
    padding: '0px !important',
  },
  '.tab-panel-box': {
    minHeight: '33vh',
  },
  '.flexBox': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  '.no-data-text': {
    fontWeight: 600,
    fontSize: '21px',
    color: '#777676',
  },
  '.no-part-text': {
    color: '#CC0000',
    fontWeight: 600,
    fontSize: '21px',
  },
  '.tab-panel-box-process': {
    minHeight: '47vh',
  },
  '.tab-panels': {
    padding: '0px',
    position: 'relative',
    bottom: '40px',
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'end',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
    bottom: '1px',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 8px 0px',
    color: '#000',
  },
  '.w-100': {
    width: '100%',
  },
  '.grid-pt': {
    '.css-1u2chej-MuiGrid-root>.MuiGrid-item': {
      paddingTop: '0px',
    },
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.reorder-icon': {
    cursor: 'pointer',
  },
  '.grid-component-card': {
    height: 'auto',
    margin: '8px 0px 0px',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    borderRadius: '4px',
    padding: '0px 0px 8px 0px',
  },
  '.reorder-icon-box': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingTop: '15px',
  },
  '.remove-card': {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    paddingRight: '15px',
    marginTop: '7px',
  },
  '.select-border-radius': {
    borderRadius: '4px',
    '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px 14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#8A8A8A',
    },
  },
  '.auto-complete': {
    '.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px',
      padding: '0px 13px 0px 13px',
      height: '42px',
    },
    '.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
      top: '7px !important',
      right: '15px !important',
    },
  },
  '.auto-complete-fieldset': {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8A8A8A',
      },
    },
  },
  '.mx-14': {
    margin: '0px 14px',
  },
  '.min-height': {
    minHeight: '30.5rem',
  },
  '.min-height-md-screen': {
    minHeight: '20rem',
  },
  '.process-header': {
    height: '40px !important',
    border: '1px solid #DEDEDE !important',
    background: 'rgba(246, 246, 246, 1)',
    borderRadius: '8px 8px 0px 0px',
    alignItems: 'center',
    marginBottom: '8px !important',
  },

  '.process-header-field-title': {
    fontSize: 14,
    fontWeight: 400,
  },
  '.margin-left': {
    marginLeft: '10px',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const moduleOptions: string[] = [
//   'Workorders',
//   'Resources',
//   'Inventory',
//   'Catalogue',
//   'Processes',
//   'User Management',
// ];

interface PropI {
  title: string;
}
export const NoDataComponent = (props: PropI) => {
  return (
    <Box className='tab-panel-box w-100 flexBox'>
      <Typography className='no-data-text'>No {props.title} Added Yet</Typography>
      <Typography className='no-data-text'>Add {props.title} To Your Product</Typography>
    </Box>
  );
};

export const AddPartFirst = () => {
  return (
    <Box className='tab-panel-box w-100 flexBox'>
      <Typography className='no-part-text'>Please add parts first</Typography>
    </Box>
  );
};
const CustomTextField = styled(TextField)(() => ({
  '.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
    borderRadius: '10px !important',
  },
  width: '100px',
}));

const AddProduct = () => {
  const { id } = useParams();
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
  const [isEditStockOpen, setIsEditStockOpen] = React.useState(false);
  const handleEditStockOpen = () => setIsEditStockOpen(true);
  const handleEditStockClose = () => setIsEditStockOpen(false);
  const [editStockData, setEditStockData] = React.useState({});
  const [isEdit, setIsEdit] = React.useState(false);
  const [parts, setParts] = React.useState<any>([]);

  const { data: partList } = useFullGetParts(true);
  const { data: machineData } = useGetFullMachines();
  const machineList: any = machineData?.map((machine) => {
    return {
      name: machine.name,
      id: machine.id,
    };
  });
  const machines = machineList?.map((machine) => {
    return {
      ...machine,
      label: machine?.name,
      color: '#000',
    };
  });
  const machineDataList = [
    {
      label: `No Machine selected`,
      id: '000',
      color: '#000',
    },
    ...(machines || []),
  ];
  const { data: processList, isLoading: isProcessLoading }: any = useFullGetProcesses(true);

  const [processes, setProcesses] = React.useState<any>([]);
  const [filteredProcesses, setFilteredProcesses] = React.useState<any>([]);

  const { mutate: hardDeleteProduct } = useHardDeleteProduct();
  const handleProductDelete = () => {
    hardDeleteProduct(id);
  };
  const location = useLocation();
  const isProductPage = location.pathname.includes('product');
  const { state } = useLocation();
  const isHideBreadcrumb =
    location.pathname.startsWith('/edit-manufacturing-order') ||
    location.pathname.startsWith('/add-manufacturing-order');
  const dragItem = React.useRef<any>(null);
  // const [formValues, setFormValues] = React.useState({});
  const dragOverItem = React.useRef<any>(null);
  const { mutate: readNotification } = useUpdateNotification();
  const isDeleted = location.pathname.startsWith('/deleted-items');
  const isRestore = location.pathname.startsWith('/deleted-items');
  const {
    data: productDetail,
    isLoading: isProductLoading,
    isSuccess: isProductSuccess,
    isRefetching: isProductRefetching,
  } = useGetProduct(id, !!id && !isDeleted && isProductPage);
  const {
    data: productDeleted,
    // isLoading: isProductLoadingDeleted,
    isSuccess: isProductSuccessDeleted,
  } = useGetDeletedProduct(id, !!id && isDeleted);

  const product = isDeleted ? productDeleted : productDetail;

  const { mutate: restoreProduct } = useRestoreProduct();

  const handleProductRestore = () => {
    if (isRestore) {
      restoreProduct(id);
    } else {
      // nothing to restore
    }
  };

  interface ProductI {
    name: string;
    number: string;
    avlStock: number;
    processes: any[];
  }
  const currentProduct: ProductI = {
    name: product?.name,
    number: product?.number,
    avlStock: product?.avlStock,
    processes: product?.processes,
  };
  let breadCrumbData = [
    {
      title: 'Catalogue',
      link: '/catalogue',
    },
    {
      title: 'Product',
      link: '/catalogue',
      activeTab: '2',
    },
    {
      title: _.get(currentProduct, 'name', 'Product Name'),
      link: '',
    },
  ];

  if (isDeleted) {
    breadCrumbData = [
      {
        title: 'Deleted Items',
        link: '/deleted-items',
      },
      {
        title: _.get(currentProduct, 'name', ''),
        link: '',
      },
    ];
  }

  const queryClient = useQueryClient();
  const userRole: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  const permission = userRole?.data?.permission;
  const isAllPermission = !_.isEmpty(permission?.find((role) => role?.userModule === 'all'));
  const rights = permission?.find((role) => role?.userModule === 'product');

  React.useEffect(() => {
    if (id && isProductPage) {
      setIsEdit(true);
    }
    if (!isProductLoading && isProductSuccess && !isProductRefetching) {
      setParts(product?.parts);
      // setIsPartsEmpty(false);
    }
  }, [isProductSuccess, isProductLoading, isProductRefetching]);

  React.useEffect(() => {
    if (!isProcessLoading && processList?.length > 0) {
      setProcesses(processList);
      setFilteredProcesses(processList);
    }
  }, [isProcessLoading, processList?.length, isEdit]);
  const processDataList = filteredProcesses?.map((process) => {
    return {
      ...process,
      label: process?.name,
      color: '#000',
    };
  });

  const modules: string[] = [];

  const currentProcesses: any = currentProduct?.processes?.map((processData) => {
    const currentParts = processData?.parts?.map((part) => {
      return { name: part?.part?.name, qty: part?.qty };
    });
    const currentProcess = processData?.process?.name;
    const currentMachine = processData?.machine?.name
      ? processData?.machine?.name
      : 'No Machine selected';
    return {
      parts: currentParts,
      process: currentProcess,
      machine: currentMachine,
    };
  });

  React.useEffect(() => {
    if (state?.isRead) {
      readNotification(state?.id);
      state.isRead = false;
    }
  }, []);
  const saveAsDaft = (values: any) => {
    // const updatedParts = parts?.map((data) => {
    //   return {
    //     // ...data,
    //     qty: data?.qty,
    //     part: data?.part?.id,
    //   };
    // });
    const updatedProcesses = values?.processes?.map((data) => {
      const currentProcess = processList.find((process) => process?.name === data?.process);
      const currentMachine = machineList.find((machine) => machine?.name === data?.machine);
      const currentParts = data?.parts?.map((partInfo) => {
        const part = partList.find((data) => partInfo?.name === data?.name);
        return { part: part?.id, qty: parseInt(partInfo?.qty) };
      });
      return {
        parts: currentParts,
        process: currentProcess?.id,
        machine: currentMachine?.id,
      };
    });
    const payload = {
      ...values,
      processes: updatedProcesses,
      isDraft: true,
    };
    setEditStockData(payload);
    handleEditStockOpen();
    // if (isEdit) {
    //   updateProduct({ ...payload, id });
    // } else {
    //   addProduct(payload);
    // }
  };

  return (
    <React.Fragment>
      {!isHideBreadcrumb && (
        <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={_.get(currentProduct, 'name', 'Product Name')}
          backToLink='/catalogue'
          isDeleted={isDeleted}
          isRestore={isRestore}
          handleDelete={handleProductDelete}
          handleRestore={handleProductRestore}
          activeTab='2'
        />
      )}
      <BoxWrapper>
        <Formik
          initialValues={{
            name: isEdit ? currentProduct.name : '',
            number: isEdit ? currentProduct.number : '',
            avlStock: isEdit ? currentProduct?.avlStock : 0,
            processes: isEdit ? currentProcesses || [] : [],
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            //Todo
            // const partsWithQty: any = {};
            // values.processes?.forEach((process) => {
            //   process?.parts?.forEach((part) => {
            //     partsWithQty[part?.name] = partsWithQty[part?.name]
            //       ? parseInt(partsWithQty[part?.name]) + parseInt(part?.qty)
            //       : part?.qty;
            //   });
            // });
            // const takenParts = parts?.filter((part) => {
            //   return Object.keys(partsWithQty).includes(part?.part?.name);
            // });
            // const invalidParts = takenParts?.filter(
            //   (part) => parseInt(part?.qty) !== parseInt(partsWithQty[part?.part?.name])
            // );
            // if (invalidParts?.length > 0) {
            //   setMismatchParts(invalidParts);
            //   setFormValues(values);
            //   handleQtyErrModalOpen();
            // }
            // const updatedParts = parts?.map((data) => {
            //   return {
            //     // ...data,
            //     qty: data?.qty,
            //     part: data?.part?.id,
            //   };
            // });
            const updatedProcesses = values?.processes?.map((data) => {
              const currentProcess = processList.find((process) => process?.name === data?.process);
              const currentMachine = machineList.find((machine) => machine?.name === data?.machine);
              const currentParts = data?.parts?.map((partInfo) => {
                const part = partList.find((data) => partInfo?.name === data?.name);
                return { part: part?.id, qty: parseInt(partInfo?.qty) };
              });
              return {
                parts: currentParts,
                process: currentProcess?.id,
                machine: currentMachine?.id,
              };
            });
            const payload = {
              ...values,
              processes: updatedProcesses,
              isDraft: false,
            };
            setEditStockData(payload);
            handleEditStockOpen();
            // if (invalidParts?.length > 0) {
            //   setMismatchParts(invalidParts);
            //   handleQtyErrModalOpen();
            // } else {
            //   if (isEdit) {
            //     updateProduct({ ...payload, id });
            //   } else {
            //     addProduct(payload);
            //   }
            // }
            // if (isEdit) {
            //   updateProduct({ ...payload, id });
            // } else {
            //   addProduct(payload);
            // }
          }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            isValid,
            setFieldValue,
            dirty,
          }) => (
            <Form noValidate autoComplete='off'>
              <Box className='main-component-padding'>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className='field-title'>
                      Product Name
                      <Require />
                    </Typography>
                    <Input
                      error={!errors.name || !touched.name ? false : true}
                      fullWidth
                      id='name'
                      placeholder='Enter Name'
                      type='text'
                      name='name'
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <br />
                    {touched.name && errors.name && (
                      <span className='validation-text'>{errors.name}</span>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className='field-title'>
                      Product Detail Number
                      <Require />
                    </Typography>
                    <Input
                      error={!errors.number || !touched.number ? false : true}
                      fullWidth
                      id='number'
                      placeholder='098-9273-1783-111-018'
                      type='text'
                      value={values.number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.number && errors.number && (
                      <span className='validation-text'>{errors.number}</span>
                    )}
                  </Grid>
                </Grid>
                <Divider />
                <Box>
                  <FieldArray
                    name='processes'
                    render={({ remove, push }) => (
                      <Box>
                        <Box className='fieldSubBox' mb={'12px'}>
                          {id && !isHideBreadcrumb && (
                            <Box mr={1}>
                              <CustomModalButton
                                className='blue-bg btn-margin'
                                onClick={() => {
                                  setEditStockData({
                                    id: id,
                                    name: currentProduct.name,
                                    avlStock: currentProduct?.avlStock,
                                  });
                                  handleEditStockOpen();
                                }}
                              >
                                Edit Stock
                              </CustomModalButton>
                            </Box>
                          )}

                          <CustomModalButton
                            // disabled={values.processes.length > 6 || (!isEdit && !parts.length)}
                            // disabled={!isEdit && !parts.length}
                            className='blue-bg'
                            // endIcon={<AddOutlinedIcon />}
                            onClick={() =>
                              push({
                                parts: modules,
                                process: '',
                                machine: '',
                              })
                            }
                          >
                            <Plus fill={'white'} />{' '}
                            <span className='margin-left'>Map Product Details</span>
                          </CustomModalButton>
                        </Box>
                        <Box>
                          <Grid container className='process-header'>
                            <Grid item xs={1} width={'50%'}>
                              <Typography
                                className='process-header-field-title'
                                textAlign={'center'}
                              >
                                #
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography className='process-header-field-title'>
                                Machine
                                <Require />
                              </Typography>
                            </Grid>
                            <Grid item xs={3} pl={'16px'}>
                              <Typography className='process-header-field-title'>
                                Process
                                <Require />
                              </Typography>
                            </Grid>
                            <Grid item xs={3} pl={'16px'}>
                              <Typography className='process-header-field-title'>
                                Parts
                                <Require />
                              </Typography>
                            </Grid>
                            <Grid item xs={2}></Grid>
                          </Grid>
                        </Box>
                        <Box className={isMdScreen ? 'min-height-md-screen' : 'min-height'}>
                          {values.processes.length > 0 &&
                            values.processes?.map((item, index) => {
                              const process = `processes[${index}].process`;
                              const touchedSelectProcess = getIn(touched, process);
                              const errorSelectProcess = getIn(errors, process);
                              const machine = `processes[${index}].machine`;
                              const touchedSelectMachine = getIn(touched, machine);
                              const errorSelectMachine = getIn(errors, machine);
                              return (
                                <Box
                                  className='row'
                                  key={index}
                                  draggable
                                  onDragStart={() => (dragItem.current = index)}
                                  onDragEnter={() => (dragOverItem.current = index)}
                                  onDragEnd={() => {
                                    const copiedProcesses = [...values.processes];
                                    const draggedItemContent = copiedProcesses.splice(
                                      dragItem.current,
                                      1
                                    )[0];

                                    //switch the position
                                    copiedProcesses.splice(
                                      dragOverItem.current,
                                      0,
                                      draggedItemContent
                                    );

                                    //reset the position ref
                                    dragItem.current = null;
                                    dragOverItem.current = null;
                                    setFieldValue('processes', copiedProcesses);
                                  }}
                                  onDragOver={(e) => e.preventDefault()}
                                >
                                  <Grid container className='grid-component-card'>
                                    <Grid item xs={1}>
                                      <Box>
                                        <Grid container className='reorder-icon-box'>
                                          <Grid item xs={6} textAlign={'center'}>
                                            <span className='reorder-icon'>
                                              {/* <ReorderIcon /> */}
                                              <DotsixVector />
                                            </span>
                                          </Grid>
                                          <Grid item xs={6}>
                                            <Typography>{index + 1}</Typography>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={3} mt={'10px'}>
                                      <Autocomplete
                                        disablePortal
                                        id='combo-box-demo'
                                        options={machineDataList || [] || ''}
                                        isOptionEqualToValue={(machineData, value) =>
                                          machineData.value === value?.value
                                        }
                                        ListboxProps={{
                                          style: {
                                            maxHeight: '14rem',
                                          },
                                        }}
                                        className='auto-complete'
                                        renderOption={(props, option) => {
                                          const { label, color } = option;
                                          return (
                                            <span
                                              {...props}
                                              style={{
                                                color: color,
                                                backgroundColor: '#fff',
                                                borderBottom: '1px solid #F6F6F6',
                                                padding: isProductPage ? '0px' : 'auto',
                                              }}
                                            >
                                              <Radio
                                                icon={<RadioButton />}
                                                checked={
                                                  values.processes?.[index]?.machine === label
                                                }
                                                checkedIcon={<RadioButtonFilled />}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `processes.${index}.machine`,
                                                    // e.target.value
                                                    label
                                                  );
                                                  if (label === 'No Machine selected') {
                                                    const filteredProcesses = processes.filter(
                                                      (data) => !data?.machine
                                                    );
                                                    setFilteredProcesses(filteredProcesses);
                                                  } else {
                                                    const filteredProcesses = processes?.filter(
                                                      (data) => data?.machine?.name === label
                                                    );
                                                    setFilteredProcesses(filteredProcesses);
                                                  }
                                                }}
                                              />
                                              {label}
                                            </span>
                                          );
                                        }}
                                        value={item?.machine || ''}
                                        onChange={(e, value) => {
                                          setFieldValue(`processes.${index}.machine`, value?.label);

                                          if (value?.label === 'No Machine selected') {
                                            const filteredProcesses = processes.filter(
                                              (data) => !data?.machine
                                            );
                                            setFilteredProcesses(filteredProcesses);
                                          } else {
                                            const filteredProcesses = processes?.filter(
                                              (data) => data?.machine?.name === value?.label
                                            );
                                            setFilteredProcesses(filteredProcesses);
                                          }
                                          // handleChange(e);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            name='machine'
                                            className='auto-complete-fieldset'
                                            placeholder='Search Machine'
                                          />
                                        )}
                                      />
                                      {touchedSelectMachine && errorSelectMachine && (
                                        <Typography className='validation-text'>
                                          {errorSelectMachine}
                                        </Typography>
                                      )}
                                    </Grid>
                                    <Grid item xs={3} mt={'10px'} pl={'16px'}>
                                      <Autocomplete
                                        disablePortal
                                        id='combo-box-demo'
                                        options={
                                          values?.processes?.[index]?.machine
                                            ? processDataList.filter((data) => {
                                                if (
                                                  values?.processes?.[index]?.machine ===
                                                  'No Machine selected'
                                                ) {
                                                  return !data?.machine;
                                                } else {
                                                  return (
                                                    data?.machine?.name ===
                                                    values?.processes?.[index]?.machine
                                                  );
                                                }
                                              })
                                            : [] || [] || ''
                                        }
                                        // options={filteredProcesses || [] || ''}
                                        isOptionEqualToValue={(processData, value) =>
                                          processData.value === value?.value
                                        }
                                        ListboxProps={{
                                          style: {
                                            maxHeight: '14rem',
                                          },
                                        }}
                                        className='auto-complete'
                                        renderOption={(props, option) => {
                                          const { label, color } = option;
                                          return (
                                            <span
                                              {...props}
                                              style={{
                                                color: color,
                                                backgroundColor: '#fff',
                                                borderBottom: '1px solid #F6F6F6',
                                                padding: isProductPage ? '0px' : 'auto',
                                              }}
                                            >
                                              <Radio
                                                icon={<RadioButton />}
                                                checked={
                                                  values.processes?.[index]?.process === label
                                                }
                                                checkedIcon={<RadioButtonFilled />}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `processes.${index}.process`,
                                                    // e.target.value
                                                    label
                                                  );
                                                }}
                                              />
                                              {label}
                                            </span>
                                          );
                                        }}
                                        value={item?.process || ''}
                                        onChange={(e, value) => {
                                          setFieldValue(`processes.${index}.process`, value?.label);
                                          // handleChange(e);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            className='auto-complete-fieldset'
                                            name={`processes.${index}.process`}
                                            placeholder='Search Process'
                                          />
                                        )}
                                      />
                                      {touchedSelectProcess && errorSelectProcess && (
                                        <Typography className='validation-text'>
                                          {errorSelectProcess}
                                        </Typography>
                                      )}
                                    </Grid>

                                    <Grid item xs={3} mt={'10px'}>
                                      <Box pl={'16px'}>
                                        <FormControl fullWidth>
                                          <Select
                                            // name={`processes.${index}.parts.${modules.length}`}
                                            labelId='demo-multiple-checkbox-label'
                                            id='demo-multiple-checkbox'
                                            multiple
                                            className='select-border-radius'
                                            // onChange={handleChange}
                                            onChange={(e) => {
                                              const isAleradySelected = values?.processes[
                                                index
                                              ]?.parts?.find(
                                                (partInfo) =>
                                                  partInfo?.name ===
                                                  e.target.value?.[e.target.value.length - 1]
                                              );
                                              if (isAleradySelected) {
                                                const filteredData = values?.processes[
                                                  index
                                                ]?.parts?.filter(
                                                  (partData) =>
                                                    partData?.name !==
                                                    e.target.value?.[e.target.value.length - 1]
                                                );
                                                setFieldValue(
                                                  `processes.${index}.parts`,
                                                  filteredData
                                                );
                                              } else {
                                                setFieldValue(
                                                  `processes.${index}.parts.${item.parts.length}.name`,
                                                  e.target.value?.[
                                                    item?.parts?.length > 0
                                                      ? item?.parts?.length
                                                      : 0
                                                  ]
                                                );
                                              }
                                            }}
                                            value={item?.parts}
                                            displayEmpty
                                            renderValue={(selected) => {
                                              const renderVal = item?.parts?.map(
                                                (currPart) =>
                                                  `${currPart?.name} (${currPart?.qty || 0})`
                                              );
                                              if (selected?.length === 0) {
                                                return (
                                                  <span className='menu-item-span'>
                                                    Select parts
                                                  </span>
                                                );
                                              }
                                              return renderVal?.join(',');
                                              // return selected?.join(',');
                                            }}
                                            MenuProps={MenuProps}
                                            placeholder='select part'
                                          >
                                            {partList?.map((partDetail) => {
                                              const partsArr = item?.parts?.map((part) => {
                                                return part?.name;
                                              });
                                              const currentIndex = values.processes?.[
                                                index
                                              ]?.parts?.findIndex(
                                                (partData) => partData?.name === partDetail?.name
                                              );
                                              return (
                                                <MenuItem
                                                  key={partDetail?.name}
                                                  value={partDetail?.name}
                                                >
                                                  <CheckBoxPart
                                                    checked={
                                                      partsArr?.indexOf(partDetail?.name) > -1
                                                    }
                                                    icon={<CheckboxIcon />}
                                                    checkedIcon={<CheckboxFilledIcon />}
                                                  />
                                                  <ListItemText primary={partDetail?.name} />
                                                  <CustomTextField
                                                    // autoFocus
                                                    placeholder='Enter Qty'
                                                    size='small'
                                                    variant='outlined'
                                                    disabled={currentIndex === -1}
                                                    // autoComplete={false}
                                                    // autoCorrect={false}
                                                    autoFocus={false}
                                                    name={`processes.${index}.parts.${currentIndex}.qty`}
                                                    value={
                                                      values.processes[index].parts[currentIndex]
                                                        ?.qty
                                                    }
                                                    onClick={(e) => e.stopPropagation()}
                                                    onChange={(e) => {
                                                      e.stopPropagation();
                                                      handleChange(e);
                                                    }}
                                                  />
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={2} display={'flex'} alignItems={'center'}>
                                      <Box className='remove-card'>
                                        <Box onClick={() => remove(index)} className='remove'>
                                          <Typography>Remove</Typography>
                                          <CloseIcon />
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              );
                            })}
                        </Box>
                      </Box>
                    )}
                  />
                </Box>
              </Box>

              {/* <Divider /> */}
              {(isAllPermission || rights?.edit) && (
                <Box className='button-box'>
                  <Box className='footer-padding'>
                    <CustomButton
                      variant='outlined'
                      className='mx-14'
                      onClick={() => saveAsDaft(values)}
                    >
                      Save as Draft
                    </CustomButton>
                    <CustomButton
                      type='submit'
                      className={!id && !(isValid && dirty) ? 'disable-btn' : 'blue-bg'}
                      variant='contained'
                    >
                      Save Product
                    </CustomButton>
                  </Box>
                </Box>
              )}
            </Form>
          )}
        </Formik>
        {/* {isQtyErrModalOpen && (
          <QuantityMisMatched
            isOpen={isQtyErrModalOpen}
            handleDraft={() => saveAsDaft(formValues)}
            handleClose={handleQtyErrModalClose}
            data={mismatchParts}
          />
        )} */}
        {/* {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )} */}
        <Loading
          loading={
            id && isProductPage && (isDeleted ? !isProductSuccessDeleted : !isProductSuccess)
              ? true
              : false
          }
          background='white'
          loaderColor='#265AA8'
        />
        {isEditStockOpen && (
          <AddProductStock
            data={editStockData}
            isOpen={isEditStockOpen}
            handleClose={handleEditStockClose}
          />
        )}
      </BoxWrapper>
    </React.Fragment>
  );
};

export default AddProduct;
