const machine = {
  MACHINE: 'MACHINE',
  MACHINES: 'MACHINES',
};

const worker = {
  WORKER: 'WORKER',
  WORKERS: 'WORKERS',
};

export { machine, worker };
