import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
// import { Checkbox, FormControl, ListItemText, Select } from '@mui/material';
// import { MenuItem } from 'react-pro-sidebar';
import CheckboxFilledIcon from '../assets/svg/CheckboxFilledIcon';
import CheckboxIcon from '../assets/svg/CheckboxIcon';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';
const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 370,
  bgcolor: 'background.paper',
  borderRadius: '32px',
  boxShadow: 24,
  p: 4,
};
const BoxWrapper = styled(Box)(() => ({
  '.close-icon': {
    cursor: 'pointer',
  },
  '.title-box': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '.select-box': {
    marginTop: '3%',
  },
  '.btn-box': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    marginTop: '55%',
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const EditProductDashboard = ({
  handleClose,
  open,
  filteredProducts,
  setFilteredProducts,
  products,
  setProducts,
  ProductionName,
}: any) => {
  const [productData, setProductData] = React.useState<any>(ProductionName);
  const handleChange = (event: SelectChangeEvent<typeof productData>) => {
    const {
      target: { value },
    } = event;
    if (value.length <= 5) {
      setProductData(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value
      );
    }
  };
  const isProductData = (item) => productData.includes(item);
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <BoxWrapper sx={style}>
          <Box className='title-box'>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Products List
            </Typography>
            <CloseIcon onClick={handleClose} className='close-icon' />
          </Box>
          <Box className='select-box'>
            <FormControl fullWidth>
              <Select
                labelId='demo-multiple-checkbox-label'
                id='demo-multiple-checkbox'
                multiple
                value={productData}
                onChange={handleChange}
                // input={<OutlinedInput label='Tag' />}
                renderValue={(selected) => {
                  // return selected.join(', ');
                  if (selected.length === 0) {
                    return <span className='menu-item-span'>Select Product</span>;
                  }
                  return selected?.join(',');
                }}
                MenuProps={MenuProps}
              >
                <MenuItem value='' disabled>
                  <span className='menu-item-span'>Select Product</span>
                </MenuItem>
                {products?.map((product) => (
                  <MenuItem
                    key={product?.name}
                    value={product?.name}
                    disabled={productData?.length === 5 && !isProductData(product?.name)}
                  >
                    <Checkbox
                      checked={productData.indexOf(product?.name) > -1}
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckboxFilledIcon />}
                    />
                    <ListItemText primary={product?.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className='btn-box'>
            <CustomModalButton
              className='blue-color edit-btn'
              onClick={() => {
                const newSelectedData = products?.filter((data) => productData.includes(data.name));
                setFilteredProducts(newSelectedData);
                handleClose();
              }}
            >
              Done
            </CustomModalButton>
          </Box>
        </BoxWrapper>
      </Modal>
    </div>
  );
};
export default EditProductDashboard;
