import * as React from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik, Form, FieldArray } from 'formik';
import { Box, Divider, Grid, TextField, Typography, Select, Button } from '@mui/material';
import { MenuItem, FormControl } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import Require from '../commonComponents/Required';
import CustomButton from '../commonComponents/CustomButton';
import ErrorPopup from '../../Modals/ErrorPopup';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import { useGetFullMachines } from '../../hooks/machineHooks';
import { useGetFullWorkorders } from '../../hooks/workorderHooks';
import { useGetAllUsers } from '../../hooks/userManagementHooks';
import { useAddTicket, useGetTicket, useUpdateTicket } from '../../hooks/ticketHooks';
import { useFullGetMobileUsers } from '../../hooks/workerManagementHooks';
import Plus from '../../assets/svg/Plus';

const validationSchema = Yup.object().shape({
  ticketOrigin: Yup.string().required('Select ticket'),
  workOrder: Yup.string().when('ticketOrigin', {
    is: (ticketOrigin) => ticketOrigin === 'workorder',
    then: Yup.string().required('Select workorder '),
  }),
  machine: Yup.string().when('ticketOrigin', {
    is: (ticketOrigin) => ticketOrigin === 'maintenance',
    then: Yup.string().required('Select Machine '),
  }),
  maintenanceSchedule: Yup.string().when('machine', {
    is: (machine) => machine,
    then: Yup.string().required('Select Maintenance Schedule '),
  }),
  action: Yup.string().trim().required('Actions details is Required'),
  primaryAssignee: Yup.string().required('PrimaryAssignee is required'),
  //   backupAssignees: Yup.array()
  //     .of(
  //       Yup.object().shape({
  //         primaryAssignee: Yup.string().required('label is required'),
  //       })
  //     )
  //     .min(1),
});
const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '9.5px 14px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8A',
  },
}));
const InputField = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '1px 14px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));

// class CSS
const BoxWrapper = styled(Box)(() => ({
  '.title-text': {
    fontSize: '34px',
    fontWeight: '700',
    color: '#265AA8',
    //paddingLeft: '33px',
    textTransform: 'capitalize',
  },
  '.fieldSubBox': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: 3,
    mb: 1,
  },
  '.field-title': {
    marginBottom: '3px',
  },
  '.SubHeadText': {
    fontSize: '22px',
    fontWeight: '700',
    color: '#265AA8',
  },
  '.checkList-btn': {
    padding: '9px 20px 10px 20px !important',
    borderRadius: '10px !important',
    fontSize: '16px !important',
    fontWeight: '500 !important',
    textTransform: 'capitalize',
    lineHeight: '21px',
  },
  '.remove': {
    display: 'flex',
    marginTop: '9px',
    cursor: 'pointer',
    color: '#265AA8',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
    // padding: '20px',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
    position: 'absolute',
    bottom: '1px',
    left: '0px',
    width: '100%',
  },
  '.ml-10': {
    marginLeft: '10px',
  },
  '.border-outline': {
    border: '1px solid rgba(25, 118, 210, 0.5)',
  },
  '.min-height': {
    minHeight: '500px',
  },
  '.btn-background': {
    color: '#0160B9',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: 600,
    padding: '0px',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
}));

const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
      // width: 250,
    },
  },
};

const AddTicket = () => {
  const { state } = useLocation();
  const [isEdit, setIsEdit] = React.useState(false);
  const [maintenanceSchedule, setMaintenanceSchedule] = React.useState<{ [key: string]: any }>();

  const { id } = useParams();
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalOpen = () => setIsErrorModalOpen(true);
  const handleErrorModalClose = () => setIsErrorModalOpen(false);

  //get machines
  const { data: getFullMachines } = useGetFullMachines();
  const { data: getFullWorkorders } = useGetFullWorkorders();
  // const { data: getAllUsers } = useGetAllUsers(true);
  const params = { isVerified: true, isCompletion: true };
  const { data: getAllUsers } = useFullGetMobileUsers(true, params);
  //Add Ticket
  const {
    mutate: addTicket,
    isError: isAddTicketError,
    error: addTicketError,
    isLoading,
  } = useAddTicket();
  //Update Ticket
  const {
    mutate: updateTicket,
    isError: isUpdateTicketError,
    error: updateTicketError,
  } = useUpdateTicket();
  //Get Single Ticket
  const { data: getTicket, isSuccess } = useGetTicket(id, !!id);
  const ticket = getTicket?.ticket;
  const icon = <AiOutlineInfoCircle color='red' />;
  const breadCrumbData = [
    {
      title: 'My Tickets',
      link: '/my-tickets',
      activeTab: state?.activeTab || 1,
    },
    {
      title: `${isEdit ? 'edit Ticket' : 'Create Ticket'}`,
      link: `/edit-ticket/${id}`,
      activeTab: state?.activeTab || 1,
    },
  ];

  // const backupAssign = ticket?.backupAssignees?.map((data) => {
  //   return {
  //     name: data?.name,
  //     id: data?.id,
  //   };
  // });
  const backupAssign = ticket?.backupAssignees?.map((data) => data?.id);
  React.useEffect(() => {
    if (id) {
      setIsEdit(true);
      const machineData = getFullMachines?.find((data) => data.id == ticket?.machine?.id);
      setMaintenanceSchedule(machineData);
    }
  }, [id, isSuccess]);

  React.useEffect(() => {
    if (isAddTicketError || isUpdateTicketError) {
      isAddTicketError
        ? setErrorMessage(addTicketError?.data?.message)
        : setErrorMessage(updateTicketError?.data?.message);
      handleErrorModalOpen();
    }
  }, [isAddTicketError, isUpdateTicketError]);
  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={`${isEdit ? 'edit' : 'Create'} Ticket`}
        backToLink='/my-tickets'
        activeTab={state?.activeTab ? state?.activeTab : '1'}
      />
      <BoxWrapper className='main-component-padding'>
        <Formik
          initialValues={{
            // do not need to map values within items because FieldArray
            ticketOrigin: isEdit ? ticket?.ticketOrigin : '',
            action: isEdit ? ticket?.action : '',
            workOrder: isEdit ? ticket?.workOrder?.id : '',
            machine: isEdit ? ticket?.machine?.id : '',
            maintenanceSchedule: isEdit ? ticket?.maintenanceSchedule : '',
            primaryAssignee: isEdit ? ticket?.primaryAssignee?.id : '',
            backupAssignees: isEdit ? backupAssign || [] : [''] || [],
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const workOrder = getFullWorkorders?.find(
              (workOrder) => workOrder.id === values?.workOrder
            );
            const updateValue = {
              ...values,
              backupAssignees: values.backupAssignees?.filter((assignee) => assignee),
              objectType:
                values.ticketOrigin === 'Independent ticket'
                  ? 'Generic'
                  : values.ticketOrigin === 'maintenance'
                  ? 'Machine'
                  : workOrder?.product
                  ? 'Product'
                  : 'Generic',
            };
            const finalValue = Object.entries(updateValue).reduce(
              (a, [k, v]) => (v ? ((a[k] = v), a) : a),
              {}
            );
            id ? updateTicket({ ...finalValue, id }) : addTicket(finalValue);
          }}
        >
          {({ values, touched, errors, handleChange, isValid, dirty, setFieldValue }) => (
            <Form noValidate autoComplete='off'>
              <>
                <Typography className='title-typography'>Enter Ticket Details</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className='field-title'>
                      Ticket Origin
                      <Require />
                    </Typography>
                    <FormControl fullWidth>
                      <Selects
                        error={!errors.ticketOrigin || !touched.ticketOrigin ? false : true}
                        onChange={(e) => {
                          if (e.target.value === 'workorder') {
                            setFieldValue('machine', '');
                            setFieldValue('maintenanceSchedule', '');
                          }
                          if (e.target.value === 'maintenance') {
                            setFieldValue('workorder', '');
                          }
                          if (e.target.value === 'Independent ticket') {
                            setFieldValue('workorder', '');
                            setFieldValue('machine', '');
                            setFieldValue('maintenanceSchedule', '');
                          }
                          handleChange(e);
                        }}
                        name='ticketOrigin'
                        variant='outlined'
                        value={values.ticketOrigin || ''}
                        defaultValue=''
                        displayEmpty
                      >
                        <MenuItem value='' disabled>
                          <span className='menu-item-span'>Select Ticket Origin</span>
                        </MenuItem>
                        <MenuItem value='workorder'>Workorder</MenuItem>
                        <MenuItem value='maintenance'>Maintenance</MenuItem>
                        <MenuItem value='Independent ticket'>Independent Ticket</MenuItem>
                      </Selects>
                      {touched.ticketOrigin && errors.ticketOrigin && (
                        <Typography className='validation-text'>{errors.ticketOrigin}</Typography>
                      )}
                    </FormControl>
                  </Grid>
                  {values.ticketOrigin == 'workorder' && (
                    <Grid item xs={4}>
                      <Typography className='field-title'>
                        Workorder
                        <Require />
                      </Typography>
                      <FormControl fullWidth>
                        <Selects
                          // error={!errors.workorder || !touched.workorder ? false : true}
                          onChange={handleChange}
                          name='workOrder'
                          variant='outlined'
                          value={values.workOrder || ''}
                          defaultValue=''
                          displayEmpty
                          MenuProps={MenuProps}
                        >
                          <MenuItem value='' disabled>
                            <span className='menu-item-span'>Select Workorder</span>
                          </MenuItem>
                          {getFullWorkorders?.map((workorder, ind) => {
                            return (
                              <MenuItem value={workorder.id} key={ind}>
                                {workorder.title}
                              </MenuItem>
                            );
                          })}
                        </Selects>
                        {touched.workOrder && errors.workOrder && (
                          <Typography className='validation-text'>{errors.workOrder}</Typography>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  {values.ticketOrigin == 'maintenance' && (
                    <Grid item xs={4}>
                      <Typography className='field-title'>
                        Machine
                        <Require />
                      </Typography>
                      <FormControl fullWidth>
                        <Selects
                          // error={!errors.workorder || !touched.workorder ? false : true}
                          onChange={(e) => {
                            const machineData = getFullMachines?.find(
                              (data) => data.id == e.target.value
                            );
                            setMaintenanceSchedule(machineData);

                            handleChange(e);
                          }}
                          name='machine'
                          variant='outlined'
                          value={values.machine || ''}
                          defaultValue=''
                          displayEmpty
                          MenuProps={MenuProps}
                        >
                          <MenuItem value='' disabled>
                            <span className='menu-item-span'>Select Machine</span>
                          </MenuItem>
                          {getFullMachines?.map((machine, ind) => {
                            return (
                              <MenuItem value={machine.id} key={ind}>
                                {machine.name}
                              </MenuItem>
                            );
                          })}
                        </Selects>
                        {touched.machine && errors.machine && (
                          <Typography className='validation-text'>
                            {isEdit ? ticket?.machine : errors.machine}
                          </Typography>
                        )}
                      </FormControl>
                    </Grid>
                  )}

                  {values.machine && (
                    <Grid item xs={4}>
                      <Typography className='field-title'>
                        Maintenance Schedule
                        <Require />
                      </Typography>
                      <FormControl fullWidth>
                        <Selects
                          // error={!errors.workorder || !touched.workorder ? false : true}
                          onChange={handleChange}
                          name='maintenanceSchedule'
                          variant='outlined'
                          value={values.maintenanceSchedule || ''}
                          defaultValue=''
                          displayEmpty
                          MenuProps={MenuProps}
                        >
                          <MenuItem value='' disabled>
                            <span className='menu-item-span'>Select Maintenance</span>
                          </MenuItem>
                          {maintenanceSchedule?.maintenanceDetails?.map((schedule, ind) => {
                            return (
                              <MenuItem value={schedule._id} key={ind}>
                                {schedule.title}
                              </MenuItem>
                            );
                          })}
                        </Selects>
                        {touched.maintenanceSchedule && errors.maintenanceSchedule && (
                          <Typography className='validation-text'>
                            {errors.maintenanceSchedule}
                          </Typography>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={2} mt={'22px'}>
                  <Grid item xs={8}>
                    <Typography className='field-title'>
                      Action
                      <Require />
                    </Typography>
                    <InputField
                      error={!errors.action || !touched.action ? false : true}
                      placeholder='Select Action for the Tickets'
                      fullWidth
                      onChange={handleChange}
                      name='action'
                      value={values.action || ''}
                      InputProps={{
                        endAdornment: touched.action && errors.action && icon,
                      }}
                    />
                    {touched.action && errors.action && (
                      <Typography className='validation-text'>{errors.action}</Typography>
                    )}
                  </Grid>
                </Grid>
              </>
              <Divider />
              {/* /////////////////////////////////////////////////////////////////////////////// */}
              {/* begin FieldArray for items */}
              <FieldArray
                name='backupAssignees'
                render={({ push }) => (
                  <Box className='min-height'>
                    <Box>
                      <Typography className='title-typography'>Assign Ticket to</Typography>
                      {/* add checklist item */}
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography className='field-title'>
                          primary Assignee
                          <Require />
                        </Typography>
                        <FormControl fullWidth>
                          <Selects
                            error={
                              !errors.primaryAssignee || !touched.primaryAssignee ? false : true
                            }
                            onChange={handleChange}
                            name='primaryAssignee'
                            variant='outlined'
                            value={values.primaryAssignee || ''}
                            defaultValue=''
                            displayEmpty
                            MenuProps={MenuProps}
                          >
                            <MenuItem value='' disabled>
                              <span className='menu-item-span'>Select worker</span>
                            </MenuItem>
                            {getAllUsers?.map((user, ind) => {
                              return (
                                <MenuItem value={user.id} key={ind}>
                                  {user.name}
                                </MenuItem>
                              );
                            })}
                          </Selects>
                          {touched.primaryAssignee && errors.primaryAssignee && (
                            <Typography className='validation-text'>
                              {errors.primaryAssignee}
                            </Typography>
                          )}
                        </FormControl>
                      </Grid>
                      {values?.backupAssignees?.length > 0 &&
                        values?.backupAssignees?.map((item, index) => {
                          const BackupAssign = `backupAssignees[${index}]`;
                          // TODO:- Commented For Error Handling
                          // const touchedBackupAssign = getIn(touched, BackupAssign);
                          // const errorBackupAssign = getIn(errors, BackupAssign);

                          return (
                            <Grid item xs={4} key={index}>
                              <Typography className='field-title'>Backup Assignee</Typography>
                              <FormControl fullWidth>
                                <Selects
                                  onChange={handleChange}
                                  name={BackupAssign}
                                  variant='outlined'
                                  value={item || ''}
                                  //sx={{ width: '528px', marginLeft: '16px', marginTop: '16px' }}
                                  displayEmpty
                                  MenuProps={MenuProps}
                                >
                                  <MenuItem value='' disabled>
                                    <span className='menu-item-span'>Select worker</span>
                                  </MenuItem>
                                  {getAllUsers?.map((user, ind) => {
                                    return (
                                      <MenuItem value={user.id} key={ind}>
                                        {user.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Selects>
                              </FormControl>
                            </Grid>
                          );
                        })}
                    </Grid>
                    <Box display={'flex'} mt={'20px'}>
                      <Button className='btn-background' onClick={() => push('')}>
                        <Plus fill={'#0160B9'} />{' '}
                        <span className='ml-10'> Add Backup Assignee</span>
                      </Button>
                      {/* <CustomButton
                      //type='submit'
                      className={!(isValid && dirty) ? 'disable-btn' : 'yellow-bg'}
                      variant='contained'
                    >
                      Save
                    </CustomButton> */}
                    </Box>
                  </Box>
                )}
              />
              <Box className='button-box'>
                <Box mr={1} p={2}>
                  <CustomButton
                    disabled={isLoading}
                    type='submit'
                    className={!id && !(isValid && dirty) ? 'disable-btn' : 'blue-bg'}
                    variant='contained'
                  >
                    {isEdit ? 'Update' : 'Create'} Ticket
                  </CustomButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        {errorMessage && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
      </BoxWrapper>
    </React.Fragment>
  );
};

export default AddTicket;
