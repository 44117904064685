import { ticketErrI, ticketI, ticketListErrI, ticketListI } from '../Types/ticket';
import { deleteRequest, get, patch, post } from '../Utils/index';
export const addTicket = async (data): Promise<ticketI | ticketErrI> => {
  return post(`/customer/ticket`, data).then((res) => res.data);
};
export const getTicket = async (data): Promise<ticketI | ticketErrI> => {
  const id = data.queryKey[1];
  return get(`/customer/ticket/${id}`).then((res) => res.data);
};
// export const getTickets = async () => {
//   return get(`/customer/ticket`);
// };
export const getTickets = async (
  data
): Promise<ticketListI | undefined | { tickets: { results: [] } } | ticketListErrI> => {
  const type = data.queryKey[1];
  return get(`/customer/ticket/ticketlist`, type).then((res) => res.data);
};
// export const getFullWorkorders = async () => {
//   return get(`/customer/work-order/full-list`);
// };
export const updateTicket = async (data): Promise<ticketI | ticketErrI> => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/ticket/${id}`, data).then((res) => res.data);
};
export const deleteTicket = async (id) => {
  return deleteRequest(`/customer/ticket/${id}`);
};
export const ticketResolved = async (id) => {
  return patch(`/customer/ticket/resolved/${id}`);
};

export const getComments = async (data) => {
  const id = data.queryKey[1];
  return get(`/customer/comments/${id}`, { limit: 10, page: data?.pageParam });
};
export const addComments = async (data) => {
  return post(`/customer/comments`, data);
};

export const ticketApi = {
  addTicket,
  getTicket,
  getTickets,
  deleteTicket,
  updateTicket,
  getComments,
  addComments,
  ticketResolved,
};
