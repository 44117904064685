import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import './../../src/style/modal.css';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  //border: '2px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  p: 2,
};

const BoxWrapper = styled(Box)(() => ({
  '.button-box': {
    width: '150px',
  },
}));
interface propI {
  open: string;
  handleClose: () => void;
  handleDelete: () => void;
  id: string;
  isDeleteOrRestoreAll: boolean;
}

const RestoreWarningModal = ({
  open,
  handleClose,
  handleDelete,
  id,
  isDeleteOrRestoreAll = false,
}: any) => {
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <BoxWrapper>
            <Box textAlign={'center'}>
              <Box m={2}>
                <Typography>
                  Are yor sure you want to restore {isDeleteOrRestoreAll ? 'All' : 'This'} record
                  {isDeleteOrRestoreAll ? 's' : ''} ?
                </Typography>
              </Box>

              <Box m={2} display={'flex'} justifyContent={'space-between'}>
                <CustomModalButton className='button-box blue-color' onClick={() => handleClose()}>
                  Cancel
                </CustomModalButton>
                <CustomModalButton
                  className='button-box blue-bg'
                  onClick={() => {
                    if (isDeleteOrRestoreAll) {
                      handleDelete();
                    } else {
                      handleDelete(id);
                    }
                  }}
                >
                  Restore
                </CustomModalButton>
              </Box>
            </Box>
          </BoxWrapper>
        </Box>
      </Modal>
    </div>
  );
};
export default RestoreWarningModal;
