import React, { useState } from 'react';
import { Box, Grid, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import styled from '@emotion/styled';
import TableComponent from '../commonComponents/Table';
import { useLocation } from 'react-router-dom';
import CommentIcon from '../../assets/svg/CommentIcon';
import { useDeleteTicket, useGetTickets } from '../../hooks/ticketHooks';
import DeleteWarningModal from '../../Modals/DeleteWarningModal';
import { GoPrimitiveDot } from 'react-icons/go';
import TicketCommentModal from '../../Modals/TicketCommentModal';
import moment from 'moment';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import DeleteIcon_new from '../../assets/svg/DeleteIcon_new';
import { useQueryClient } from '@tanstack/react-query';
import { user } from '../../queryKeys/userManagement';
import AccessIcon from '../../assets/svg/AccessIcon';

const GridWrapper = styled(Grid)(() => ({
  '.tab-list-box': {
    marginBottom: '18px',
  },
  '.tab-head': {
    borderColor: 'rgba(0, 0, 0, 0.12)',
  },
  '.tab-panels': {
    padding: '2px 0px 0px 0px',
  },
  '.grid-table': {
    padding: '24px 0px 0px 0px',
  },
  '.tab-panel-box': {
    borderBottom: '1px solid #DEDEDE',
  },
}));

const MyTickets = () => {
  const { state } = useLocation();
  const [value, setValue] = React.useState(state?.activeTab ? state?.activeTab : '1');
  const [deleteRecord, setDeleteRecord] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const [currentTicketInfo, setCurrentTicketInfo] = useState<{
    action?: string;
    assignedTo?: string;
    createdAt?: string;
    createdBy?: string;
    id?: string;
    no?: number;
    status?: string;
    ticketOrigin?: string;
  }>({});
  const handleDeleteOpen = () => setDeleteRecord(true);
  const handleDeleteClose = () => setDeleteRecord(false);
  const [commitOpen, setCommitOpen] = useState(false);
  const handleCommitOpen = () => setCommitOpen(true);
  const handleCommitClose = () => setCommitOpen(false);
  const [type, setType] = useState('');
  const [page, setPage] = useState(1);
  const PagePath = { page, limit: 10, type };
  const {
    data: ticketListInfo,
    isLoading: isTicketLoading,
    isFetching: isTicketFetching,
    isSuccess: ticketSuccess,
  } = useGetTickets(true, PagePath);

  const ticketList = ticketListInfo?.results;
  // Access Module
  const queryClient = useQueryClient();
  const userSubscription: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  const subscriptions = userSubscription?.data?.subscription;
  const ticketRights = subscriptions?.find((subscription) => subscription?.userModule === 'ticket');

  const { mutate: deleteTicket } = useDeleteTicket(value);

  const AssignedToMeCols = [
    { field: 'no', headerName: 'Sr.No', flex: 0.3 },
    { field: 'assignedTo', headerName: 'Assigned by', flex: 1 },
    {
      field: 'ticketOrigin',
      headerName: 'Ticket Origin',
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Date Created',
      flex: 1,
    },
    {
      field: 'updatedOn',
      headerName: 'Updated on',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      // width: 200,
      width: 126,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Box className='table-icons'>
            <Box
              pr={'16px'}
              onClick={() => {
                setCurrentId(params.row.id);
                setCurrentTicketInfo(params.row);
                handleCommitOpen();
              }}
            >
              <CommentIcon />
            </Box>
            {/* <Box
              pr={'16px'}
              onClick={() =>
                navigate(`/edit-ticket/${params.row.id}`, { state: { activeTab: '2' } })
              }
            >
              <EditIcon_new />
            </Box> */}
            <Box onClick={() => deleteTicket(params.row.id)}>
              <DeleteIcon_new />
            </Box>
          </Box>
        );
      },
    },
  ];

  const createdByMeCols = [
    { field: 'no', headerName: 'Sr.No', flex: 0.4 },
    { field: 'assignedTo', headerName: 'Assigned to', flex: 1 },
    {
      field: 'ticketOrigin',
      headerName: 'Ticket Origin',
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className='table-icons'>
            <Box pr={'25px'} display='flex' alignItems={'center'}>
              {params.row.status === 'pending' ? (
                <>
                  <GoPrimitiveDot fontSize={'24px'} color={'#FFC229'} /> {params.row.status}
                </>
              ) : (
                <Box pl={'25px'}>{params.row.status}</Box>
              )}
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Date Created',
      flex: 1,
    },
    {
      field: 'updatedOn',
      headerName: 'Updated on',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      // width: 200,
      width: 127,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Box className='table-icons'>
            <Box
              pr={'16px'}
              onClick={() => {
                setCurrentId(params.row.id);
                setCurrentTicketInfo(params.row);
                handleCommitOpen();
              }}
            >
              <CommentIcon />
            </Box>
            {/* <Box
              pr={'16px'}
              onClick={() =>
                navigate(`/edit-ticket/${params.row.id}`, { state: { activeTab: '3' } })
              }
            >
              <EditIcon_new />
            </Box> */}
            <Box
              onClick={() => {
                setCurrentId(params.row.id);
                handleDeleteOpen();
              }}
            >
              <DeleteIcon_new />
            </Box>
          </Box>
        );
      },
    },
  ];

  const AllTicketsCols = [
    { field: 'no', headerName: 'Sr.No', flex: 0.3 },
    { field: 'assignedTo', headerName: 'Assigned to', flex: 1 },
    {
      field: 'createdBy',
      headerName: 'Created by',
      flex: 0.9,
    },
    {
      field: 'ticketOrigin',
      headerName: 'Ticket Origin',
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.8,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className='table-icons'>
            <Box pr={'22px'} display='flex' alignItems={'center'}>
              {params.row.status === 'pending' ? (
                <>
                  <GoPrimitiveDot fontSize={'24px'} color={'#FFC229'} /> {params.row.status}
                </>
              ) : (
                <Box pl={'22px'}>{params.row.status}</Box>
              )}
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Date Created',
      flex: 1,
    },
    {
      field: 'updatedOn',
      headerName: 'Updated on',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      // width: 200,
      // width: 126,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Box className='table-icons'>
            <Box
              pr={'16px'}
              onClick={() => {
                setCurrentId(params.row.id);
                setCurrentTicketInfo(params.row);
                handleCommitOpen();
              }}
            >
              <CommentIcon />
            </Box>
            {/* <Box
              pr={'16px'}
              onClick={() =>
                navigate(`/edit-ticket/${params.row.id}`, { state: { activeTab: '1' } })
              }
            >
              <EditIcon_new />
            </Box> */}
            <Box
              onClick={() => {
                setCurrentId(params.row.id);
                handleDeleteOpen();
              }}
            >
              <DeleteIcon_new />
            </Box>
          </Box>
        );
      },
    },
  ];
  const AllTicketsRows = ticketList?.map((data, ind) => {
    const startIndex = (page - 1) * 10;
    return {
      id: data.id,
      no: startIndex + ind + 1,
      assignedTo: `${data.primaryAssignee?.name || '-'} ${
        data?.backupAssignees[0]?.name ? ',' + data?.backupAssignees[0]?.name : ''
      }`,
      createdBy: data?.createBy?.name,
      ticketOrigin: data.ticketOrigin,
      action: data.action,
      status: data?.ticketStatus,
      createdAt: data?.createdAt ? moment(data?.createdAt)?.format('hh:mm A, DD MMM, YYYY') : '-',
      updatedOn: data?.updatedAt ? moment(data?.updatedAt).format('hh:mm A, DD MMM, YYYY') : '-',
      typeOfIssue: data?.natureOfIssue,
      reasonOfIssue: data?.typeOfIssue,
      ticketNo: data?.ticketNo,
      images: data?.images,
    };
  });

  const AssignedToMeRows = ticketList?.map((data, ind) => {
    const startIndex = (page - 1) * 10;
    return {
      id: data.id,
      no: startIndex + ind + 1,
      assignedTo: `${data.primaryAssignee?.name || '-'} ${
        data?.backupAssignees[0]?.name ? ',' + data?.backupAssignees[0]?.name : ''
      }`,
      ticketOrigin: data.ticketOrigin,
      action: data.action,
      status: data?.ticketStatus,
      createdAt: data?.createdAt ? moment(data?.createdAt)?.format('hh:mm A, DD MMM, YYYY') : '-',
      updatedOn: data?.updatedAt ? moment(data?.updatedAt).format('hh:mm A, DD MMM, YYYY') : '-',
      createdBy: data?.createBy?.name,
      typeOfIssue: data?.natureOfIssue,
      reasonOfIssue: data?.typeOfIssue,
      ticketNo: data?.ticketNo,
      images: data?.images,
    };
  });
  const createdByMeRows = ticketList?.map((data, ind) => {
    // const availability = ['Pending'];
    const startIndex = (page - 1) * 10;
    return {
      id: data.id,
      no: startIndex + ind + 1,
      assignedTo: `${data.primaryAssignee?.name || '-'}${
        data?.backupAssignees[0]?.name ? ',' + data?.backupAssignees[0]?.name : ''
      }`,
      ticketOrigin: data.ticketOrigin,
      action: data.action,
      status: data?.ticketStatus,
      createdBy: data?.createBy?.name,
      createdAt: data?.createdAt ? moment(data?.createdAt)?.format('hh:mm A, DD MMM, YYYY') : '-',
      updatedOn: data?.updatedAt ? moment(data?.updatedAt).format('hh:mm A, DD MMM, YYYY') : '-',
      typeOfIssue: data?.natureOfIssue,
      reasonOfIssue: data?.typeOfIssue,
      ticketNo: data?.ticketNo,
      images: data?.images,
    };
  });

  const AllTicketsTableData = {
    columns: AllTicketsCols || [],
    rows: AllTicketsRows || [],
  };

  const AssignedToMeTableData = {
    columns: AssignedToMeCols || [],
    rows: AssignedToMeRows || [],
  };

  const CreatedByMeTableData = {
    columns: createdByMeCols || [],
    rows: createdByMeRows || [],
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const BtnAllTickets = {
    title: 'Create Ticket',
    linkData: [
      {
        menuItem: 'Create Ticket',
        to: '/add-ticket',
      },
    ],
  };

  const BtnAssignedToMe = {
    title: 'Create Ticket',
    linkData: [
      {
        menuItem: 'Create Ticket',
        to: '/add-ticket',
      },
    ],
  };

  const BtnCreatedByMe = {
    title: 'Create Ticket',
    linkData: [
      {
        menuItem: 'Create Ticket',
        to: '/add-ticket',
      },
    ],
  };

  return (
    <React.Fragment>
      <BreadcrumbNavigation breadCrumbData={null} pageTitle={`My Tickets`} backToLink={null} />
      <GridWrapper container>
        <Grid item xs={12}>
          <Box
            className={
              ticketRights?.access === false ? 'background-access main-padding' : 'main-padding'
            }
          >
            <TabContext value={value}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className='tab-list-box tab-panel-box'>
                    <TabList onChange={handleChange} aria-label='lab API tabs example'>
                      <Tab
                        label='All Tickets'
                        value='1'
                        className='tab-head'
                        onClick={() => setType('')}
                      />
                      <Tab
                        label='Assigned to me'
                        value='2'
                        className='tab-head'
                        onClick={() => setType('assignedToMe')}
                      />
                      <Tab
                        label='Created by me'
                        value='3'
                        className='tab-head'
                        onClick={() => setType('createdByMe')}
                      />
                    </TabList>
                  </Box>
                </Grid>
              </Grid>
              <TabPanel value='1' className='tab-panels'>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <TableComponent
                      data={BtnAllTickets}
                      isHideDropDown={true}
                      tableData={AllTicketsTableData}
                      isOptionShow={true}
                      isLoading={isTicketLoading || isTicketFetching}
                      page={page}
                      setPage={setPage}
                      paginationData={ticketListInfo}
                      // listName={'Work Order List'}
                      isNoEntityShow={AllTicketsTableData?.rows?.length <= 0 && ticketSuccess}
                      isSubscription={ticketRights?.access}
                      moduleName={'Ticket'}
                      redirectPage={'/add-ticket'}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value='2' className='tab-panels'>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={12}>
                    <TableComponent
                      data={BtnAssignedToMe}
                      isHideDropDown={true}
                      tableData={AssignedToMeTableData}
                      isOptionShow={true}
                      isLoading={isTicketLoading || isTicketFetching}
                      page={page}
                      setPage={setPage}
                      paginationData={ticketListInfo}
                      listName={'Assigned to me List'}
                      isNoEntityShow={AssignedToMeTableData?.rows?.length <= 0 && ticketSuccess}
                      isSubscription={ticketRights?.access}
                      moduleName={'Ticket'}
                      redirectPage={'/add-ticket'}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value='3' className='tab-panels'>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={12}>
                    <TableComponent
                      data={BtnCreatedByMe}
                      isHideDropDown={true}
                      tableData={CreatedByMeTableData}
                      isOptionShow={true}
                      isLoading={isTicketFetching || isTicketLoading}
                      page={page}
                      setPage={setPage}
                      paginationData={ticketListInfo}
                      listName={'Created by me List'}
                      isNoEntityShow={CreatedByMeTableData?.rows?.length <= 0 && ticketSuccess}
                      isSubscription={ticketRights?.access}
                      moduleName={'Ticket'}
                      redirectPage={'/add-ticket'}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
        {value === '1' && deleteRecord && currentId && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
            handleDelete={deleteTicket}
            id={currentId}
          />
        )}
        {value === '3' && deleteRecord && currentId && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
            handleDelete={deleteTicket}
            id={currentId}
          />
        )}
        {commitOpen && (
          <TicketCommentModal
            open={commitOpen}
            ticketInfo={currentTicketInfo}
            id={currentId}
            handleClose={handleCommitClose}
          />
        )}
      </GridWrapper>
      {ticketRights?.access === false && (
        <Box className='access-top-up'>
          <AccessIcon />
        </Box>
      )}
    </React.Fragment>
  );
};

export default MyTickets;
