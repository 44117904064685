import React from 'react';

const DotsixVector = () => {
  return (
    <React.Fragment>
      <svg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M2.125 1.6875C2.125 2.20523 1.70523 2.625 1.1875 2.625C0.669769 2.625 0.25 2.20523 0.25 1.6875C0.25 1.16977 0.669769 0.75 1.1875 0.75C1.70523 0.75 2.125 1.16977 2.125 1.6875Z'
          fill='#8A8A8A'
        />
        <path
          d='M7.75 1.6875C7.75 2.20523 7.33023 2.625 6.8125 2.625C6.29477 2.625 5.875 2.20523 5.875 1.6875C5.875 1.16977 6.29477 0.75 6.8125 0.75C7.33023 0.75 7.75 1.16977 7.75 1.6875Z'
          fill='#8A8A8A'
        />
        <path
          d='M2.125 7C2.125 7.51773 1.70523 7.9375 1.1875 7.9375C0.669769 7.9375 0.25 7.51773 0.25 7C0.25 6.48227 0.669769 6.0625 1.1875 6.0625C1.70523 6.0625 2.125 6.48227 2.125 7Z'
          fill='#8A8A8A'
        />
        <path
          d='M7.75 7C7.75 7.51773 7.33023 7.9375 6.8125 7.9375C6.29477 7.9375 5.875 7.51773 5.875 7C5.875 6.48227 6.29477 6.0625 6.8125 6.0625C7.33023 6.0625 7.75 6.48227 7.75 7Z'
          fill='#8A8A8A'
        />
        <path
          d='M2.125 12.3125C2.125 12.8302 1.70523 13.25 1.1875 13.25C0.669769 13.25 0.25 12.8302 0.25 12.3125C0.25 11.7948 0.669769 11.375 1.1875 11.375C1.70523 11.375 2.125 11.7948 2.125 12.3125Z'
          fill='#8A8A8A'
        />
        <path
          d='M7.75 12.3125C7.75 12.8302 7.33023 13.25 6.8125 13.25C6.29477 13.25 5.875 12.8302 5.875 12.3125C5.875 11.7948 6.29477 11.375 6.8125 11.375C7.33023 11.375 7.75 11.7948 7.75 12.3125Z'
          fill='#8A8A8A'
        />
      </svg>
    </React.Fragment>
  );
};

export default DotsixVector;
