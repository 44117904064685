import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { partApi } from '../api/part';
import { part } from '.././queryKeys/catalogue';
import { PartErrI } from '../Types/part';

export const useGetPart = (id, isEnable = true) => {
  return useQuery([part.PART, id], partApi.getSinglePart, {
    enabled: isEnable,
    // onSuccess: (res) => {
    //   // const data = res?.data;
    // },
    onError: (err: PartErrI) => {
      return err;
    },
    refetchOnWindowFocus: false,
  });
};
export const useGetDeletedPart = (id, isEnable = true) => {
  return useQuery([part.PART + 'DELETED', id], partApi.getSingleDeletedPart, {
    enabled: isEnable,
    select: (data) => data.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useGetParts = (isEnabled, page) => {
  return useQuery([part.PARTS, page], partApi.getParts, {
    // initialData: { parts: { results: [] } },
    enabled: isEnabled,
    select: (res) => res?.parts,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    //   //toast.success(data.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};
export const useFullGetParts = (isEnabled) => {
  return useQuery([part.PARTS + 'FULL'], partApi.getFullParts, {
    initialData: { parts: [] },
    enabled: isEnabled,
    select: (res) => res?.parts,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    //   //toast.success(data.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};
export const useAddPart = (isRedirect = false) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(partApi.addPart, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([part.PARTS]);
      queryClient.invalidateQueries([part.PARTSFULL]);
      const data = res;
      if (!isRedirect) {
        const materialType = data?.part?.partMaterialType;
        materialType == 'Part'
          ? navigate(`/catalogue`, { state: { activeTab: '1' } })
          : navigate(`/catalogue`, { state: { activeTab: '4' } });
        // navigate(
        //   materialType == 'Part'
        //     ? `/part-details/${data?.part?.id}`
        //     : `/raw-material-details/${data?.part?.id}`
        // );
      }
    },
    onError: (err: PartErrI) => {
      return err;
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};
export const useUpdatePart = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(partApi.updatePart, {
    onSuccess: (res, id) => {
      const data = res;
      queryClient.invalidateQueries([part.PARTS]);
      queryClient.invalidateQueries([part.PART, id]);
      const materialType = data?.part?.partMaterialType;
      // navigate(
      //   materialType == 'Part'
      //     ? `/part-details/${data?.part?.id}`
      //     : `/raw-material-details/${data?.part?.id}`
      // );
      materialType == 'Part'
        ? navigate(`/catalogue`, { state: { activeTab: '1' } })
        : navigate(`/catalogue`, { state: { activeTab: '4' } });
    },
    onError: (err: any) => {
      return err;
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useRestorePart = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(partApi.restorePart, {
    onSuccess: () => {
      queryClient.invalidateQueries([part.PARTS]);
      // queryClient.invalidateQueries([part.PART, id]);
      //toast.success(data.message);
      navigate('/deleted-items');
    },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  });
};

export const useHardDeletePart = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(partApi.hardDeletePart, {
    onSuccess: () => {
      queryClient.invalidateQueries([part.PARTS]);
      // queryClient.invalidateQueries([part.PART, id]);
      navigate('/deleted-items');
    },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  });
};

export const useDeletePart = (isPart = true) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(partApi.deletePart, {
    onSuccess: (res, id) => {
      queryClient.invalidateQueries([part.PARTS]);
      queryClient.removeQueries([part.PART, id]);
      navigate(`/catalogue`, { state: { activeTab: isPart ? '1' : '4' } });
    },
  });
};
