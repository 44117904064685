import React, { useEffect, useRef, useState } from 'react';
// import Highcharts from 'highcharts';
import gantt from 'highcharts/highcharts-gantt';
import HighchartsReact from 'highcharts-react-official';
import Box from '@mui/material/Box';
import Draggable from 'highcharts/modules/draggable-points';
import { useUpdateScheduleWorkorder } from '../../hooks/workorderHooks';
import moment from 'moment';

const now = moment();
const formattedDate = now.format('ddd, MMM D, YYYY h:mm:ss A');

// Initialize the Draggable module
Draggable(gantt);

const GanttChartNew = ({ cardData }: any) => {
  const [draggedTask, setDraggedTask] = useState<any>(null);
  // const [time, setTime] = useState(0);
  const [scheduleData, setScheduleData] = useState({});
  const timeRef = useRef<any>(0);
  const workOrderList = cardData?.scheduleWorkorderList?.map((data) => {
    return {
      start: new Date(data?.start)?.getTime(),
      end: new Date(data?.end)?.getTime(),
      name: data?.name,
      color: data?.color,
      id: data?._id,
    };
  });
  const holidayList = cardData?.holidays?.map((data) => {
    return {
      from: new Date(data?.date)?.getTime(),
      to: new Date(data?.date)?.getTime() + 1000 * 60 * 60 * 24,
      label: {
        text: data?.name, // Label text
        align: 'center', // Label alignment
      },
      id: data?._id,
      color: '#FFCCCB',
      width: 2,
    };
  });
  const { mutate: updateSchedule } = useUpdateScheduleWorkorder();
  const chartOptions = {
    // title: {
    //   text: 'Gantt Chart with Navigation',
    // },
    chart: {
      // height: '420px',
      scrollablePlotArea: {
        minHeight: 720, // Adjust the minHeight as needed
        scrollPositionX: 0, // Start with the scrollbar at the top
      },
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      uniqueNames: true,
      visible: true,
    },
    // plotOptions: {
    //   gantt: {
    //     draggableX: true,
    //     draggableY: true,
    //     dragMinY: 0,
    //     dragMaxY: 2,
    //   },
    // },

    plotOptions: {
      gantt: {
        point: {
          events: {
            dragStart: function (e: any) {
              // Set the currently dragged task
              setDraggedTask(this);
            },
            drop: function (e: any) {
              // updateSchedule({ id: e.target.id, startDate: new Date(e.target.start) });
              // setScheduleData({ id: e.target.id, startDate: new Date(e.target.start) });
              timeRef.current = e?.newPoint?.start;
              // setTime(e?.newPoint?.start);
              if (draggedTask) {
                draggedTask.update({
                  start: e?.newPoint?.start,
                });
              }
            },
          },
        },
      },
    },

    navigator: {
      enabled: true,
      liveRedraw: true,
      series: {
        type: 'gantt',
        pointPlacement: 0.5,
        pointPadding: 0.25,
        accessibility: {
          enabled: false,
        },
      },
      yAxis: {
        min: 0,
        max: 3,
        reversed: true,
        categories: [],
      },
    },

    scrollbar: {
      enabled: true,
      // showFull: false,
    },

    rangeSelector: {
      enabled: false,
      selected: 0,
    },

    accessibility: {
      point: {
        descriptionFormat:
          '{yCategory}. ' +
          '{#if completed}Task {(multiply completed.amount 100):.1f}% completed. {/if}' +
          'Start {x:%Y-%m-%d}*****, end {x2:%Y-%m-%d}.',
      },
      series: {
        descriptionFormat: '{name}',
      },
    },
    lang: {
      accessibility: {
        axis: {
          xAxisDescriptionPlural:
            'The chart has a two-part X axis showing time in both week numbers and days.',
          yAxisDescriptionPlural: 'The chart has one Y axis showing task categories.',
        },
      },
    },
    xAxis: {
      currentDateIndicator: {
        enabled: true,
        label: {
          format: formattedDate, // Use the formatted date with the desired timezone
          useHTML: true,
        },
      },
      type: 'datetime',
      gridLineWidth: 1,
      gridZIndex: 1,
      tickInterval: 1000 * 60 * 60 * 24,
      // Example
      // plotBands: [
      //   {
      //     from: 1613361600000,
      //     to: 1612841640000,
      //     color: 'gray',
      //   },
      // ],
      plotBands: holidayList,
    },

    series: [
      {
        name: 'Project 1',
        dragDrop: {
          liveRedraw: true,
          draggableStart: false,
          draggableEnd: false,
          draggableX: false,
          draggableY: true,
          dragMinY: 0,
          dragMaxY: 2,
        },
        dataLabels: [
          {
            enabled: true,
            format: '{point.name}',
            useHTML: true,
            align: 'center',
            radius: 12,
            background: '{point.color}',
          },
        ],
        data: workOrderList,
        // data: [{
        //   start: Date.UTC(2017, 11, 1),
        //   end: Date.UTC(2018, 1, 2),
        //   completed: {
        //     amount: 0.95
        //   },
        //   name: 'Prototyping'
        // }, {
        //   start: Date.UTC(2018, 1, 2),
        //   end: Date.UTC(2018, 11, 5),
        //   name: 'Development'
        // }, {
        //   start: Date.UTC(2018, 11, 8),
        //   end: Date.UTC(2018, 11, 9),
        //   name: 'Testing'
        // }, {
        //   start: Date.UTC(2018, 11, 9),
        //   end: Date.UTC(2018, 11, 19),
        //   name: 'Development'
        // }, {
        //   start: Date.UTC(2018, 11, 10),
        //   end: Date.UTC(2018, 11, 23),
        //   name: 'Testing'
        // }, {
        //   start: Date.UTC(2018, 11, 25, 8),
        //   end: Date.UTC(2018, 11, 25, 16),
        //   name: 'Release'
        // }]
      },
    ],
  };
  return (
    <React.Fragment>
      <Box mt={3}>
        <HighchartsReact
          constructorType={'ganttChart'}
          highcharts={gantt}
          options={chartOptions}
          allowChartUpdate={true}
        />
      </Box>
    </React.Fragment>
  );
};

export default GanttChartNew;
