import * as React from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Box,
  Divider,
  Grid,
  Typography,
  Select,
  TextField,
  MenuItem,
  FormControl,
} from '@mui/material';
// import { MenuItem, FormControl } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { WeekdaysInput } from 'react-weekdays-input';
import * as _ from 'lodash';
import {
  useGetMobileUser,
  useFullGetMobileUsers,
  useUpdateMobileUser,
} from '../../../hooks/workerManagementHooks';
import { useFullGetLines } from '../../../hooks/lineHooks';
import getTimeData from '../../../Utils/getTimeData';
import Require from '../../commonComponents/Required';
import CustomButton from '../../commonComponents/CustomButton';
import WorkerConfirm from '../../../Modals/WorkerConfirm';
import BreadcrumbNavigation from '../../commonComponents/BreadCrumbNavigation';
import Loading from 'react-fullscreen-loading';
import { useGetFactorySchedule } from '../../../hooks/factoryScheduleHooks';
import { useGetFullDepartments } from '../../../hooks/departmentHooks';
import ErrorPopup from '../../../Modals/ErrorPopup';
import { getEndTime, getStartTime } from '../../../Utils/convertTime';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Worker Name is Required'),
  uniqId: Yup.string().trim().required('Worker Id is Required'),
  department: Yup.string().required('Department is Required'),
  skillLevel: Yup.string().required('Skills is Required'),
  line: Yup.string().required('Line is Required'),
  mobile: Yup.string()
    .matches(/^\d{10}$/, 'Please enter a valid 10-digit phone number.')
    .required('Worker phone number is required.'),
  email: Yup.string().email('Email must be a valid email').required('email is required'),
  shift: Yup.string().required('shift time is Required'),
  startTime: Yup.string().when('shift', {
    is: (shift) => shift,
    then: Yup.string().required('Start time is Required '),
  }),
  endTime: Yup.string().when('shift', {
    is: (shift) => shift,
    then: Yup.string().required('End time is Required '),
  }),
  workingDays: Yup.array()
    .min(1, 'please select at least one day')
    .compact()
    .of(Yup.string().required('Days is Required')),
});

const SelectsNew = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '9.5px 14px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8A',
  },
}));

const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '9.5px 14px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8A',
  },
}));

// class CSS
const BoxWrapper = styled(Box)(() => ({
  paddingBottom: '40px',
  '.title-text': {
    fontSize: '34px',
    fontWeight: '700',
    color: '#265AA8',
    textTransform: 'capitalize',
  },
  '.fieldSubBox': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: 3,
    mb: 1,
  },
  '.SubHeadText': {
    fontSize: '22px',
    fontWeight: '700',
    color: '#265AA8',
  },
  '.checkList-btn': {
    padding: '9px 20px 10px 20px !important',
    borderRadius: '10px !important',
    fontSize: '16px !important',
    fontWeight: '500 !important',
    textTransform: 'capitalize',
    lineHeight: '21px',
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'end',
    // marginTop: '20px',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
    position: 'absolute',
    bottom: '1px',
    left: '0px',
    width: '100%',
  },
  '.remove': {
    display: 'flex',
    marginTop: '9px',
    cursor: 'pointer',
    color: '#265AA8',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.mr-14': {
    marginRight: '14px',
  },
  '.email-text': {
    marginTop: '13px',
    fontSize: '12px',
    color: '#122E54',
  },
  '.field-title': {
    // marginBottom: '8px',
    fontSize: '14px',
    fontWeight: 400,
  },
  '.footer-padding': {
    padding: '16px 56px 16px 20px',
  },
}));

const dayList = {
  0: 'Monday',
  1: 'Tuesday',
  2: 'Wednesday',
  3: 'Thursday',
  4: 'Friday',
  5: 'Saturday',
  6: 'Sunday',
};
const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '1px 14px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));
interface mobileUserI {
  id: string;
  name: string;
  isCustom: boolean;
  department: {
    name: string;
    id: string;
  };
  line: {
    name: string;
    id: string;
  };
  shift: {
    _id: string;
    name: string;
  };
  uniqId: string;
  mobile: string;
  email: string;
  startTime: string;
  endTime: string;
  skillLevel: string;
  workingDays: [string];
}
const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
      // width: 250,
    },
  },
};
const WorkerCompletion = () => {
  const [isEdit, setIsEdit] = React.useState(false);
  const { id } = useParams();
  const [startTimeData, setStartTimeData] = React.useState<
    (string | { label: string; value: string })[] | []
  >([]);
  const [endTimeData, setendTimeData] = React.useState<
    (string | { label: string; value: string })[] | []
  >([]);
  const [daysAsObject, setDaysAsObject] = React.useState([0, 0, 0, 0, 0, 0, 0]);
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState<boolean>(false);
  const handleOpen = () => setConfirmationModalOpen(true);
  const handleClose = () => setConfirmationModalOpen(false);
  const [value, setValue] = React.useState({});
  const [isLoadDays, setIsLoadDays] = React.useState(false);
  const [isShowDays, setIsShowDays] = React.useState(false);

  const { data: mobileUser, isSuccess } = useGetMobileUser(id, !!id);
  // const { data: getDepartments } = useGetDepartments(true, 1);
  const { data: getDepartments } = useGetFullDepartments();

  const singleMobileUser: mobileUserI = mobileUser?.user;

  //Get Factory Schedule
  const { data: getFactorySchedule } = useGetFactorySchedule();

  const icon = <AiOutlineInfoCircle color='red' />;

  React.useEffect(() => {
    const { startTime } = getTimeData();
    setStartTimeData(startTime);
    if (id) {
      setIsEdit(true);
      const workingDays = singleMobileUser?.workingDays;
      const workingDaysArray = Object.values(dayList)?.map((day) => {
        if (workingDays?.includes(day)) {
          return 1;
        } else {
          return 0;
        }
      });
      setDaysAsObject(workingDaysArray);
      setIsLoadDays(false);
      retrieveEndTime(singleMobileUser?.startTime);
    }
  }, [id]);

  const retrieveEndTime = (val) => {
    const { endTime } = getTimeData(val);
    setendTimeData(endTime);
  };
  const params = { isVerified: true };
  const { data: workerData } = useFullGetMobileUsers(true, params);
  const workerVerifiedList = workerData?.filter(
    (data) => data?.isVerified == true && data?.isCompletion == false
  );
  const workerList = workerVerifiedList?.map((worker) => {
    return {
      ...worker,
    };
  });
  //Get Lines

  // const { data: getLines } = useGetLines(true, 1);
  const { data: getLines } = useFullGetLines(true);

  const lines = getLines?.map((data) => {
    return {
      name: data?.name,
      id: data?.id,
    };
  });

  const breadCrumbData = [
    {
      title: 'resources',
      link: '/resources',
      activeTab: '2',
    },
    {
      title: `${isEdit ? `Edit ${singleMobileUser?.name}` : 'Add New user'}`,
      link: ``,
    },
  ];
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const {
    mutate: updateUser,
    isError: isUpdateMobileUserError,
    error: updateMobileUserError,
  } = useUpdateMobileUser(true);
  React.useEffect(() => {
    if (isUpdateMobileUserError) {
      isUpdateMobileUserError ? setErrorMessage(updateMobileUserError?.data?.message) : '';
      setIsErrorModalOpen(true);
      // handleClose();
    }
  }, [isUpdateMobileUserError]);
  const saveAsDraft = (values) => {
    const workingDays = values.workingDays.map((day, ind) => {
      return day ? dayList[ind] : null;
    });
    const workerId = workerList?.find((data) => data.id === values?.name)?.id;
    const userPayload = {
      department: values?.department,
      line: values?.line,
      skillLevel: values?.skillLevel,
      ...(values?.shift === 'custom'
        ? { isCustom: true }
        : { isCustom: false, shift: values?.shift }),
      startTime: values?.startTime,
      endTime: values?.endTime,
      isDraft: true,
      mobile: values?.mobile,
      uniqId: values?.uniqId,
      isVerified: values?.isVerified,
      isCompletion: false,
      ...(values?.email && { email: values?.email }),
      workingDays: _.compact(workingDays),
    };
    updateUser({ ...userPayload, id: workerId });
  };
  return (
    <>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={`${isEdit ? `Edit ${singleMobileUser?.name}` : 'Add mobile user'}`}
        backToLink='/resources'
        activeTab='2'
      />
      <BoxWrapper className='main-component-padding'>
        <Formik
          initialValues={{
            // do not need to map values within items because FieldArray
            name: isEdit ? singleMobileUser?.id : '',
            //workerId: '',
            department: isEdit ? singleMobileUser?.department?.id : '',
            line: isEdit ? singleMobileUser?.line?.id : '',
            uniqId: isEdit ? singleMobileUser?.uniqId : '',
            mobile: isEdit ? singleMobileUser?.mobile : '',
            email: isEdit ? singleMobileUser?.email : '',
            skillLevel: isEdit ? singleMobileUser?.skillLevel : '',
            shift: isEdit
              ? singleMobileUser?.isCustom
                ? 'custom'
                : singleMobileUser?.shift?._id
              : '',
            startTime: isEdit ? singleMobileUser?.startTime : '',
            endTime: isEdit ? singleMobileUser?.endTime : '',
            workingDays: isEdit ? (singleMobileUser?.workingDays?.length ? daysAsObject : []) : [],
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const workingDays = values.workingDays.map((day, ind) => {
              return day ? dayList[ind] : null;
            });
            const line = lines?.find((lineData) => lineData.id === values.line);
            const department = getDepartments?.find(
              (departmentData) => departmentData.id === values.department
            );
            const workerName = workerList?.find((data) => data.id === values?.name);
            const updateValue = {
              ...values,
              workingDays: _.compact(workingDays),
              lineName: line?.name,
              departmentName: department?.name,
              isCompletion: true,
              workerName: workerName?.name,
            };
            setValue({ ...updateValue, isDraft: false });
            handleOpen();
          }}
        >
          {({ values, touched, errors, handleChange, isValid, dirty, setFieldValue }) => (
            <Form noValidate autoComplete='off'>
              <>
                <Typography className='title-typography'>Enter Basic Details</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className='field-title'>
                      Name
                      <Require />
                    </Typography>
                    <FormControl fullWidth>
                      <Selects
                        error={!errors.name || !touched.name ? false : true}
                        onChange={(e) => {
                          const getValues = workerList?.find((data) => data?.id === e.target.value);
                          handleChange(e);
                          setFieldValue('uniqId', getValues?.uniqId);
                          setFieldValue('department', getValues?.department);
                          setFieldValue('line', getValues?.line);
                          setFieldValue('skillLevel', getValues?.skillLevel);
                          setFieldValue('mobile', getValues?.mobile);
                          setFieldValue('email', getValues?.email);
                          setFieldValue('isCustom', getValues?.isCustom);
                          if (getValues?.isCustom === true) {
                            setFieldValue('shift', 'custom');
                          } else {
                            setFieldValue('shift', getValues?.shift);
                          }
                          setFieldValue('startTime', getValues?.startTime);
                          setFieldValue('endTime', getValues?.endTime);
                          retrieveEndTime(e.target.value);
                          const workingDraftDaysArray = Object.values(dayList)?.map((day) => {
                            if (getValues?.workingDays?.includes(day)) {
                              return 1;
                            } else {
                              return 0;
                            }
                          });
                          setDaysAsObject(workingDraftDaysArray);
                          setTimeout(() => {
                            setIsShowDays(true);
                          }, 0);
                          setFieldValue('workingDays', workingDraftDaysArray);
                        }}
                        name='name'
                        value={values.name || ''}
                        variant='outlined'
                        displayEmpty
                      >
                        <MenuItem value='' key={100000} disabled>
                          <span className='menu-item-span'>Select Worker</span>
                        </MenuItem>
                        {workerList?.map((worker, ind) => {
                          return (
                            <MenuItem key={ind} value={worker.id}>
                              {worker.name}
                            </MenuItem>
                          );
                        })}
                      </Selects>

                      {touched.name && errors.name && (
                        <Typography className='validation-text'>{errors.name}</Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className='field-title'>
                      Id
                      <Require />
                    </Typography>

                    <Input
                      error={!errors.uniqId || !touched.uniqId ? false : true}
                      placeholder='Enter Id'
                      fullWidth
                      onChange={handleChange}
                      name='uniqId'
                      value={values.uniqId || ''}
                      InputProps={{
                        endAdornment: touched.uniqId && errors.uniqId && icon,
                      }}
                    />
                    {touched.uniqId && errors.uniqId && (
                      <Typography className='validation-text'>{errors.uniqId}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className='field-title'>
                      Department
                      <Require />
                    </Typography>

                    <FormControl fullWidth>
                      <Selects
                        error={!errors.department || !touched.department ? false : true}
                        onChange={handleChange}
                        name='department'
                        value={values.department || ''}
                        variant='outlined'
                        displayEmpty
                        MenuProps={MenuProps}
                      >
                        <MenuItem value='' key={100000} disabled>
                          <span className='menu-item-span'>Select Department</span>
                        </MenuItem>
                        {getDepartments?.map((department, ind) => {
                          return (
                            <MenuItem key={ind} value={department?.id}>
                              {department?.name}
                            </MenuItem>
                          );
                        })}
                      </Selects>

                      {touched.department && errors.department && (
                        <Typography className='validation-text'>{errors.department}</Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className='field-title' mt={'2px'}>
                      Line
                      <Require />
                    </Typography>
                    <FormControl fullWidth>
                      <Selects
                        error={!errors.line || !touched.line ? false : true}
                        onChange={handleChange}
                        name='line'
                        value={values.line || ''}
                        variant='outlined'
                        displayEmpty
                        MenuProps={MenuProps}
                      >
                        <MenuItem value='' key={100000} disabled>
                          <span className='menu-item-span'>Select Line</span>
                        </MenuItem>
                        {lines?.map((line, ind) => {
                          return (
                            <MenuItem key={ind} value={line?.id}>
                              {line?.name}
                            </MenuItem>
                          );
                        })}
                      </Selects>

                      {touched.line && errors.line && (
                        <Typography className='validation-text'>{errors.line}</Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className='field-title' mt={'2px'}>
                      Skill Level
                      <Require />
                    </Typography>
                    <FormControl fullWidth>
                      <Selects
                        error={!errors.skillLevel || !touched.skillLevel ? false : true}
                        onChange={handleChange}
                        name='skillLevel'
                        value={values.skillLevel || ''}
                        variant='outlined'
                        displayEmpty
                      >
                        <MenuItem value='' disabled>
                          <span className='menu-item-span'>Select the skill level</span>
                        </MenuItem>
                        <MenuItem value='Level 1: Low Competency'>Level 1: Low Competency</MenuItem>
                        <MenuItem value='Level 2: Medium Competency'>
                          Level 2: Medium Competency
                        </MenuItem>
                        <MenuItem value='Level 3: High Competency'>
                          Level 3: High Competency
                        </MenuItem>
                      </Selects>
                      {touched.skillLevel && errors.skillLevel && (
                        <Typography className='validation-text'>{errors.skillLevel}</Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className='field-title'>
                      Mobile Number
                      <Require />
                    </Typography>

                    <Input
                      error={!errors.mobile || !touched.mobile ? false : true}
                      placeholder='Enter Phone Number'
                      fullWidth
                      onChange={handleChange}
                      name='mobile'
                      value={values.mobile || ''}
                      InputProps={{
                        endAdornment: touched.mobile && errors.mobile && icon,
                      }}
                    />
                    {touched.mobile && errors.mobile && (
                      <Typography className='validation-text'>{errors.mobile}</Typography>
                    )}
                  </Grid>
                </Grid>
                <Divider />
                <Typography className='title-typography'> User Email Id</Typography>
                <Grid container spacing={2} mb={'1%'}>
                  <Grid item xs={4}>
                    <Typography className='field-title'>
                      Email id for login <Require />
                    </Typography>

                    <Input
                      error={!errors.email || !touched.email ? false : true}
                      placeholder='Enter Email Id'
                      fullWidth
                      value={values.email || ''}
                      onChange={handleChange}
                      name='email'
                      InputProps={{
                        endAdornment: touched.email && errors.email && icon,
                      }}
                    />
                    {touched.email && errors.email && (
                      <Typography className='validation-text'>{errors.email}</Typography>
                    )}
                    {values.email && (
                      <Typography className='email-text'>
                        Email will be sent to the user with the login credentials.
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Divider />
                <Typography className='title-typography'>Factory Timing</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Box>
                      <Typography className='field-title' mt={'2px'}>
                        Select Shift
                        <Require />
                      </Typography>

                      <FormControl fullWidth>
                        <Selects
                          onChange={(e) => {
                            const findTime = getFactorySchedule?.shifts?.find(
                              (data) => data._id == e.target.value
                            );
                            setFieldValue('startTime', findTime?.startTime);
                            setFieldValue('endTime', findTime?.endTime);
                            setFieldValue('shift', e.target.value);
                            retrieveEndTime(e.target.value);
                          }}
                          name='shift'
                          value={values?.shift || ''}
                          variant='outlined'
                          displayEmpty
                          MenuProps={MenuProps}
                        >
                          <MenuItem value='' key={100000} disabled>
                            <span className='menu-item-span'>Select Shift Time</span>
                          </MenuItem>
                          {getFactorySchedule?.shifts?.map((shift, ind) => {
                            return (
                              <MenuItem key={ind} value={shift._id}>
                                {/* {shift.name}({shift.startTime}-{shift.endTime}) */}
                                {shift.name} ({getStartTime(shift.startTime)}-
                                {getEndTime(shift.endTime)})
                              </MenuItem>
                            );
                          })}
                          <MenuItem value='custom'>Custom</MenuItem>
                        </Selects>

                        {touched.shift && errors.shift && (
                          <Typography className='validation-text'>{errors.shift}</Typography>
                        )}
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={'2px'} mb={'20px'}>
                  {values?.shift === 'custom' && (
                    <>
                      <Grid item xs={4}>
                        <Box>
                          <Typography className='field-title'>
                            Start Time
                            <Require />
                          </Typography>
                          <FormControl fullWidth>
                            <SelectsNew
                              labelId='demo-simple-select-label'
                              id='demo-simple-select'
                              value={values.startTime || ''}
                              name='startTime'
                              onChange={(e) => {
                                handleChange(e);
                                retrieveEndTime(e.target.value);
                              }}
                              MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                              displayEmpty
                            >
                              <MenuItem value='' disabled>
                                <span className='menu-item-span'>Select start time</span>
                              </MenuItem>
                              {startTimeData.map((time, ind) => {
                                return (
                                  <MenuItem value={time?.value} key={ind}>
                                    {time?.label}
                                  </MenuItem>
                                );
                              })}
                            </SelectsNew>
                          </FormControl>
                          <Typography className='validation-text'>
                            {touched.shift && errors.startTime && (
                              <Typography className='validation-text'>
                                {errors.startTime}
                              </Typography>
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box>
                          <Typography className='field-title'>
                            End Time
                            <Require />
                          </Typography>
                          <FormControl fullWidth>
                            <SelectsNew
                              labelId='demo-simple-select-label'
                              id='demo-simple-select'
                              name='endTime'
                              value={values.endTime || ''}
                              onChange={handleChange}
                              MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                              displayEmpty
                            >
                              <MenuItem value='' disabled>
                                <span className='menu-item-span'>Select end time</span>
                              </MenuItem>
                              {endTimeData.map((time, ind) => {
                                return (
                                  <MenuItem value={time?.value} key={ind}>
                                    {time?.label}
                                  </MenuItem>
                                );
                              })}
                            </SelectsNew>
                          </FormControl>
                          <Typography className='validation-text'>
                            {touched.shift && errors.endTime && (
                              <Typography className='validation-text'>{errors.endTime}</Typography>
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4} sx={{ minHeight: '175px' }}>
                    <Typography className='field-title'>
                      Working Days
                      <Require />
                    </Typography>
                    {isShowDays && (
                      <Box>
                        <WeekdaysInput
                          value={daysAsObject}
                          onChange={(value) => {
                            setDaysAsObject(value);
                            setFieldValue('workingDays', value);
                          }}
                          days={[
                            'monday',
                            'tuesday',
                            'wednesday',
                            'thursday',
                            'friday',
                            'saturday',
                            'sunday',
                          ]}
                          name='workingDays'
                          showChars={1}
                          activeDayStyle={{
                            color: 'blue',
                            fontSize: '16px',
                            backgroundColor: '#F6F6F6',
                            borderRadius: '4px 4px 4px 4px',
                            fontWeight: 700,
                          }}
                          inactiveDayStyle={{
                            color: 'gray',
                            backgroundColor: '#FFFFFF',
                            borderRadius: '4px 4px 4px 4px',
                          }}
                          dayStyle={{
                            padding: '9px 0px 9px 0px',
                            width: '50px',
                            textAlign: 'center',
                          }}
                          inputStyle={{
                            border: '1px solid #8A8A8A',
                            borderRadius: '4px',
                          }}
                          forcedState={{
                            0: 'none',
                            1: 'none',
                            2: 'none',
                            3: 'none',
                            4: 'none',
                            5: 'none',
                            6: 'none',
                          }}
                          textCase={'firstToUpper'}
                        ></WeekdaysInput>
                        {touched.workingDays && errors.workingDays && (
                          <Typography className='validation-text'>{errors.workingDays}</Typography>
                        )}
                      </Box>
                    )}
                    {/* Used in future */}
                    {/* {!isLoadDays && (
                    <Box>
                      <WeekdaysInput
                        value={daysAsObject}
                        onChange={(value) => {
                          setDaysAsObject(value);
                          setFieldValue('workingDays', value);
                        }}
                        days={[
                          'monday',
                          'tuesday',
                          'wednesday',
                          'thursday',
                          'friday',
                          'saturday',
                          'sunday',
                        ]}
                        name='workingDays'
                        showChars={1}
                        activeDayStyle={{
                          color: 'blue',
                          fontSize: '16px',
                        }}
                        inactiveDayStyle={{
                          backgroundColor: '#cececec',
                          color: 'gray',
                        }}
                        dayStyle={{
                          padding: '9px 15px 9px 15px',
                        }}
                        inputStyle={{
                          border: '1px solid #D2D1D1',
                          borderRadius: '4px',
                        }}
                        forcedState={{
                          0: 'none',
                          1: 'none',
                          2: 'none',
                          3: 'none',
                          4: 'none',
                          5: 'none',
                          6: 'none',
                        }}
                        textCase={'firstToUpper'}
                      ></WeekdaysInput>
                      {touched.workingDays && errors.workingDays && (
                        <Typography className='validation-text'>{errors.workingDays}</Typography>
                      )}
                    </Box>
                  )} */}
                  </Grid>
                </Grid>
              </>
              <Box className='button-box'>
                <Box className='footer-padding'>
                  <CustomButton
                    className={'mr-14'}
                    variant='outlined'
                    onClick={() => saveAsDraft(values)}
                  >
                    Save As Draft
                  </CustomButton>
                  <CustomButton
                    type='submit'
                    className={!id && !(isValid && dirty) ? 'disable-btn' : 'blue-bg'}
                    variant='contained'
                  >
                    Save Worker
                  </CustomButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        <Loading
          loading={id && !isSuccess ? true : false}
          background='white'
          loaderColor='#265AA8'
        />
        {confirmationModalOpen && (
          <WorkerConfirm workerData={value} onClose={handleClose} isOpen={confirmationModalOpen} />
        )}
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
      </BoxWrapper>
    </>
  );
};

export default WorkerCompletion;
