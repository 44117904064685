import { searchApi } from '../api/search';
import { useQuery } from '@tanstack/react-query';
import { searchOptions } from '../queryKeys/catalogue';

export const useSearchData = (isEnabled) => {
  return useQuery([searchOptions.SEARCH], searchApi.searchOptions, {
    enabled: isEnabled,
    keepPreviousData: true,
    select: (data) => data?.data?.searchAllList,
    // onSuccess: (res) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};
