import React, { useState } from 'react';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Box, styled } from '@mui/material';

const BoxWrapper = styled(Box)(() => ({
  '.date-input': {
    width: '192px',
    height: '32px',
    border: '1px solid #D2D1D1',
    borderRadius: '10px',
    textAlign: 'center',
  },
}));

const DateRange = (props: any) => {
  const { dates, setDates } = props;

  const ranges = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    Tomorrow: [moment().add(1, 'days'), moment().add(1, 'days')],
    'Last Week': [
      moment().subtract(1, 'week').startOf('week'),
      moment().subtract(1, 'week').endOf('week'),
    ],
    'This Week': [moment().startOf('week'), moment().endOf('week')],
    'Next Week': [moment().add(1, 'week').startOf('week'), moment().add(1, 'week').endOf('week')],
    'Current Month': [moment().startOf('month'), moment().endOf('month')],
    // 'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    // 'This Month': [moment().startOf('month'), moment().endOf('month')],
    // 'Last Month': [
    //   moment().subtract(1, 'month').startOf('month'),
    //   moment().subtract(1, 'month').endOf('month'),
    // ],
  };
  return (
    <BoxWrapper>
      <DateRangePicker
        onApply={setDates}
        initialSettings={{
          startDate: dates?.startDate ? new Date(dates?.startDate) : new Date(),
          endDate: dates?.endDate ? new Date(dates?.endDate) : new Date(),
          ranges: ranges,
          formate: 'YYYY/MM/DD',
        }}
      >
        <input
          type='text'
          // value={dates?.startDate + '-' + dates?.endDate}
          defaultValue={dates?.startDate + '-' + dates?.endDate}
          className='date-input form-control'
          placeholder='enter your date'
        />
      </DateRangePicker>
    </BoxWrapper>
  );
};
export default DateRange;
