import React from 'react';
import { AppBar, Box, styled, Typography } from '@mui/material';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import SearchComponent from './SearchComponent';
import { cookie } from '../Utils/Cookies/cookie';
import { useQueryClient } from '@tanstack/react-query';
import { user } from '../queryKeys/userManagement';
import UserInfo from './UserInfo';

// const Header = ({ Component }: ReactComponent) => {
interface authI {
  isAuthenticated: boolean;
}
const Header = (props: authI) => {
  const { isAuthenticated } = props;
  // let isAuthenticated = false;

  const location = useLocation();
  // const token = cookie.get('token');
  const queryClient = useQueryClient();
  const userData: any = queryClient.getQueryData([user.LOGGED_IN_USER]);

  // if (token) {
  //   const { data: UserDetails, isLoading, status } = useVerifyToken();
  // }
  // // if (isLoading === false && UserDetails?.data.user) {
  // if (token) {
  //   isAuthenticated = true;
  // }
  const isHideHeader =
    location.pathname.startsWith('/work-order/add-procedure/') ||
    location.pathname.startsWith('/work-order/edit-procedure/') ||
    location.pathname.startsWith('/work-order/view-procedure/');
  const dashboard = location.pathname.startsWith('/dashboard');
  const HeaderWrapper = styled(Box)(() => ({
    // marginLeft: '13%',
    marginLeft: '264px',
    //old Width
    // width:'100%',
    // Add Width after Add syncfusion Gantt chart
    width: dashboard ? '86%' : '100%',
    position: 'sticky',
    //padding: '0px 39px 0px 40px',
    background: '#FCFDFF',
    '.app-bar': {
      //paddingTop: '43px',
      padding: '43px 39px 0px 40px',
      background: '#FCFDFF',
      zIndex: -10,
      // zIndex: 1,
    },
    '.main-box': {
      // padding: isHideHeader ? '0px' : '0px 56px 2px 56px',
      // padding: isHideHeader ? '0px' : '0px 39px 2px 40px',
    },
    '.search-box': {
      boxShadow: '0px 2px 10px rgba(40, 40, 40, 0.1)',
      height: '50px',
      color: 'gray',
      width: '400px',
      '.css-i4bv87-MuiSvgIcon-root': {},
      borderRadius: '10px',
      paddingLeft: '10px',
    },
    '.search-icon': {
      color: '#265AA8',
    },
    '.notification-icon': {
      cursor: 'pointer',
      height: '55px',
      width: '55px',
      background: '#fff',
      color: '#265AA8',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '30px',
      margin: '0px 11px 0px 0px',
      boxShadow: '0px 2px 10px rgba(40, 40, 40, 0.1)',
    },
    '.avatar-box': {
      // height: '40px',
      width: '210px',
      background: '#fff',
      color: 'blue',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '10px',
      boxShadow: '0px 2px 10px rgba(40, 40, 40, 0.1)',
      padding: '2px 0px 2px 5px',
      cursor: 'pointer',
    },
    // Add media Width after Add syncfusion Gantt chart
    '@media (min-width: 1025px) and (max-width: 1440px)': {
      width: dashboard ? '82%' : '100%',
    },
    '@media (min-width: 0px) and (max-width: 1024px)': {
      width: dashboard ? '74.4%' : '100%',
    },
  }));

  return (
    <React.Fragment>
      <HeaderWrapper>
        {/* {!isHideHeader && (
          <AppBar position='sticky' color='transparent' elevation={0} className='app-bar'>
            <Box display={'flex'} justifyContent='space-between' pb={'37px'}>
              <SearchComponent />
              <UserInfo userData={userData} />
            </Box>
          </AppBar>
        )} */}
        <Box className='main-box'>{isAuthenticated ? <Outlet /> : <Navigate to={'/login'} />}</Box>
      </HeaderWrapper>
    </React.Fragment>
  );
};
export default Header;
