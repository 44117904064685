import { useMutation } from '@tanstack/react-query';
import { uploadApi } from '../api/uploadFile';

export const useUploadFile = () => {
  return useMutation(uploadApi.addFile, {
    onSuccess: () => {
      // const data = res.data;
      //   toast.success(data?.message);
    },
    onError: (err) => {
      return err;
      // const errData = err?.data;
      //   toast.error(errData.message);
    },
  });
};
