import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { workerManagementApi } from '../api/workerManagment';
import { user } from '../queryKeys/userManagement';
import { ErrorI } from '../Types/Error';

export const useGetMobileUser = (id, isEnable = true) => {
  return useQuery([user.MOBILE_USER, id], workerManagementApi.getSingleMobileUser, {
    select: (data) => data.data,
    enabled: isEnable,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useGetMobileUsers = (isEnabled: boolean, page) => {
  return useQuery([user.MOBILE_USERS, page], workerManagementApi.getMobileUser, {
    enabled: isEnabled,
    select: (data) => data?.data?.users,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    //   //toast.success(data.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useFullGetMobileUsers = (isEnabled: boolean, params) => {
  return useQuery([user.MOBILE_USERS + 'FULL', params], workerManagementApi.getFullMobileUser, {
    enabled: isEnabled,
    select: (data) => data?.data?.users,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    //   //toast.success(data.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useAddMobileUser = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(workerManagementApi.addMobileUser, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([user.MOBILE_USERS]);
      const data = res.data;
      // toast.success(data.message);
      // navigate(`/mobile-user-details/${data?.user?.id}`);
      navigate(`/user-management`, { state: { activeTab: '2' } });
    },
    onError: (err: ErrorI) => {
      return err;
      // const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useUpdateMobileUser = (isRedirectedFromWorker = false) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(workerManagementApi.updateMobileUser, {
    onSuccess: (res) => {
      const data = res.data;
      queryClient.invalidateQueries([user.MOBILE_USERS]);
      queryClient.invalidateQueries([user.MOBILE_USER, data?.user?.id]);
      // toast.success(data.message);
      if (isRedirectedFromWorker) {
        // navigate(`/worker-detail/${data?.user?.id}`);
        navigate(`/resources`, { state: { activeTab: '2' } });
      } else {
        // navigate(`/mobile-user-details/${data?.user?.id}`);
        navigate(`/user-management`, { state: { activeTab: '2' } });
      }
      // navigate(`/mobile-user-details/${data?.user?.id}`);
    },
    onError: (err: any) => {
      return err;
      // toast.error(errData.message);
    },
  });
};

export const useDeleteMobileUser = (isRedirectToResources?: boolean) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(workerManagementApi.deleteMobileUser, {
    onSuccess: (res) => {
      queryClient.refetchQueries([user.MOBILE_USERS]);
      const data = res.data;
      queryClient.removeQueries([user.MOBILE_USER, data?.user?.id]);
      queryClient.invalidateQueries([user.MOBILE_USERS]);
      // toast.success(data.message);
      if (isRedirectToResources) {
        navigate('/resources', { state: { activeTab: '2' } });
      } else {
        navigate(`/user-management`, { state: { activeTab: '2' } });
      }
    },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
  });
};
export const useGetDeletedMobileUser = (id, isEnable = true) => {
  return useQuery(
    [user.MOBILE_USER + 'DELETED', id],
    workerManagementApi.getSingleDeletedMobileUser,
    {
      enabled: isEnable,
      select: (data) => data.data,
      // onSuccess: (res: any) => {
      //   const data = res?.data;
      // },
      // onError: (err: any) => {
      //   const errData = err?.data;
      //   //toast.error(errData.message);
      // },
      refetchOnWindowFocus: false,
    }
  );
};
export const useRestoreMobileUser = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(workerManagementApi.restoreMobileUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([user.MOBILE_USERS]);
      // queryClient.invalidateQueries([part.PART, id]);
      //toast.success(data.message);
      navigate('/deleted-items');
    },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  });
};

export const useHardDeleteMobileUser = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(workerManagementApi.hardDeleteMobileUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([user.MOBILE_USERS]);
      // queryClient.invalidateQueries([part.PART, id]);
      navigate('/deleted-items');
    },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  });
};
