import React from 'react';
import Highcharts from 'highcharts/highstock';
import PieChart from 'highcharts-react-official';
import { Box, Select, MenuItem, styled } from '@mui/material';
import { useFullGetMobileUsers } from '../../hooks/workerManagementHooks';
import { useGetFullMachines } from '../../hooks/machineHooks';
const Selects = styled(Select)(() => ({
  borderRadius: '10px !important',
  width: '160px',
  '.MuiOutlinedInput-input': {
    padding: '5.5px 14px',
  },
}));
interface PropI {
  allValue?: string;
  productive?: any;
  nonProductive?: any;
  setMachine?: any;
  machine?: any;
  worker?: any;
  setWorker?: any;
}
const PieChartData = (props: PropI) => {
  const { allValue, productive, nonProductive, setMachine, machine, worker, setWorker } = props;

  const options = {
    chart: {
      type: 'pie',
      height: 350,
      border: '1px solid blue',
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },

    legend: {
      enabled: true,
      align: 'center',
      itemStyle: {
        color: '#000000',
      },
    },
    tooltip: {
      headerFormat: '<span style="color:#22232E"></span><br>',
      pointFormat:
        '<span style="color:#22232E">{point.name}<br/> Value: </span><b style="color:#22232E">{point.percentage:.1f} %</b> <br/>',
    },
    // tooltip: {
    //   useHTML: true,
    //   backgroundColor: '#ffffff',
    //   width: '50px',
    // },

    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        size: 133,
        dataLabels: {
          distance: 10,
          enabled: true,
          format: '{point.percentage:.1f} %',
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: '',
        color: '#006600',
        lineWidth: 1,
        marker: {
          enabled: false,
          symbol: 'circle',
          //radius: 3,
          states: {
            hover: {
              enabled: true,
              lineWidth: 1,
            },
          },
        },
        data: [
          {
            name: 'Productive',
            y: productive,
            color: 'rgba(171, 230, 255, 1)',
          },
          {
            name: 'non-productive',
            y: nonProductive,
            color: 'rgba(255, 106, 106, 1)',
          },
        ],
      },
    ],
  };

  // const options = {
  //   title: {
  //     text: '',
  //   },
  //   chart: {
  //     plotBackgroundColor: null,
  //     plotBorderWidth: 0,
  //     plotShadow: false,
  //     // height: 175,
  //     // width: 175,
  //     // margin: 0,
  //     // backgroundColor: 'rgba(0,0,0,0)',
  //   },
  //   credits: {
  //     enabled: false,
  //   },
  //   subtitle: {
  //     text: `<div style='font-size: 80px'>90%</div>`,
  //     align: 'center',
  //     verticalAlign: 'middle',
  //     style: {
  //       textAlign: 'center',
  //     },
  //     x: 0,
  //     y: -2,
  //     useHTML: true,
  //   },
  //   plotOptions: {
  //     size: '100%',
  //     pie: {
  //       innerSize: 100,
  //       depth: 15,
  //       dataLabels: {
  //         connectorWidth: 0,
  //       },
  //     },
  //   },
  //   series: [
  //     {
  //       type: 'pie',
  //       enableMouseTracking: false,
  //       innerSize: '80%',
  //       backgroundColor: ['green'],
  //       dataLabels: {
  //         enabled: false,
  //       },
  //       data: [
  //         {
  //           y: 70,
  //           color: 'blue',
  //         },
  //         {
  //           y: 30,
  //           color: '#fff',
  //         },
  //       ],
  //     },
  //   ],
  // };
  const params = { isVerified: true, isCompletion: true };
  const { data: getAllUsers } = useFullGetMobileUsers(true, params);
  const { data: machineData } = useGetFullMachines();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 'auto',
      },
    },
  };
  return (
    <Box>
      <PieChart highcharts={Highcharts} options={options} />
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        {allValue === 'All Workers' ? (
          <>
            <Selects
              value={worker}
              onChange={(e) => setWorker(e.target.value)}
              MenuProps={MenuProps}
            >
              <MenuItem value='All'>All Workers</MenuItem>
              {getAllUsers?.map((data, ind) => {
                return (
                  <MenuItem value={data?.id} key={ind}>
                    {data?.name}
                  </MenuItem>
                );
              })}
            </Selects>
          </>
        ) : (
          <Selects
            value={machine}
            onChange={(e) => setMachine(e.target.value)}
            MenuProps={MenuProps}
          >
            <MenuItem value='All'>All Machine</MenuItem>
            {machineData?.map((data, ind) => {
              return (
                <MenuItem value={data?.id} key={ind}>
                  {data?.name}
                </MenuItem>
              );
            })}
          </Selects>
        )}
      </Box>
    </Box>
  );
};

export default PieChartData;
