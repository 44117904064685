import { get, post, patch, deleteRequest } from '../Utils/index';

export const addProduct = async (data) => {
  return post(`/customer/product`, data);
};
export const getSingleProduct = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/product/${id}`);
};
export const getSingleDeletedProduct = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/product/deleted/${id}`);
};
export const updateProduct = async (data) => {
  // const id = data?.queryKey[1];
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/product/${id}`, data);
};
export const deleteProduct = async (id) => {
  return deleteRequest(`/customer/product/${id}`);
};
export const getProducts = async (data) => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10 };
  return get(`/customer/product`, payload);
};
export const getFullProducts = async () => {
  return get(`/customer/product/full-list`);
};
export const restoreProduct = async (id) => {
  // const id = data?.queryKey[1];
  // const id = data?.id;
  // delete data['id'];
  return patch(`/customer/product/restore/${id}`);
};
export const hardDeleteProduct = async (id) => {
  // const id = data?.queryKey[1];
  // const id = data?.id;
  // delete data['id'];
  return deleteRequest(`/customer/product/hard-delete/${id}`);
};
export const productApi = {
  addProduct,
  getSingleProduct,
  updateProduct,
  deleteProduct,
  getProducts,
  getFullProducts,
  restoreProduct,
  hardDeleteProduct,
  getSingleDeletedProduct,
};
