//this is use for mobile
import { deleteRequest, get, patch, post } from '../Utils/index';

export const getSingleMobileUser = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/mobile-users/${id}`);
};

export const addMobileUser = async (data) => {
  return post(`/customer/mobile-users`, data);
};
export const updateMobileUser = async (data) => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/mobile-users/${id}`, data);
};
export const deleteMobileUser = async (id) => {
  return deleteRequest(`/customer/mobile-users/${id}`);
};
export const restoreMobileUser = async (id) => {
  return patch(`/customer/mobile-users/restore/${id}`);
};
export const getSingleDeletedMobileUser = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/mobile-users/deleted/${id}`);
};

export const hardDeleteMobileUser = async (id) => {
  return deleteRequest(`/customer/mobile-users/hard-delete/${id}`);
};

export const getMobileUser = async (data) => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10 };
  return get(`/customer/mobile-users`, payload);
};

export const getFullMobileUser = async (data) => {
  const params = data?.queryKey[1];
  return get(`/customer/mobile-users/full-list`, params);
};

export const workerManagementApi = {
  deleteMobileUser,
  getSingleMobileUser,
  updateMobileUser,
  getMobileUser,
  addMobileUser,
  getFullMobileUser,
  getSingleDeletedMobileUser,
  hardDeleteMobileUser,
  restoreMobileUser,
};
