import { Avatar, Box, Drawer, Stack, styled, Typography } from '@mui/material';
import React, { useState } from 'react';
import NotificationModal from '../Modals/NotificationModal';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ProfileModal from '../Modals/ProfileModal';

const HeadBox = styled(Box)(() => ({
  marginLeft: '24px',
  display: 'flex',
  alignItems: 'center',
  '.display-name': {
    color: '#265AA8',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '21px',
    width: '120px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  '.role-name': {
    color: '#777676',
    fontSize: '14px',
    lineHeight: '21px',
  },
  '.notification-box': {
    cursor: 'pointer',
  },
  '.avatar-box-width': {
    width: '67px',
  },
}));

const UserInfo = ({ userData }: any) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [state, setState] = React.useState(false);
  const toggleDrawer = () => () => {
    setState(!state);
  };

  return (
    <HeadBox>
      <Box className=''>
        {/* <Stack spacing={0}>
          <Avatar sizes='sm' className='notification-icon' onClick={toggleDrawer()}>
            <NotificationsNoneIcon className='notification-box' />
            <Drawer anchor={'right'} open={state} onClose={toggleDrawer()}>
              <NotificationModal />
            </Drawer>
          </Avatar>
        </Stack> */}
      </Box>
      <Box className='avatar-box' onClick={() => handleOpen()}>
        <Box className='avatar-box-width'>
          <Avatar sizes={'40px'} alt='' src={userData?.data?.user?.profilePic}>
            {userData?.data?.user?.displayName?.[0]?.toUpperCase() ||
              userData?.data?.user?.name?.[0]?.toUpperCase()}
          </Avatar>
        </Box>
        <Box>
          <Typography className='display-name'>
            {userData?.data?.user?.displayName || userData?.data?.user?.name}
          </Typography>
          <Typography className='role-name'>{userData?.data?.user?.userRole?.name}</Typography>
        </Box>
      </Box>
      {open && <ProfileModal open={open} handleClose={handleClose} />}
    </HeadBox>
  );
};

export default UserInfo;
