import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import BookIcon from '../../assets/svg/BookIcon';
import { Collapse, List, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CommentIcon from '../../assets/svg/CommentIcon';
import _ from 'lodash';

const RecentActivityCard = ({ cardData }: any) => {
  const CardWrapper = styled(Card)(() => ({
    boxShadow: '0px 2px 10px rgba(40, 40, 40, 0.1)',
    borderRadius: '15px',
    padding: '13px 20px 11px 16px',
    margin: '0px 5px 10px 4px',
    '.MuiCardContent-root': {
      padding: '0px',
    },
    '.MuiCardContent-root:last-child': {
      paddingBottom: '0px',
    },
    '.title': {
      color: '#1D1B1B',
      fontWeight: 600,
      fontSize: '16px',
      textTransform: 'capitalize',
    },
    '.cursor-pointer': {
      cursor: 'pointer',
    },
    '.comment': {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '11px',
    },
    '.discussion-btn': {
      marginTop: '14px',
      borderRadius: '10px',
      border: '1px solid #265AA8',
    },
  }));
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    // setOpen(!open);
  };
  return (
    <CardWrapper
    // style={{
    //   boxShadow: !open ? '0px 2px 10px rgba(40, 40, 40, 0.1)' : 'inset 0px -2px 0px #265AA8',
    // }}
    >
      <CardContent>
        <Box onClick={handleClick} display='flex'>
          <ListItemText>
            <Typography className='title'>
              <BookIcon />
              {_.get(cardData, 'title', '-')}
            </Typography>
            <Typography>{!open && _.get(cardData, 'message', '-')}</Typography>
          </ListItemText>
          {/* {open ? (
            <ExpandLess className='cursor-pointer' />
          ) : (
            <ExpandMore className='cursor-pointer' />
          )} */}
        </Box>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <Typography>{_.get(cardData, 'message', '-')}</Typography>
            {/* <Box className='comment'> */}
            {/* <Typography>By {_.get(cardData?.createdBy, 'name', '-')}</Typography> */}
            {/* <Box>
                <CommentIcon />
                20
              </Box> */}
            {/* </Box> */}
            {/* <Button variant='outlined' fullWidth className='discussion-btn'>
              View Discussion
            </Button> */}
          </List>
        </Collapse>
      </CardContent>
    </CardWrapper>
  );
};

export default RecentActivityCard;
