import { cloneDeep } from 'lodash';
import { upload } from '../Utils/index';
export const addFile = async (data) => {
  const postFix = cloneDeep(data.get('postfix'));
  data.delete('postfix');
  return upload(`/customer/upload/files/${postFix}`, data);
};
// export const updateFile = async (data) => {
//   const id = data?.id;
//   delete data['id'];
//   return patch(`/upload/files/${id}`, data);
// };

export const uploadApi = {
  addFile,
};
