import styled from '@emotion/styled';
import * as Yup from 'yup';
import Loading from 'react-fullscreen-loading';
import {
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
  Select,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Checkbox,
  TableCell,
  MenuItem,
  FormControl,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Formik, Form } from 'formik';
import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import CustomButton from '../commonComponents/CustomButton';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import Require from '../commonComponents/Required';
import CheckboxFilledIcon from '../../assets/svg/CheckboxFilledIcon';
import CheckboxIcon from '../../assets/svg/CheckboxIcon';
import AddRoles from '../../Modals/AddRoles';
import { useGetRole } from '../../hooks/userRoleHook';
import { BsInfoCircle } from 'react-icons/bs';
import _ from 'lodash';
const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '1px 14px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));
const TabContainer = styled(TableContainer)(() => ({
  borderRadius: '8px 8px 0px 0px !important',
  border: '1px solid #DEDEDE !important',
}));

const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '6px 14px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8A',
  },
}));
const BoxWrapper = styled(Box)(() => ({
  paddingBottom: '20px',
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
    position: 'absolute',
    bottom: '1px',
    left: '0px',
    width: '100%',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 8px 0px',
    color: '#000',
  },
  '.disable-btn': {
    background: '#C9C9C9',
  },
  '.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root': {
    padding: '0px !important',
  },
  '.MuiButtonBase-root-MuiCheckbox-root': {
    padding: '0px !important',
  },
  '.row-bg-color': {
    backgroundColor: '#C9C9C9',
  },
  '.customize-table': {
    border: '1px solid #DEDEDE !important',
    // borderRadius: '4px 4px 0px 0px !important',
  },
  '.module-text': {
    width: 150,
    color: '#0160B9 !important',
    fontWeight: 600,
  },
  'full-width': {
    width: '100% !important',
  },
  '.background-head': {
    background: '#F6F6F6',
  },
  '.bs-info-circle': {
    marginLeft: '7px',
  },
  '.table-data-cell-box': {
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
  },
}));
const AddRole = () => {
  const [isEdit, setIsEdit] = React.useState(false);
  const icon = <AiOutlineInfoCircle color='red' />;
  const [modalValues, setModalValues] = React.useState<any>({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { id } = useParams();
  const { data: roleDetails, isSuccess } = useGetRole(id, !!id);
  const roleName: string = roleDetails?.userRole?.name || '';
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  const addRoleSchema = Yup.object().shape({
    name: Yup.string()
      .required('name is Required')
      .matches(/^[ a-zA-Z0-9]+$/, 'Only alphabets and numbers are allowed'),
    rights: Yup.array(),
  });
  React.useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
  }, [id]);
  const breadCrumbData = [
    {
      title: 'User Management',
      link: '/user-management',
      activeTab: '3',
    },
    {
      title: `${isEdit ? 'Edit' : 'Add new'} Role`,
      link: '',
    },
  ];

  const TableDataCell = styled(TableCell)(() => ({
    // padding: '4px',
    padding: isMdScreen ? '8px 8px 8px 16px' : '8px 16px 8px 16px',
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    '.MuiTableCell-root': {
      // borderBottom: 'none !important',
    },
    '&:nth-of-type(odd)': {
      // backgroundColor: '#F4F9FF',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const modules = ['worker', 'machine', 'process', 'part', 'product', 'workOrder'];
  const rowsData = modules.map((module, ind) => {
    return {
      id: ind,
      module,
    };
  });

  const rightsArr = modules.map((module) => {
    return {
      userModule: module,
      add: false,
      view: false,
      edit: false,
      delete: false,
    };
  });

  const notificationModules = [
    'User',
    'Machine',
    'Process',
    'Part',
    'Product',
    'Production Request',
    'Work Request',
    'Maintenance Request',
    'IR',
    'Parts below Min.Qty',
  ];

  const notificationsArr = notificationModules?.map((notification) => {
    return {
      event: notification,
      type: 'Off',
      add: 'Off',
      edit: 'Off',
      delete: 'Off',
    };
  });

  const fetchedRightsArr = modules?.map((module) => {
    const currentModule = roleDetails?.userPermission?.find(
      (permission) => permission?.userModule === module
    );
    return currentModule;
  });
  //Notification module

  const notificationRowData = notificationModules?.map((notification, ind) => {
    return {
      id: ind,
      notification,
    };
  });
  const fetchedNotificationsArr = notificationModules?.map((module) => {
    const currentModule = roleDetails?.notifications?.find(
      (permission) => permission?.event === module
    );
    return currentModule;
  });

  const moduleMapping = {
    workOrder: 'Work Order',
    worker: 'Worker',
    machine: 'Machine',
    process: 'Process',
    part: 'Part',
    product: 'Product',
  };
  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={`${isEdit ? 'Edit' : 'Add new'} Role`}
        backToLink='/user-management'
        activeTab='3'
      />
      <BoxWrapper className='main-component-padding'>
        <Formik
          initialValues={{
            name: isEdit ? roleName : '',
            rights: isEdit ? fetchedRightsArr : rightsArr,
            notifications: isEdit ? fetchedNotificationsArr : notificationsArr,
          }}
          enableReinitialize={true}
          validationSchema={addRoleSchema}
          onSubmit={(values) => {
            setModalValues(values);
            handleOpen();
          }}
        >
          {({ values, touched, errors, handleChange, isValid, dirty, setFieldValue }) => (
            <Form>
              <Typography className='title-typography'>Enter Role Details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography className='field-title'>
                    Role
                    <Require />
                  </Typography>
                  <Input
                    error={!errors.name || !touched.name ? false : true}
                    placeholder='Enter name'
                    fullWidth
                    onChange={handleChange}
                    name='name'
                    value={values.name}
                    InputProps={{
                      endAdornment: touched.name && errors.name && icon,
                    }}
                  />
                  {touched.name && errors.name && (
                    <Typography className='validation-text'>{errors.name}</Typography>
                  )}
                </Grid>
              </Grid>
              <Divider />
              <Typography className='title-typography'> Module Access</Typography>
              <Box height={'auto'} width={isMdScreen ? `90%` : `60%`}>
                <TabContainer>
                  <Table aria-label='customized table'>
                    <TableHead className='background-head'>
                      <TableRow>
                        <TableDataCell>Module</TableDataCell>
                        <TableDataCell align='left'>Create</TableDataCell>
                        <TableDataCell align='left'>Read</TableDataCell>
                        <TableDataCell align='left'>Update</TableDataCell>
                        <TableDataCell align='left'>Delete</TableDataCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsData.map((row) => {
                        return (
                          <StyledTableRow
                            key={row.module}
                            style={{ padding: '0px' }}
                            // className={values?.rights[row.id]?.view === false ? 'row-bg-color' : ''}
                          >
                            <TableDataCell
                              component='th'
                              scope='row'
                              // sx={{ width: 150, padding: '0px', color: '#0160B9' }}
                              className={
                                values?.rights[row.id]?.view === false
                                  ? 'row-bg-color module-text'
                                  : 'module-text'
                              }
                            >
                              {/* {row.module} */}
                              {moduleMapping[row.module]}
                            </TableDataCell>
                            <TableDataCell
                              align='left'
                              className={
                                values?.rights[row.id]?.view === false ? 'row-bg-color' : ''
                              }
                            >
                              <Box className='table-data-cell-box'>
                                <Checkbox
                                  name={`rights[${row.id}].add`}
                                  checked={values?.rights[row.id]?.add ? true : false}
                                  onChange={(e) => {
                                    handleChange(e);
                                    if (e.target.checked) {
                                      setFieldValue(`rights[${row.id}].view`, true);
                                    } else {
                                      const filteredObject = _.pickBy(
                                        values?.rights[row.id],
                                        function (value, key) {
                                          return ['edit', 'delete'].includes(key);
                                        }
                                      );
                                      const isAnyRightTrue = Object.values(
                                        filteredObject || {}
                                      ).includes(true);
                                      if (!isAnyRightTrue) {
                                        setFieldValue(`rights[${row.id}].view`, false);
                                      }
                                    }
                                  }}
                                  icon={<CheckboxIcon />}
                                  checkedIcon={<CheckboxFilledIcon />}
                                />
                                {/* <BsInfoCircle size={16.25} className='bs-info-circle' /> */}
                              </Box>
                            </TableDataCell>
                            <TableDataCell
                              align='left'
                              className={
                                values?.rights[row.id]?.view === false ? 'row-bg-color' : ''
                              }
                            >
                              <Box className='table-data-cell-box'>
                                <Checkbox
                                  name={`rights[${row.id}].view`}
                                  checked={values?.rights[row.id]?.view ? true : false}
                                  onChange={(e) => {
                                    handleChange(e);
                                    if (e.target.checked === false) {
                                      setFieldValue(`rights[${row.id}].add`, false);
                                      setFieldValue(`rights[${row.id}].edit`, false);
                                      setFieldValue(`rights[${row.id}].delete`, false);
                                    }
                                  }}
                                  icon={<CheckboxIcon />}
                                  checkedIcon={<CheckboxFilledIcon />}
                                />
                                {/* <BsInfoCircle size={16.25} className='bs-info-circle' /> */}
                              </Box>
                            </TableDataCell>
                            <TableDataCell
                              align='left'
                              className={
                                values?.rights[row.id]?.view === false ? 'row-bg-color' : ''
                              }
                            >
                              <Box className='table-data-cell-box'>
                                <Checkbox
                                  name={`rights[${row.id}].edit`}
                                  checked={values?.rights[row.id]?.edit ? true : false}
                                  onChange={(e) => {
                                    handleChange(e);
                                    if (e.target.checked) {
                                      setFieldValue(`rights[${row.id}].view`, true);
                                    } else {
                                      const filteredObject = _.pickBy(
                                        values?.rights[row.id],
                                        function (value, key) {
                                          return ['add', 'delete'].includes(key);
                                        }
                                      );
                                      const isAnyRightTrue = Object.values(
                                        filteredObject || {}
                                      ).includes(true);
                                      if (!isAnyRightTrue) {
                                        setFieldValue(`rights[${row.id}].view`, false);
                                      }
                                    }
                                  }}
                                  icon={<CheckboxIcon />}
                                  checkedIcon={<CheckboxFilledIcon />}
                                />
                                {/* <BsInfoCircle size={16.25} className='bs-info-circle' /> */}
                              </Box>
                            </TableDataCell>
                            <TableDataCell
                              align='left'
                              className={
                                values?.rights[row.id]?.view === false ? 'row-bg-color' : ''
                              }
                            >
                              <Box className='table-data-cell-box'>
                                <Checkbox
                                  name={`rights[${row.id}].delete`}
                                  checked={values?.rights[row.id]?.delete ? true : false}
                                  onChange={(e) => {
                                    handleChange(e);
                                    if (e.target.checked) {
                                      setFieldValue(`rights[${row.id}].view`, true);
                                    } else {
                                      const filteredObject = _.pickBy(
                                        values?.rights[row.id],
                                        function (value, key) {
                                          return ['add', 'edit'].includes(key);
                                        }
                                      );
                                      const isAnyRightTrue = Object.values(
                                        filteredObject || {}
                                      ).includes(true);
                                      if (!isAnyRightTrue) {
                                        setFieldValue(`rights[${row.id}].view`, false);
                                      }
                                    }
                                  }}
                                  icon={<CheckboxIcon />}
                                  checkedIcon={<CheckboxFilledIcon />}
                                />
                                {/* <BsInfoCircle size={16.25} className='bs-info-circle' /> */}
                              </Box>
                            </TableDataCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TabContainer>
              </Box>
              <Divider />
              <Typography className='title-typography'> Notifications Settings</Typography>
              <Box
                height={'auto'}
                mb={isMdScreen ? '8%' : '6%'}
                width={isMdScreen ? '100%' : '80%'}
              >
                <TabContainer>
                  <Table sx={{ minWidth: 700 }} aria-label='customize table'>
                    <TableHead className='background-head'>
                      <TableRow>
                        <TableDataCell>Event</TableDataCell>
                        <TableDataCell>All</TableDataCell>
                        <TableDataCell align='left'>Create</TableDataCell>
                        <TableDataCell align='left'>Update</TableDataCell>
                        <TableDataCell align='left'>Delete</TableDataCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {notificationRowData.map((row) => {
                        return (
                          <StyledTableRow key={row.notification}>
                            <TableDataCell
                              component='th'
                              scope='row'
                              sx={{ width: 150 }}
                              className='module-text'
                            >
                              {row.notification}
                            </TableDataCell>
                            <TableDataCell align='left'>
                              {row?.notification !== 'Parts below Min.Qty' && (
                                <FormControl fullWidth>
                                  <Selects
                                    onChange={(e: any) => {
                                      if (['On', 'On + Emails', 'Off'].includes(e.target.value)) {
                                        setFieldValue(
                                          `notifications[${row.id}].add`,
                                          e.target.value
                                        );
                                        setFieldValue(
                                          `notifications[${row.id}].edit`,
                                          e.target.value
                                        );
                                        setFieldValue(
                                          `notifications[${row.id}].delete`,
                                          e.target.value
                                        );
                                      }
                                      handleChange(e);
                                    }}
                                    name={`notifications[${row.id}].type`}
                                    value={values?.notifications[row.id]?.type || ''}
                                    variant='outlined'
                                  >
                                    <MenuItem value='On'>On</MenuItem>
                                    <MenuItem value='Off'>Off</MenuItem>
                                    <MenuItem value='Mixed'>Mixed</MenuItem>
                                    <MenuItem value='On + Emails'>On + Email</MenuItem>
                                  </Selects>
                                </FormControl>
                              )}
                            </TableDataCell>
                            <TableDataCell align='left'>
                              {row?.notification !== 'Parts below Min.Qty' && (
                                <FormControl fullWidth>
                                  <Selects
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    name={`notifications[${row.id}].add`}
                                    value={values?.notifications[row.id]?.add || ''}
                                    variant='outlined'
                                  >
                                    {['Mixed', 'On'].includes(
                                      values?.notifications[row.id]?.type
                                    ) && <MenuItem value='On'>On</MenuItem>}
                                    {['Mixed', 'Off'].includes(
                                      values?.notifications[row.id]?.type
                                    ) && <MenuItem value='Off'>Off</MenuItem>}
                                    {/* {['Mixed'].includes(values?.notifications[row.id]?.type) && (
                                      <MenuItem value='Mixed'>Mixed</MenuItem>
                                    )} */}
                                    {['Mixed', 'On + Emails'].includes(
                                      values?.notifications[row.id]?.type
                                    ) && <MenuItem value='On + Emails'>On + Email</MenuItem>}
                                  </Selects>
                                </FormControl>
                              )}
                            </TableDataCell>
                            <TableDataCell align='left'>
                              <FormControl fullWidth>
                                <Selects
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  name={`notifications[${row.id}].edit`}
                                  value={values?.notifications[row.id]?.edit || ''}
                                  variant='outlined'
                                >
                                  {/* {['Mixed', 'On', 'Off'].includes(
                                  values?.notifications[row.id]?.type
                                ) && <MenuItem value='On'>On</MenuItem>}
                                {['Mixed', 'Off'].includes(values?.notifications[row.id]?.type) && (
                                  <MenuItem value='Off'>Off</MenuItem>
                                )}
                                {['Mixed', 'On + Emails', 'Off'].includes(
                                  values?.notifications[row.id]?.type
                                ) && <MenuItem value='On + Emails'>On + Email</MenuItem>} */}

                                  {row?.notification !== 'Parts below Min.Qty'
                                    ? ['Mixed', 'On'].includes(
                                        values?.notifications[row.id]?.type
                                      ) && <MenuItem value='On'>On</MenuItem>
                                    : ['Mixed', 'On', 'Off', 'On + Emails'].includes(
                                        values?.notifications[row.id]?.type
                                      ) && <MenuItem value='On'>On</MenuItem>}
                                  {row?.notification !== 'Parts below Min.Qty'
                                    ? ['Mixed', 'Off'].includes(
                                        values?.notifications[row.id]?.type
                                      ) && <MenuItem value='Off'>Off</MenuItem>
                                    : ['Mixed', 'On', 'Off', 'On + Emails'].includes(
                                        values?.notifications[row.id]?.type
                                      ) && <MenuItem value='Off'>Off</MenuItem>}
                                  {row?.notification !== 'Parts below Min.Qty'
                                    ? ['Mixed', 'On + Emails'].includes(
                                        values?.notifications[row.id]?.type
                                      ) && <MenuItem value='On + Emails'>On + Email</MenuItem>
                                    : ['Mixed', 'On', 'Off', 'On + Emails'].includes(
                                        values?.notifications[row.id]?.type
                                      ) && <MenuItem value='On + Emails'>On + Email</MenuItem>}
                                </Selects>
                              </FormControl>
                            </TableDataCell>
                            <TableDataCell align='left'>
                              {row?.notification !== 'Parts below Min.Qty' && (
                                <FormControl fullWidth>
                                  <Selects
                                    onChange={handleChange}
                                    name={`notifications[${row.id}].delete`}
                                    value={values?.notifications[row.id]?.delete || ''}
                                    variant='outlined'
                                  >
                                    {['Mixed', 'On'].includes(
                                      values?.notifications[row.id]?.type
                                    ) && <MenuItem value='On'>On</MenuItem>}
                                    {['Mixed', 'Off'].includes(
                                      values?.notifications[row.id]?.type
                                    ) && <MenuItem value='Off'>Off</MenuItem>}
                                    {/* {['Mixed'].includes(values?.notifications[row.id]?.type) && (
                                    <MenuItem value='Mixed'>Mixed</MenuItem>
                                  )} */}
                                    {['Mixed', 'On + Emails'].includes(
                                      values?.notifications[row.id]?.type
                                    ) && <MenuItem value='On + Emails'>On + Email</MenuItem>}
                                  </Selects>
                                </FormControl>
                              )}
                            </TableDataCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TabContainer>
              </Box>

              <Box className='button-box'>
                <Box className='footer-padding'>
                  <CustomButton
                    className={!id && !(isValid && dirty) ? 'disable-btn' : 'blue-bg'}
                    variant='contained'
                    type='submit'
                  >
                    Save Role
                  </CustomButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        {isModalOpen && (
          <AddRoles isOpen={isModalOpen} values={modalValues} handleClose={handleClose} />
        )}
        <Loading
          loading={id && !isSuccess ? true : false}
          background='white'
          loaderColor='#265AA8'
        />
      </BoxWrapper>
    </React.Fragment>
  );
};

export default AddRole;
